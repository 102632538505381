import React from 'react';
import classNames from 'classnames';

import { Checkbox } from './Checkbox';

export interface ICheckboxGroupProps extends React.HTMLAttributes<HTMLDivElement> {
  name: string;
  children: ReadonlyArray<React.ReactElement>;
  inLine?: boolean;
}

export const CheckboxGroup: React.FC<ICheckboxGroupProps> = ({
  children,
  inLine,
  name,
  className,
}: ICheckboxGroupProps) => {
  const defaultClass = classNames('sui-flex', { 'sui-flex-col': !inLine }, className);
  return (
    <div className={defaultClass}>
      {children.map((item, index) => {
        if (item.props.children) {
          return (
            <Checkbox key={index} name={name} {...item.props}>
              {item.props.children}
            </Checkbox>
          );
        }

        return <Checkbox key={index} name={name} label={item.props.label} {...item.props} />;
      })}
    </div>
  );
};

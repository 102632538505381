import React from 'react';
import classNames from 'classnames';

import { IconTypes } from '../../../types';

type IIconBoldProps = {
  isLight?: boolean;
};

export const IconBold = ({
  className,
  width = 24,
  height = 24,
  isLight,
  ariaHidden = true,
  isClickable = false,
}: IconTypes & IIconBoldProps): React.ReactElement => {
  const defaultClass = classNames(
    'sui-fill-current',
    { 'sui-pointer-events-none': !isClickable },
    className
  );

  const iconVariant = isLight ? (
    <path d="M15.4981 11.2186C16.4888 10.4907 17.141 9.32536 17.141 8C17.141 6.93913 16.7195 5.92172 15.9694 5.17157C15.2193 4.42143 14.2018 4 13.141 4H5.99812C5.92234 4 5.84967 4.0301 5.79609 4.08368C5.7425 4.13727 5.7124 4.20994 5.7124 4.28571V4.85714C5.7124 4.93292 5.7425 5.00559 5.79609 5.05917C5.84967 5.11275 5.92234 5.14286 5.99812 5.14286H6.85526V18.8571H5.99812C5.92234 18.8571 5.84967 18.8872 5.79609 18.9408C5.7425 18.9944 5.7124 19.0671 5.7124 19.1429V19.7143C5.7124 19.7901 5.7425 19.8627 5.79609 19.9163C5.84967 19.9699 5.92234 20 5.99812 20H13.7124C14.7674 20.001 15.7903 19.637 16.6075 18.9698C17.4248 18.3027 17.9861 17.3734 18.1963 16.3395C18.4066 15.3057 18.2527 14.231 17.761 13.2976C17.2692 12.3642 16.4697 11.6297 15.4981 11.2186ZM7.99812 5.14286H13.141C13.8987 5.14286 14.6255 5.44388 15.1613 5.97969C15.6971 6.51551 15.9981 7.24224 15.9981 8C15.9981 8.75776 15.6971 9.48449 15.1613 10.0203C14.6255 10.5561 13.8987 10.8571 13.141 10.8571H7.99812V5.14286ZM13.7124 18.8571H7.99812V12H13.7124C14.6217 12 15.4938 12.3612 16.1368 13.0042C16.7797 13.6472 17.141 14.5193 17.141 15.4286C17.141 16.3379 16.7797 17.21 16.1368 17.8529C15.4938 18.4959 14.6217 18.8571 13.7124 18.8571Z" />
  ) : (
    <path d="M16.7143 11.3571C17.2581 10.6928 17.5936 9.88257 17.6786 9.02821C17.9421 6.30321 15.8039 4 13.1403 4H6.01821C5.86665 4 5.72131 4.0602 5.61414 4.16737C5.50698 4.27453 5.44678 4.41988 5.44678 4.57143V6.28571C5.44678 6.43727 5.50698 6.58261 5.61414 6.68978C5.72131 6.79694 5.86665 6.85714 6.01821 6.85714H7.15642V17.1429H6.01821C5.86665 17.1429 5.72131 17.2031 5.61414 17.3102C5.50698 17.4174 5.44678 17.5627 5.44678 17.7143V19.4286C5.44678 19.5801 5.50698 19.7255 5.61414 19.8326C5.72131 19.9398 5.86665 20 6.01821 20H13.4939C16.0225 20 18.2846 18.1518 18.5296 15.6286C18.6989 13.8982 17.9443 12.3407 16.7143 11.3571ZM10.0061 6.85714H13.1403C13.595 6.85714 14.031 7.03775 14.3525 7.35925C14.674 7.68074 14.8546 8.11677 14.8546 8.57143C14.8546 9.02609 14.674 9.46212 14.3525 9.78361C14.031 10.1051 13.595 10.2857 13.1403 10.2857H10.0061V6.85714ZM13.1403 17.1429H10.0061V13.1429H13.1403C13.6708 13.1429 14.1795 13.3536 14.5546 13.7286C14.9296 14.1037 15.1403 14.6124 15.1403 15.1429C15.1403 15.6733 14.9296 16.182 14.5546 16.5571C14.1795 16.9321 13.6708 17.1429 13.1403 17.1429Z" />
  );
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={defaultClass}
      aria-hidden={ariaHidden}
    >
      {iconVariant}
    </svg>
  );
};

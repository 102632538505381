import React from 'react';
import classNames from 'classnames';

import { IconTypes } from '../../../types';

export const IconCaretDown = ({
  className,
  width = 24,
  height = 24,
  ariaHidden = true,
  isClickable = false,
}: IconTypes): React.ReactElement => {
  const defaultClass = classNames(
    'sui-fill-current',
    { 'sui-pointer-events-none': !isClickable },
    className
  );
  return (
    <svg
      width={width}
      height={height}
      viewBox="-6 -8 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={defaultClass}
      aria-hidden={ariaHidden}
    >
      <path d="M1.38434 0.97464H10.6156C11.2542 0.97464 11.5735 1.746 11.1214 2.19806L6.50762 6.81546C6.22778 7.09531 5.77214 7.09531 5.4923 6.81546L0.878474 2.19806C0.42642 1.746 0.745728 0.97464 1.38434 0.97464Z" />
    </svg>
  );
};

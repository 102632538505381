import React from 'react';
import classNames from 'classnames';

import { ButtonSize, ButtonVariant, HasIcon } from '../../types';
import { Loader } from '../Loader';
import { Pills } from '../Pills';
import { IconAngle } from '../Icon';

export interface IButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  hasIcon?: HasIcon;
  size?: ButtonSize;
  variant?: ButtonVariant;
  border?: 'default' | 'none' | 'rounded' | 'dashed';
  type?: 'button' | 'submit' | 'reset';
  isLoading?: boolean;
  ariaLabel?: string;
  /**
   * @deprecated `itemCount` is deprecated. Use `countIndicator` instead
   */
  itemCount?: number;
  /**
   * @deprecated `className` is deprecated. Avoid using this one as props.
   */
  className?: string;
  countIndicator?: { count: number; onClick?: () => void };
  hasDownArrow?: boolean;
}

const ButtonVariantToClass: Record<ButtonVariant, string> = {
  [ButtonVariant.PRIMARY]: 'sui-c-btn-primary',
  [ButtonVariant.SECONDARY]: 'sui-c-btn-secondary',
  [ButtonVariant.TERTIARY]: 'sui-c-btn-tertiary',
  [ButtonVariant.WARNING]: 'sui-c-btn-warning',
  [ButtonVariant.NEGATIVE]: 'sui-c-btn-negative',
  [ButtonVariant.LINK]: 'sui-c-btn-link',
  [ButtonVariant.ICON]: 'sui-c-btn-icon',
};

const InnerButton = (
  {
    children,
    className,
    hasIcon,
    size = ButtonSize.MEDIUM,
    isLoading = false,
    variant = ButtonVariant.PRIMARY,
    border = 'default',
    type = 'button',
    disabled,
    ariaLabel,
    itemCount,
    countIndicator,
    hasDownArrow,
    ...props
  }: IButtonProps,
  ref: React.Ref<HTMLButtonElement>
) => {
  const isIconOnly = hasIcon && !children;
  if (itemCount !== undefined && !countIndicator) {
    countIndicator = { count: itemCount };
  }
  let textSize = 'sui-font-heading sui-text-h4';
  let buttonSize = classNames('sui-u-btn-medium', {
    'sui-w-8 sui-h-8': isIconOnly && !hasDownArrow,
    'sui-gap-4': hasDownArrow,
  });
  let buttonPadding = '';
  if (size === ButtonSize.MEDIUM) {
    buttonPadding = classNames({
      'sui-px-base sui-py-1.5': children && !hasIcon,
      'sui-px-base sui-py-1': children && hasIcon,
      'sui-p-1': isIconOnly || (hasDownArrow && isIconOnly),
      'sui-pl-4 sui-py-1.5 sui-pr-3': hasDownArrow && children && !hasIcon,
      'sui-py-1 sui-pr-3 sui-pl-4': hasDownArrow && children && hasIcon,
    });
  }

  // Text Size
  if (size === ButtonSize.LARGE) {
    textSize = 'sui-font-heading sui-text-h3';
    buttonSize = classNames('sui-u-btn-large', {
      'sui-gap-4': hasDownArrow,
      'sui-w-10 sui-h-10': isIconOnly && !hasDownArrow,
    });
    buttonPadding = classNames('sui-py-2', {
      'sui-px-base': children,
      'sui-px-2': isIconOnly || (hasDownArrow && isIconOnly),
      'sui-pl-base sui-pr-3': hasDownArrow && !isIconOnly,
    });
  }

  if (size === ButtonSize.SMALL) {
    textSize = 'sui-font-heading sui-text-h5';
    buttonSize = classNames(
      'sui-u-btn-small',
      { 'small-loader': isLoading },
      {
        'sui-w-6 sui-h-6': isIconOnly && !hasDownArrow,
        'sui-gap-3': hasDownArrow && !isIconOnly,
        'sui-gap-2': hasDownArrow && isIconOnly,
      }
    );
    buttonPadding = classNames('sui-py-1', {
      'sui-px-3': children,
      'sui-px-1': isIconOnly || (hasDownArrow && isIconOnly),
      'sui-pl-3 sui-pr-2': hasDownArrow && !isIconOnly,
    });
  }

  // Link and Icon Variant
  if (variant === ButtonVariant.LINK) {
    buttonSize = '';
    buttonPadding = '';
  }
  if (variant === ButtonVariant.ICON) {
    textSize = '';
    buttonSize = '';
    buttonPadding = 'sui-p-2.5';
  }
  const defaultClass = classNames(
    variant === ButtonVariant.ICON
      ? 'sui-relative sui-rounded-full focus:sui-outline-none'
      : 'sui-relative sui-rounded sui-flex sui-items-center focus:sui-outline-none sui-font-bold',
    { 'sui-rounded-full': isIconOnly && (border === 'none' || border === 'rounded') },
    { 'sui-justify-center': isIconOnly },
    {
      'sui-border-none sui-bg-transparent focus:sui-bg-transparent active:sui-bg-primary-lighter':
        border === 'none',
    },
    { 'sui-border-dashed': border === 'dashed' },
    {
      isLoading: isLoading,
      'sui-justify-center sui-items-center sui-pointer-events-none':
        isLoading && variant !== ButtonVariant.LINK,
      'sui-c-btn-disabled': disabled,
      'sui-c-has-item-count': countIndicator && countIndicator.count > 0,
    },
    ButtonVariantToClass[variant],
    buttonSize,
    buttonPadding,
    textSize,
    className
  );
  const ItemCount = ({ value, onClick }: { value: number; onClick?: () => void }) => {
    const crossClickHandler = async (e: React.MouseEvent<HTMLElement>) => {
      if (!onClick) {
        return;
      }
      e.stopPropagation();
      onClick();
    };
    return (
      <Pills
        label={value.toString()}
        variant="dark"
        isSmall
        isRemovable={onClick !== undefined}
        onclickRemovable={crossClickHandler}
      />
    );
  };

  const Content = ({
    icon,
    children,
    countIndicator,
    isLoading,
  }: {
    icon?: HasIcon;
    children: React.ReactNode;
    countIndicator?: { count: number; onClick?: () => void };
    isLoading?: boolean;
  }) => {
    const flexClass = 'sui-flex sui-items-center sui-justify-center';
    const baseClasses = classNames(
      'sui-relative',
      flexClass,
      countIndicator ? 'sui-gap-2' : 'sui-gap-1',
      {
        'sui-invisible': isLoading,
        'sui-pointer-events-none': !countIndicator,
      }
    );

    const iconWrapperSize: Record<ButtonSize, string> = {
      [ButtonSize.SMALL]: 'sui-w-4 sui-h-4',
      [ButtonSize.MEDIUM]: 'sui-w-6 sui-h-6',
      [ButtonSize.LARGE]: border === 'default' ? 'sui-w-6 sui-h-6' : 'sui-w-8 sui-h-8',
    };

    const downArrowIconWrapperSize: Record<ButtonSize, string> = {
      [ButtonSize.SMALL]: 'sui-w-3.5 sui-h-3.5',
      [ButtonSize.MEDIUM]: 'sui-w-4 sui-h-4',
      [ButtonSize.LARGE]: 'sui-w-5 sui-h-5',
    };

    return (
      <>
        {isLoading && (
          <span className="sui-absolute sui-c-loading-icon">
            <Loader color="white" inverted role="status" />
          </span>
        )}
        {isIconOnly ? (
          <div className={classNames(baseClasses, iconWrapperSize[size])}>{icon?.icon}</div>
        ) : icon ? (
          <div className={classNames(baseClasses, { 'sui-flex-row-reverse': !icon.isLeft })}>
            <div className={classNames(flexClass, iconWrapperSize[size])}>{icon.icon}</div>
            {children}
          </div>
        ) : countIndicator && countIndicator.count > 0 ? (
          <div className={baseClasses}>
            <span>{children}</span>
            <ItemCount value={countIndicator.count} onClick={countIndicator.onClick} />
          </div>
        ) : (
          <span className={baseClasses}>{children}</span>
        )}
        {hasDownArrow && (
          <div className={classNames(baseClasses, downArrowIconWrapperSize[size])}>
            <IconAngle variant="down" isLight />
          </div>
        )}
      </>
    );
  };

  return (
    <button
      className={defaultClass}
      ref={ref}
      type={type}
      disabled={disabled}
      aria-disabled={disabled}
      aria-label={ariaLabel}
      {...props}
    >
      <Content countIndicator={countIndicator} isLoading={isLoading} icon={hasIcon}>
        {children}
      </Content>
    </button>
  );
};

export const Button = React.forwardRef(InnerButton);

import React from 'react';
import classNames from 'classnames';

import { IconCheck, IconMinus } from '../Icon';

export interface ICheckboxProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  indeterminate?: boolean;
  isLarge?: boolean;
  isInvalid?: boolean;
}

export const Checkbox: React.FC<ICheckboxProps> = ({
  className,
  checked,
  children,
  disabled,
  indeterminate = false,
  isLarge,
  isInvalid,
  label,
  ...props
}: ICheckboxProps) => {
  const inputRef = React.useRef<HTMLInputElement>(null);

  let textSize = 'sui-text-b4';
  let checkboxSize = 'sui-w-base sui-h-base';
  let errorClass = '';
  let textColor = '';
  let marginBetweenCheckboxAndLabel = '';

  // Text Size
  if (isLarge) {
    textSize = 'sui-text-b3';
    checkboxSize = 'sui-u-checkbox-large';
  }

  if (disabled) {
    textColor = 'sui-text-lightGray-darkest';
  }

  if (isInvalid) {
    errorClass = 'is-invalid';
  }

  const defaultStyle = classNames(
    'sui-flex sui-items-start sui-text-base',
    { 'sui-cursor-pointer': !disabled },
    className,
    textSize,
    errorClass,
    textColor
  );

  if (children || label) {
    marginBetweenCheckboxAndLabel = 'sui-mr-2';

    if (isLarge) {
      marginBetweenCheckboxAndLabel = 'sui-mr-2.5';
    }
  }

  const inputCheckboxClass = classNames(
    'sui-w-base sui-h-base sui-bg-white sui-inline-flex sui-justify-center sui-items-center sui-rounded-sm sui-border sui-border-lightGray-darker sui-flex-no-shrink sui-flex-shrink-0',
    checkboxSize,
    marginBetweenCheckboxAndLabel
  );

  function CheckboxIcon() {
    if (indeterminate) {
      return (
        <IconMinus
          width={isLarge ? 12 : 10.67}
          height={isLarge ? 2.57 : 2.29}
          className="sui-text-white"
        />
      );
    }
    return (
      <IconCheck
        width={isLarge ? 12 : 10.67}
        height={isLarge ? 8.95 : 7.95}
        className="sui-text-white"
      />
    );
  }

  React.useEffect(() => {
    if (inputRef.current) {
      inputRef.current.indeterminate = indeterminate;
    }
  }, [indeterminate, inputRef]);

  return (
    <div className="sui-c-checkbox">
      <label tabIndex={0} className={defaultStyle}>
        <input
          ref={inputRef}
          tabIndex={-1}
          type="checkbox"
          className="sui-hidden"
          checked={checked}
          disabled={disabled}
          aria-checked={checked}
          aria-disabled={disabled}
          {...props}
        />
        <span className={`sui-input-subs sui-flex sui-items-center sui-h-${isLarge ? 6 : 5}`}>
          <span className={inputCheckboxClass}>
            <CheckboxIcon />
          </span>
        </span>
        {children ? <div>{children}</div> : label}
      </label>
    </div>
  );
};

import React from 'react';
import classNames from 'classnames';

import { UncontrolledTooltip } from '../Tooltip';
import { IconQuestion } from '../Icon';

export interface LabelProps extends React.LabelHTMLAttributes<HTMLLabelElement> {
  /**
   * @deprecated `isTelus` will be removed in a future release.
   */
  isTelus?: boolean;
  required?: boolean;
  tooltip?: React.ReactChild;
  isDescriptive?: boolean;
  isDisabled?: boolean;
  size?: 'large' | 'medium';
  children?: string;
}

export const Label: React.FC<LabelProps> = ({
  className,
  children,
  required,
  tooltip,
  isDescriptive,
  isDisabled,
  size = 'medium',
  ...props
}: LabelProps) => {
  const textSize = size === 'large' ? 'sui-text-h3' : 'sui-text-h4';

  const defaultStyle = classNames(
    'sui-font-heading sui-font-bold',
    isDisabled ? 'sui-text-lightGray-darkest' : 'sui-text-darkGray-darkest',
    textSize,
    className
  );

  const asteriskClass = classNames(
    'sui-ml-0.5',
    textSize,
    isDisabled ? 'sui-text-lightGray-darkest' : 'sui-text-danger-dark'
  );
  const defaultLabel = (
    <label className={defaultStyle} {...props}>
      {children}
    </label>
  );

  return (
    <div className="sui-flex sui-py-2 sui-items-center">
      {defaultLabel}
      {required && <span className={asteriskClass}>*</span>}

      {tooltip && (
        <UncontrolledTooltip
          content={tooltip}
          className="sui-cursor-pointer sui-ml-1"
          isDescriptive={isDescriptive}
        >
          <IconQuestion variant="circle" className="sui-text-lightGray-darkest" />
        </UncontrolledTooltip>
      )}
    </div>
  );
};

import React from 'react';
import classNames from 'classnames';

import { BadgeSize, BadgesType, BadgeVariant } from '../../types';

export interface IBadgeProps {
  text?: string;
  icon?: React.ReactElement;
  type?: BadgesType;
  variant?: BadgeVariant;
  className?: string;
  /**
   * @deprecated `isLarge` will be removed in a future release. Use `size=BadgeSize.LARGE` instead of isLarge
   */
  isLarge?: boolean;
  /**
   * @deprecated `isTelus` will now be removed on a future release.
   */
  isTelus?: boolean;
  size?: BadgeSize;
}

export const Badge: React.FC<IBadgeProps> = ({
  text,
  className,
  type = BadgesType.GENERIC,
  variant = BadgeVariant.PRIMARY,
  icon,
  size = BadgeSize.MEDIUM,
}: IBadgeProps) => {
  let badgeBackground = 'sui-bg-white';
  let badgeColor = 'sui-text-white';
  let badgeBorder = 'sui-border-transparent';

  // Badge Type
  switch (type) {
    case BadgesType.GENERIC:
      badgeBorder = 'sui-border-lightGray-darker';
      badgeColor =
        variant === BadgeVariant.PRIMARY ? 'sui-text-darkGray-darkest' : 'sui-text-darkGray';

      break;

    case BadgesType.ONGOING:
      variant === BadgeVariant.PRIMARY
        ? (badgeBackground = 'sui-bg-info')
        : ((badgeBackground = 'sui-bg-info-bg'), (badgeColor = 'sui-text-info-dark'));
      break;

    case BadgesType.SUCCESS:
      variant === BadgeVariant.PRIMARY
        ? (badgeBackground = 'sui-bg-success')
        : ((badgeBackground = 'sui-bg-success-bg'), (badgeColor = 'sui-text-success-dark'));
      break;

    case BadgesType.WARNING:
      variant === BadgeVariant.PRIMARY
        ? (badgeBackground = 'sui-bg-warning')
        : ((badgeBackground = 'sui-bg-warning-bg'), (badgeColor = 'sui-text-warning'));
      break;

    case BadgesType.ERROR:
      variant === BadgeVariant.PRIMARY
        ? (badgeBackground = 'sui-bg-danger')
        : ((badgeBackground = 'sui-bg-danger-lightest'), (badgeColor = 'sui-text-danger-dark'));
      break;

    case BadgesType.PAUSE:
      variant === BadgeVariant.PRIMARY
        ? (badgeBackground = 'sui-bg-lightGray')
        : ((badgeBackground = 'sui-bg-lightGray-lighter'),
          (badgeColor = 'sui-text-darkGray-lightest'));
      break;

    case BadgesType.NEUTRAL:
      variant === BadgeVariant.PRIMARY
        ? (badgeBackground = 'sui-bg-darkGray')
        : ((badgeBackground = 'sui-bg-lightGray'), (badgeColor = 'sui-text-darkGray-darkest'));
      break;
  }

  const badgeHeight = classNames({
    'sui-h-6 sui-rounded-xl': size === BadgeSize.LARGE,
    'sui-h-5 sui-rounded-2lg': size === BadgeSize.MEDIUM,
    'sui-h-4 sui-rounded-lg': size === BadgeSize.SMALL,
  });

  const defaultClass = classNames(
    'sui-border sui-font-bold sui-uppercase sui-font-heading sui-w-max sui-flex sui-items-center sui-gap-0.5',
    size === BadgeSize.SMALL ? 'sui-px-1.5 sui-text-h6' : 'sui-px-2 sui-text-h4',
    badgeBackground,
    badgeHeight,
    badgeColor,
    badgeBorder,
    className
  );

  const iconSizeClass = classNames('sui-flex sui-items-center sui-justify-center', {
    'sui-h-4 sui-w-4': size === BadgeSize.SMALL,
    'sui-h-5 sui-w-5': size === BadgeSize.MEDIUM,
    'sui-h-6 sui-w-6': size === BadgeSize.LARGE,
  });

  return (
    <div className={defaultClass}>
      {icon && <div className={iconSizeClass}>{icon}</div>}
      {text && text}
    </div>
  );
};

import React from 'react';
import classNames from 'classnames';

import { IconTypes } from '../../../types';

type IIconAlignJustifyProps = {
  isLight?: boolean;
};

export const IconAlignJustify = ({
  className,
  width = 24,
  height = 24,
  isLight,
  ariaHidden = true,
  isClickable = false,
}: IconTypes & IIconAlignJustifyProps): React.ReactElement => {
  const defaultClass = classNames(
    'sui-fill-current',
    { 'sui-pointer-events-none': !isClickable },
    className
  );

  const iconVariant = isLight ? (
    <path d="M19.7143 4.57129H4.28571C4.20994 4.57129 4.13727 4.60139 4.08368 4.65497C4.0301 4.70855 4 4.78123 4 4.857V5.42843C4 5.50421 4.0301 5.57688 4.08368 5.63046C4.13727 5.68404 4.20994 5.71415 4.28571 5.71415H19.7143C19.7901 5.71415 19.8627 5.68404 19.9163 5.63046C19.9699 5.57688 20 5.50421 20 5.42843V4.857C20 4.78123 19.9699 4.70855 19.9163 4.65497C19.8627 4.60139 19.7901 4.57129 19.7143 4.57129ZM19.7143 18.2856H4.28571C4.20994 18.2856 4.13727 18.3157 4.08368 18.3693C4.0301 18.4228 4 18.4955 4 18.5713V19.1427C4 19.2185 4.0301 19.2912 4.08368 19.3447C4.13727 19.3983 4.20994 19.4284 4.28571 19.4284H19.7143C19.7901 19.4284 19.8627 19.3983 19.9163 19.3447C19.9699 19.2912 20 19.2185 20 19.1427V18.5713C20 18.4955 19.9699 18.4228 19.9163 18.3693C19.8627 18.3157 19.7901 18.2856 19.7143 18.2856ZM19.7143 13.7141H4.28571C4.20994 13.7141 4.13727 13.7442 4.08368 13.7978C4.0301 13.8514 4 13.9241 4 13.9999V14.5713C4 14.6471 4.0301 14.7197 4.08368 14.7733C4.13727 14.8269 4.20994 14.857 4.28571 14.857H19.7143C19.7901 14.857 19.8627 14.8269 19.9163 14.7733C19.9699 14.7197 20 14.6471 20 14.5713V13.9999C20 13.9241 19.9699 13.8514 19.9163 13.7978C19.8627 13.7442 19.7901 13.7141 19.7143 13.7141ZM19.7143 9.14272H4.28571C4.20994 9.14272 4.13727 9.17282 4.08368 9.2264C4.0301 9.27998 4 9.35266 4 9.42843V9.99986C4 10.0756 4.0301 10.1483 4.08368 10.2019C4.13727 10.2555 4.20994 10.2856 4.28571 10.2856H19.7143C19.7901 10.2856 19.8627 10.2555 19.9163 10.2019C19.9699 10.1483 20 10.0756 20 9.99986V9.42843C20 9.35266 19.9699 9.27998 19.9163 9.2264C19.8627 9.17282 19.7901 9.14272 19.7143 9.14272Z" />
  ) : (
    <path d="M19.4286 17.7143H4.57143C4.41988 17.7143 4.27453 17.7745 4.16737 17.8817C4.0602 17.9888 4 18.1342 4 18.2857V19.4286C4 19.5801 4.0602 19.7255 4.16737 19.8326C4.27453 19.9398 4.41988 20 4.57143 20H19.4286C19.5801 20 19.7255 19.9398 19.8326 19.8326C19.9398 19.7255 20 19.5801 20 19.4286V18.2857C20 18.1342 19.9398 17.9888 19.8326 17.8817C19.7255 17.7745 19.5801 17.7143 19.4286 17.7143ZM19.4286 13.1429H4.57143C4.41988 13.1429 4.27453 13.2031 4.16737 13.3102C4.0602 13.4174 4 13.5627 4 13.7143V14.8571C4 15.0087 4.0602 15.154 4.16737 15.2612C4.27453 15.3684 4.41988 15.4286 4.57143 15.4286H19.4286C19.5801 15.4286 19.7255 15.3684 19.8326 15.2612C19.9398 15.154 20 15.0087 20 14.8571V13.7143C20 13.5627 19.9398 13.4174 19.8326 13.3102C19.7255 13.2031 19.5801 13.1429 19.4286 13.1429ZM19.4286 8.57143H4.57143C4.41988 8.57143 4.27453 8.63163 4.16737 8.7388C4.0602 8.84596 4 8.9913 4 9.14286V10.2857C4 10.4373 4.0602 10.5826 4.16737 10.6898C4.27453 10.7969 4.41988 10.8571 4.57143 10.8571H19.4286C19.5801 10.8571 19.7255 10.7969 19.8326 10.6898C19.9398 10.5826 20 10.4373 20 10.2857V9.14286C20 8.9913 19.9398 8.84596 19.8326 8.7388C19.7255 8.63163 19.5801 8.57143 19.4286 8.57143ZM19.4286 4H4.57143C4.41988 4 4.27453 4.0602 4.16737 4.16737C4.0602 4.27453 4 4.41988 4 4.57143V5.71429C4 5.86584 4.0602 6.01118 4.16737 6.11835C4.27453 6.22551 4.41988 6.28571 4.57143 6.28571H19.4286C19.5801 6.28571 19.7255 6.22551 19.8326 6.11835C19.9398 6.01118 20 5.86584 20 5.71429V4.57143C20 4.41988 19.9398 4.27453 19.8326 4.16737C19.7255 4.0602 19.5801 4 19.4286 4Z" />
  );
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={defaultClass}
      aria-hidden={ariaHidden}
    >
      {iconVariant}
    </svg>
  );
};

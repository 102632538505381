import React from 'react';
import { Placement } from '@popperjs/core';

import { Overlay } from './Overlay';

type TriggerOptions = 'hover' | 'click' | 'focus';

export interface IOverlayTriggerProps extends React.HtmlHTMLAttributes<HTMLDivElement> {
  children: React.ReactElement;
  overlay: React.ReactElement;
  placement?: Placement;
  show?: boolean;
  trigger?: TriggerOptions | ReadonlyArray<TriggerOptions>;
  offset?: { horizontal: number; vertical: number };
  fade?: boolean;
}

export const OverlayTrigger: React.FC<IOverlayTriggerProps> = ({
  children,
  overlay,
  placement = 'top',
  show = false,
  trigger = ['hover', 'focus'],
  ...props
}: IOverlayTriggerProps) => {
  const targetRef = React.useRef<HTMLElement>(null);
  const [toggle, setToggle] = React.useState<boolean>(show);

  const targetWithRef = React.cloneElement(children, {
    ref: targetRef,
    onMouseEnter: () => {
      if (trigger === 'hover' || trigger.includes('hover')) {
        setToggle(true);
      }
    },
    onMouseLeave: () => {
      if (trigger === 'hover' || trigger.includes('hover')) {
        setToggle(false);
      }
    },
    onFocus: () => {
      if (trigger === 'focus' || trigger.includes('focus')) {
        setToggle(true);
      }
    },
    onBlur: () => {
      if (trigger === 'focus' || trigger.includes('focus')) {
        setToggle(false);
      }
    },
    onClick: () => {
      if (trigger === 'click' || trigger.includes('click')) {
        setToggle(shown => !shown);
      }
    },
  });

  return (
    <>
      {targetWithRef}
      <Overlay show={toggle} target={targetRef.current} placement={placement} {...props}>
        {overlay}
      </Overlay>
    </>
  );
};

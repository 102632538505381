import React from 'react';
import classNames from 'classnames';

import { IconTypes } from '../../../types';

type IIconImageProps = {
  isLight?: boolean;
};

export const IconImage = ({
  className,
  width = 24,
  height = 24,
  isLight,
  ariaHidden = true,
  isClickable = false,
}: IconTypes & IIconImageProps): React.ReactElement => {
  const defaultClass = classNames(
    'sui-fill-current',
    { 'sui-pointer-events-none': !isClickable },
    className
  );

  const iconVariant = isLight ? (
    <path d="M18.5 6H5.5C4.67156 6 4 6.67156 4 7.5V16.5C4 17.3284 4.67156 18 5.5 18H18.5C19.3284 18 20 17.3284 20 16.5V7.5C20 6.67156 19.3284 6 18.5 6ZM19 16.5C19 16.7757 18.7757 17 18.5 17H5.5C5.22431 17 5 16.7757 5 16.5V7.5C5 7.22431 5.22431 7 5.5 7H18.5C18.7757 7 19 7.22431 19 7.5V16.5ZM7.5 11.25C8.4665 11.25 9.25 10.4665 9.25 9.5C9.25 8.5335 8.4665 7.75 7.5 7.75C6.5335 7.75 5.75 8.5335 5.75 9.5C5.75 10.4665 6.5335 11.25 7.5 11.25ZM7.5 8.75C7.91356 8.75 8.25 9.08644 8.25 9.5C8.25 9.91356 7.91356 10.25 7.5 10.25C7.08644 10.25 6.75 9.91356 6.75 9.5C6.75 9.08644 7.08644 8.75 7.5 8.75ZM13.9697 9.46966L11 12.4393L10.0303 11.4697C9.73744 11.1768 9.26256 11.1768 8.96969 11.4697L6.21969 14.2197C6.15004 14.2893 6.09479 14.372 6.0571 14.463C6.0194 14.554 6 14.6515 6 14.75V15.625C6 15.8321 6.16791 16 6.375 16H17.625C17.8321 16 18 15.8321 18 15.625V12.75C18 12.5511 17.921 12.3603 17.7803 12.2197L15.0303 9.46966C14.7374 9.17678 14.2626 9.17678 13.9697 9.46966ZM17 15H7V14.8536L9.5 12.3536L11 13.8536L14.5 10.3536L17 12.8536V15Z" />
  ) : (
    <path d="M18.5 18H5.5C4.67156 18 4 17.3284 4 16.5V7.5C4 6.67156 4.67156 6 5.5 6H18.5C19.3284 6 20 6.67156 20 7.5V16.5C20 17.3284 19.3284 18 18.5 18ZM7.5 7.75C6.5335 7.75 5.75 8.5335 5.75 9.5C5.75 10.4665 6.5335 11.25 7.5 11.25C8.4665 11.25 9.25 10.4665 9.25 9.5C9.25 8.5335 8.4665 7.75 7.5 7.75ZM6 16H18V12.5L15.2652 9.76516C15.1187 9.61872 14.8813 9.61872 14.7348 9.76516L10.5 14L8.76516 12.2652C8.61872 12.1187 8.38128 12.1187 8.23481 12.2652L6 14.5V16Z" />
  );
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={defaultClass}
      aria-hidden={ariaHidden}
    >
      {iconVariant}
    </svg>
  );
};

import React from 'react';
import classNames from 'classnames';

import { IconOutline, IconTypes } from '../../../types';

type IconSignOutProps = {
  variant?: boolean;
};

export const IconSignOut = ({
  className,
  width,
  height,
  variant,
  outline,
  ariaHidden = true,
  isClickable = false,
}: IconTypes & IconSignOutProps & IconOutline): React.ReactElement => {
  const defaultClass = classNames(
    'sui-fill-current',
    { 'sui-pointer-events-none': !isClickable },
    className
  );
  let viewBox = '0 0 16 14';
  let svgWidth = width ? width : 16;
  let svgHeight = height ? height : 14;
  let iconVariant = outline ? (
    <path d="M1.49963 0.00146484H5.62363C5.82983 0.00146484 5.99854 0.170174 5.99854 0.376373V0.626312C5.99854 0.832512 5.82983 1.00122 5.62363 1.00122H1.49963C1.2247 1.00122 0.999756 1.22617 0.999756 1.5011V10.4989C0.999756 10.7738 1.2247 10.9988 1.49963 10.9988H5.62363C5.82983 10.9988 5.99854 11.1675 5.99854 11.3737V11.6236C5.99854 11.8298 5.82983 11.9985 5.62363 11.9985H1.49963C0.671711 11.9985 0 11.3268 0 10.4989V1.5011C0 0.673176 0.671711 0.00146484 1.49963 0.00146484ZM10.2163 0.610691L9.99443 0.832512C9.8476 0.979351 9.8476 1.21679 9.99443 1.36363L14.1184 5.46888H5.37369C5.16749 5.46888 4.99878 5.63759 4.99878 5.84379V6.15621C4.99878 6.36241 5.16749 6.53112 5.37369 6.53112H14.1184L9.99756 10.6364C9.85072 10.7832 9.85072 11.0206 9.99756 11.1675L10.2194 11.3893C10.3662 11.5361 10.6037 11.5361 10.7505 11.3893L15.8899 6.26556C16.0367 6.11872 16.0367 5.88128 15.8899 5.73444L10.7474 0.610691C10.6005 0.463852 10.3631 0.463852 10.2163 0.610691Z" />
  ) : (
    <path d="M5.62472 11.9997H2.99985C1.34368 11.9997 0 10.656 0 8.99987V3.00016C0 1.34399 1.34368 0.000305176 2.99985 0.000305176H5.62472C5.83097 0.000305176 5.99971 0.169047 5.99971 0.375287V1.62523C5.99971 1.83147 5.83097 2.00021 5.62472 2.00021H2.99985C2.44676 2.00021 1.9999 2.44706 1.9999 3.00016V8.99987C1.9999 9.55296 2.44676 9.99982 2.99985 9.99982H5.62472C5.83097 9.99982 5.99971 10.1686 5.99971 10.3748V11.6247C5.99971 11.831 5.83097 11.9997 5.62472 11.9997ZM9.30892 2.52831L11.7338 4.75007H5.74972C5.33411 4.75007 4.99976 5.08443 4.99976 5.50004V6.49999C4.99976 6.91559 5.33411 7.24995 5.74972 7.24995H11.7338L9.30892 9.47172C8.99331 9.7592 8.98394 10.2529 9.28392 10.556L9.96826 11.2404C10.2589 11.531 10.7339 11.5341 11.0276 11.2435L15.7774 6.53124C16.0742 6.2375 16.0742 5.7594 15.7774 5.46566L11.0307 0.759643C10.737 0.469032 10.2651 0.469032 9.97139 0.762768L9.28705 1.44711C8.98394 1.74709 8.99644 2.24082 9.30892 2.52831Z" />
  );

  if (variant) {
    viewBox = '0 0 16 14';
    svgWidth = width ? width : 16;
    svgHeight = height ? height : 14;
    iconVariant = outline ? (
      <path d="M5 4.78438C5 4.50938 5.225 4.28438 5.5 4.28438H10V1.35C10 1.12812 10.2688 1.01562 10.425 1.17188L14.85 5.64375C15.0469 5.84063 15.0469 6.15625 14.85 6.35312L10.425 10.825C10.2688 10.9812 10 10.8719 10 10.6469V7.7125H5.5C5.225 7.7125 5 7.4875 5 7.2125V4.78438ZM4 4.78438V7.2125C4 8.04062 4.67188 8.7125 5.5 8.7125H9V10.6469C9 11.7563 10.3438 12.3188 11.1313 11.5312L15.5594 7.0625C16.1469 6.475 16.1469 5.525 15.5594 4.9375L11.1313 0.465625C10.3469 -0.31875 9 0.2375 9 1.35V3.28438H5.5C4.67188 3.28438 4 3.95938 4 4.78438ZM0 1.5V10.5C0 11.3281 0.671875 12 1.5 12H5.625C5.83125 12 6 11.8313 6 11.625V11.375C6 11.1687 5.83125 11 5.625 11H1.5C1.225 11 1 10.775 1 10.5V1.5C1 1.225 1.225 1 1.5 1H5.625C5.83125 1 6 0.83125 6 0.625V0.375C6 0.16875 5.83125 0 5.625 0H1.5C0.671875 0 0 0.671875 0 1.5Z" />
    ) : (
      <path d="M15.7786 7.53937L10.445 12.873C9.96875 13.3492 9.14331 13.0158 9.14331 12.3333V9.28549H4.82564C4.40339 9.28549 4.06369 8.94579 4.06369 8.52354V5.47577C4.06369 5.05353 4.40339 4.71383 4.82564 4.71383H9.14331V1.66606C9.14331 0.986661 9.96557 0.650137 10.445 1.12635L15.7786 6.45995C16.0738 6.75838 16.0738 7.24094 15.7786 7.53937ZM6.09554 12.7142V11.4443C6.09554 11.2348 5.9241 11.0634 5.71457 11.0634H3.04777C2.48584 11.0634 2.03185 10.6094 2.03185 10.0474V3.95189C2.03185 3.38996 2.48584 2.93596 3.04777 2.93596H5.71457C5.9241 2.93596 6.09554 2.76453 6.09554 2.55499V1.28509C6.09554 1.07555 5.9241 0.904118 5.71457 0.904118H3.04777C1.36515 0.904118 0 2.26926 0 3.95189V10.0474C0 11.7301 1.36515 13.0952 3.04777 13.0952H5.71457C5.9241 13.0952 6.09554 12.9238 6.09554 12.7142Z" />
    );
  }
  return (
    <svg
      width={svgWidth}
      height={svgHeight}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={defaultClass}
      aria-hidden={ariaHidden}
    >
      {iconVariant}
    </svg>
  );
};

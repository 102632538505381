import React from 'react';
import classNames from 'classnames';

import { IconOutline, IconTypes } from '../../../types';

export const IconNetworkWired = ({
  className,
  width = 24,
  height = 24,
  ariaHidden = true,
  isClickable = false,
  outline,
}: IconTypes & IconOutline): React.ReactElement => {
  const defaultClass = classNames(
    'sui-fill-current sui-icon-network-wired',
    { 'sui-pointer-events-none': !isClickable },
    className
  );
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={defaultClass}
      aria-hidden={ariaHidden}
    >
      {outline ? (
        <path d="M20 12.2V11.8C20 11.579 19.821 11.4 19.6 11.4H12.6V10.4H14.4C14.8418 10.4 15.2 10.0418 15.2 9.60001V6.40001C15.2 5.95826 14.8418 5.60001 14.4 5.60001H9.6C9.15825 5.60001 8.8 5.95826 8.8 6.40001V9.60001C8.8 10.0418 9.15825 10.4 9.6 10.4H11.4V11.4H4.4C4.179 11.4 4 11.579 4 11.8V12.2C4 12.421 4.179 12.6 4.4 12.6H7V13.6H5.6C5.15825 13.6 4.8 13.9583 4.8 14.4V17.6C4.8 18.0418 5.15825 18.4 5.6 18.4H9.6C10.0417 18.4 10.4 18.0418 10.4 17.6V14.4C10.4 13.9583 10.0417 13.6 9.6 13.6H8.2V12.6H15.8V13.6H14.4C13.9583 13.6 13.6 13.9583 13.6 14.4V17.6C13.6 18.0418 13.9583 18.4 14.4 18.4H18.4C18.8418 18.4 19.2 18.0418 19.2 17.6V14.4C19.2 13.9583 18.8418 13.6 18.4 13.6H17V12.6H19.6C19.821 12.6 20 12.421 20 12.2ZM10.4 8.80001V7.20001H13.6V8.80001H10.4ZM8.8 16.8H6.4V15.2H8.8V16.8ZM17.6 16.8H15.2V15.2H17.6V16.8Z" />
      ) : (
        <path d="M19.8 11.6H12.4V9.60001H14.4C14.8418 9.60001 15.2 9.24176 15.2 8.80001V6.40001C15.2 5.95826 14.8418 5.60001 14.4 5.60001H9.6C9.15825 5.60001 8.8 5.95826 8.8 6.40001V8.80001C8.8 9.24176 9.15825 9.60001 9.6 9.60001H11.6V11.6H4.2C4.0895 11.6 4 11.6895 4 11.8V12.2C4 12.3105 4.0895 12.4 4.2 12.4H7.2V14.4H5.6C5.15825 14.4 4.8 14.7583 4.8 15.2V17.6C4.8 18.0418 5.15825 18.4 5.6 18.4H9.6C10.0418 18.4 10.4 18.0418 10.4 17.6V15.2C10.4 14.7583 10.0418 14.4 9.6 14.4H8V12.4H16V14.4H14.4C13.9583 14.4 13.6 14.7583 13.6 15.2V17.6C13.6 18.0418 13.9583 18.4 14.4 18.4H18.4C18.8418 18.4 19.2 18.0418 19.2 17.6V15.2C19.2 14.7583 18.8418 14.4 18.4 14.4H16.8V12.4H19.8C19.9105 12.4 20 12.3105 20 12.2V11.8C20 11.6895 19.9105 11.6 19.8 11.6ZM9.6 15.2V17.6H5.6V15.2H9.6ZM9.6 8.80001V6.40001H14.4V8.80001H9.6ZM18.4 15.2V17.6H14.4V15.2H18.4Z" />
      )}
    </svg>
  );
};

import React from 'react';
import classNames from 'classnames';

import { IconOutline, IconTypes } from '../../../types';

export type IconSearchProps = {
  /**
   * @deprecated `isLight` will be remove soon. please use `outline` instead.
   */
  isLight?: boolean;
  variant?: 'default' | 'minus' | 'plus';
};

export const IconSearch = ({
  className,
  width = 24,
  height = 24,
  isLight,
  ariaHidden = true,
  isClickable = false,
  outline,
  variant,
}: IconTypes & IconSearchProps & IconOutline): React.ReactElement => {
  const defaultClass = classNames(
    'sui-fill-current sui-icon-search',
    {
      'sui-pointer-events-none': !isClickable,
      'sui-icon-search-plus': variant === 'plus',
      'sui-icon-search-minus': variant === 'minus',
      outline,
    },
    className
  );

  let iconVariant =
    isLight || outline ? (
      <path d="M19.89,19.05l-4-4a.39.39,0,0,0-.27-.11h-.32a6.51,6.51,0,1,0-.36.36v.32a.39.39,0,0,0,.11.27l4,4a.37.37,0,0,0,.53,0l.31-.31A.37.37,0,0,0,19.89,19.05ZM10.5,16A5.5,5.5,0,1,1,16,10.5,5.5,5.5,0,0,1,10.5,16Z" />
    ) : (
      <path d="M19.7811 17.833L16.6657 14.7177C16.5251 14.5771 16.3345 14.499 16.1345 14.499H15.6252C16.4876 13.396 17.0001 12.0086 17.0001 10.4994C17.0001 6.90909 14.091 4 10.5007 4C6.91043 4 4.00134 6.90909 4.00134 10.4994C4.00134 14.0896 6.91043 16.9987 10.5007 16.9987C12.0099 16.9987 13.3973 16.4863 14.5003 15.6239V16.1332C14.5003 16.3332 14.5784 16.5238 14.719 16.6644L17.8344 19.7797C18.1281 20.0734 18.603 20.0734 18.8936 19.7797L19.7779 18.8954C20.0716 18.6017 20.0716 18.1267 19.7811 17.833ZM10.5007 14.499C8.29155 14.499 6.5011 12.7116 6.5011 10.4994C6.5011 8.29021 8.28842 6.49976 10.5007 6.49976C12.7099 6.49976 14.5003 8.28708 14.5003 10.4994C14.5003 12.7085 12.713 14.499 10.5007 14.499Z" />
    );

  switch (variant) {
    case 'minus':
      iconVariant =
        isLight || outline ? (
          <path d="M13.6223 10.9964H7.37033C7.16402 10.9964 6.99522 10.8276 6.99522 10.6213V10.3713C6.99522 10.1649 7.16402 9.99614 7.37033 9.99614H13.6223C13.8286 9.99614 13.9974 10.1649 13.9974 10.3713V10.6213C13.9974 10.8276 13.8286 10.9964 13.6223 10.9964ZM19.8898 19.5366L19.5366 19.8898C19.3897 20.0367 19.1521 20.0367 19.0052 19.8898L14.9727 15.8573C14.9008 15.7854 14.8633 15.6916 14.8633 15.5916V15.3259C13.7098 16.37 12.1812 17.0045 10.5025 17.0045C6.9327 17.0045 4.04744 14.1568 4.00055 10.5869C3.95366 6.92957 6.92957 3.95366 10.5869 4.00055C14.1568 4.04744 17.0045 6.9327 17.0045 10.5025C17.0045 12.1812 16.37 13.7098 15.3259 14.8633H15.5916C15.6916 14.8633 15.7854 14.9039 15.8573 14.9727L19.8898 19.0052C20.0367 19.1521 20.0367 19.3897 19.8898 19.5366ZM16.0042 10.5025C16.0042 7.46099 13.5441 5.00085 10.5025 5.00085C7.46099 5.00085 5.00085 7.46099 5.00085 10.5025C5.00085 13.5441 7.46099 16.0042 10.5025 16.0042C13.5441 16.0042 16.0042 13.5441 16.0042 10.5025Z" />
        ) : (
          <path d="M13.5 9.99941V10.9993C13.5 11.2055 13.3313 11.3743 13.1251 11.3743H7.8756C7.66937 11.3743 7.50063 11.2055 7.50063 10.9993V9.99941C7.50063 9.79318 7.66937 9.62445 7.8756 9.62445H13.1251C13.3313 9.62445 13.5 9.79318 13.5 9.99941ZM19.7807 18.8954L18.8964 19.7797C18.6027 20.0734 18.1277 20.0734 17.8371 19.7797L14.7187 16.6644C14.5781 16.5238 14.5 16.3332 14.5 16.1332V15.6239C13.3969 16.4863 12.0096 16.9987 10.5003 16.9987C6.91007 16.9987 4.00098 14.0896 4.00098 10.4994C4.00098 6.90909 6.91007 4 10.5003 4C14.0906 4 16.9997 6.90909 16.9997 10.4994C16.9997 12.0086 16.4873 13.396 15.6248 14.499H16.1342C16.3341 14.499 16.5248 14.5771 16.6654 14.7177L19.7807 17.833C20.0713 18.1267 20.0713 18.6017 19.7807 18.8954ZM14.7499 10.4994C14.7499 8.14959 12.8501 6.24978 10.5003 6.24978C8.15057 6.24978 6.25076 8.14959 6.25076 10.4994C6.25076 12.8491 8.15057 14.749 10.5003 14.749C12.8501 14.749 14.7499 12.8491 14.7499 10.4994Z" />
        );
      break;

    case 'plus':
      iconVariant =
        isLight || outline ? (
          <path d="M13.9974 10.3775V10.6276C13.9974 10.8339 13.8286 11.0027 13.6223 11.0027H10.9964V13.6285C10.9964 13.8348 10.8276 14.0036 10.6213 14.0036H10.3713C10.1649 14.0036 9.99614 13.8348 9.99614 13.6285V11.0027H7.37033C7.16402 11.0027 6.99522 10.8339 6.99522 10.6276V10.3775C6.99522 10.1712 7.16402 10.0024 7.37033 10.0024H9.99614V7.37659C9.99614 7.17027 10.1649 7.00147 10.3713 7.00147H10.6213C10.8276 7.00147 10.9964 7.17027 10.9964 7.37659V10.0024H13.6223C13.8286 10.0024 13.9974 10.1712 13.9974 10.3775ZM19.8898 19.5366L19.5366 19.8898C19.3897 20.0367 19.1521 20.0367 19.0052 19.8898L14.9727 15.8573C14.9008 15.7854 14.8633 15.6916 14.8633 15.5916V15.3259C13.7098 16.37 12.1812 17.0045 10.5025 17.0045C6.9327 17.0045 4.04744 14.1568 4.00055 10.5869C3.95366 6.92957 6.92957 3.95366 10.5869 4.00055C14.1568 4.04744 17.0045 6.9327 17.0045 10.5025C17.0045 12.1812 16.37 13.7098 15.3259 14.8633H15.5916C15.6916 14.8633 15.7854 14.9039 15.8573 14.9727L19.8898 19.0052C20.0367 19.1521 20.0367 19.3897 19.8898 19.5366ZM16.0042 10.5025C16.0042 7.46099 13.5441 5.00085 10.5025 5.00085C7.46099 5.00085 5.00085 7.46099 5.00085 10.5025C5.00085 13.5441 7.46099 16.0042 10.5025 16.0042C13.5441 16.0042 16.0042 13.5441 16.0042 10.5025Z" />
        ) : (
          <path d="M13.5 9.99941V10.9993C13.5 11.2055 13.3313 11.3743 13.1251 11.3743H11.3753V13.1241C11.3753 13.3303 11.2065 13.4991 11.0003 13.4991H10.0004C9.79416 13.4991 9.62543 13.3303 9.62543 13.1241V11.3743H7.8756C7.66937 11.3743 7.50063 11.2055 7.50063 10.9993V9.99941C7.50063 9.79318 7.66937 9.62445 7.8756 9.62445H9.62543V7.87462C9.62543 7.66839 9.79416 7.49966 10.0004 7.49966H11.0003C11.2065 7.49966 11.3753 7.66839 11.3753 7.87462V9.62445H13.1251C13.3313 9.62445 13.5 9.79318 13.5 9.99941ZM19.7807 18.8954L18.8964 19.7797C18.6027 20.0734 18.1277 20.0734 17.8371 19.7797L14.7187 16.6644C14.5781 16.5238 14.5 16.3332 14.5 16.1332V15.6239C13.3969 16.4863 12.0096 16.9987 10.5003 16.9987C6.91007 16.9987 4.00098 14.0896 4.00098 10.4994C4.00098 6.90909 6.91007 4 10.5003 4C14.0906 4 16.9997 6.90909 16.9997 10.4994C16.9997 12.0086 16.4873 13.396 15.6248 14.499H16.1342C16.3341 14.499 16.5248 14.5771 16.6654 14.7177L19.7807 17.833C20.0713 18.1267 20.0713 18.6017 19.7807 18.8954ZM14.7499 10.4994C14.7499 8.14959 12.8501 6.24978 10.5003 6.24978C8.15057 6.24978 6.25076 8.14959 6.25076 10.4994C6.25076 12.8491 8.15057 14.749 10.5003 14.749C12.8501 14.749 14.7499 12.8491 14.7499 10.4994Z" />
        );
  }

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={defaultClass}
      aria-hidden={ariaHidden}
    >
      {iconVariant}
    </svg>
  );
};

import React, { useState } from 'react';
import classNames from 'classnames';

import { TabTitle } from './TabTitle';

export interface ITabsProps extends React.HTMLAttributes<HTMLDivElement> {
  active?: number;
  asNav?: boolean;
  children?: ReadonlyArray<React.ReactElement>;
  isLarge?: boolean;
  isSubHeader?: boolean;
  onTabChange?: (tabIndex: number) => void;
}

export const Tabs: React.FC<ITabsProps> = ({
  children,
  className,
  active = 0,
  asNav,
  isSubHeader,
  isLarge,
  onTabChange,
  ...props
}: ITabsProps) => {
  const [selectedTab, setSelectedTab] = useState(active);
  const defaultClass = classNames(
    isSubHeader ? 'sui-c-tab-subHeader sui--mb-px' : 'sui-c-tabs',
    className
  );

  const setSelectedTabHandler = (index: number) => {
    setSelectedTab(index);
    if (index !== selectedTab) {
      onTabChange?.(index);
    }
  };

  return (
    <div className={defaultClass} {...props}>
      <div className="sui-border-b sui-border-lightGray-darker">
        <div className={classNames(isSubHeader ? 'sui-px-10' : 'sui-px-0', 'sui--mb-px')}>
          <ol className="sui-c-tab-list sui-flex">
            {children?.map((item, index) => {
              if (item.props.isHidden) {
                return;
              }
              return (
                <TabTitle
                  key={index}
                  title={item.props.title}
                  className={item.props.className}
                  index={index}
                  activeTab={selectedTab}
                  setSelectedTab={setSelectedTabHandler}
                  disabled={item.props.disabled}
                  to={item.props.to}
                  exact={item.props.exact}
                  isLarge={isLarge}
                  isSettings={item.props.isSettings}
                  icon={item.props.icon}
                  {...props}
                />
              );
            })}
          </ol>
        </div>
      </div>
      {asNav ? children : children?.[selectedTab]}
    </div>
  );
};

import React from 'react';
import classNames from 'classnames';

import { IconAngle } from '../Icon';
import { Label } from '../Label';

const EMPTY_VALUE = '';

export interface SelectProps extends React.SelectHTMLAttributes<HTMLSelectElement> {
  custom?: boolean;
  isTelus?: boolean;
  required?: boolean;
  label?: string;
  placeholder?: string;
  tooltip?: React.ReactChild;
  name?: string;
  disabled?: boolean;
}

export const Select: React.FC<SelectProps> = ({
  className,
  children,
  custom,
  isTelus,
  required,
  label,
  placeholder,
  tooltip,
  name,
  id,
  disabled,
  ...props
}: SelectProps) => {
  const defaultStyle = classNames(
    'sui-border sui-rounded focus:sui-outline-none sui-placeholder-lightGray-darker sui-text-darkGray',
    { 'sui-appearance-none': custom || isTelus },
    disabled ? 'sui-border-lightGray sui-bg-lightGray-lighter' : 'sui-bg-transparent',
    isTelus
      ? 'sui-h-8 sui-px-4 sui-w-full sui-border-lightGray'
      : 'sui-w-72 sui-h-14 sui-p-base sui-border-lightGray-darkest',
    'sui-relative sui-z-10',
    className
  );

  const defaultSelect = (
    <select
      className={defaultStyle}
      aria-required={required}
      aria-disabled={disabled}
      required={required}
      disabled={disabled}
      name={name}
      id={id}
      {...props}
    >
      {placeholder ? (
        <option value={EMPTY_VALUE} disabled hidden>
          {placeholder}
        </option>
      ) : null}
      {children}
    </select>
  );
  return isTelus ? (
    <>
      {label ? (
        <Label tooltip={tooltip} required={required} htmlFor={id}>
          {label}
        </Label>
      ) : null}
      <div className="sui-relative sui-rounded sui-bg-white z-0">
        {defaultSelect}
        <span className="sui-absolute sui-right-4 sui-top-0 sui-flex sui-items-center sui-z-0">
          <span className="sui-w-px sui-h-4 sui-bg-lightGray sui-my-sm sui-mr-3" />
          <IconAngle variant="down" width={12} height={8} className="sui-text-darkGray" />
        </span>
      </div>
    </>
  ) : (
    <>
      {defaultSelect}
      {custom ? (
        <>
          <span className="sui-absolute sui-right-9 sui-w-px sui-h-xl sui-bg-lightGray-darkest sui-my-sm" />
          <IconAngle variant="down" width={12} height={8} className="sui-text-darkGray" />
        </>
      ) : null}
    </>
  );
};

import React from 'react';
import Quill from 'quill';
import { renderToStaticMarkup } from 'react-dom/server';

import {
  IconAlignCenter,
  IconAlignJustify,
  IconAlignLeft,
  IconAlignRight,
  IconBold,
  IconFillDrip,
  IconFontColor,
  IconImage,
  IconIndent,
  IconIndentLeft,
  IconItalic,
  IconLink,
  IconListOl,
  IconListUl,
  IconStrikethrough,
  IconUnderline,
} from '../Icon';

export const importCustomIconsToQuill = (): void => {
  const icons = Quill.import('ui/icons');

  icons['bold'] = renderToStaticMarkup(<IconBold className="ql-fill" />);
  icons['italic'] = renderToStaticMarkup(<IconItalic className="ql-fill" />);
  icons['underline'] = renderToStaticMarkup(<IconUnderline className="ql-fill" />);
  icons['strike'] = renderToStaticMarkup(<IconStrikethrough className="ql-fill" />);
  icons['color'] = renderToStaticMarkup(<IconFontColor className="ql-fill" />);
  icons['background'] = renderToStaticMarkup(<IconFillDrip className="ql-fill" />);
  icons['align'][''] = renderToStaticMarkup(<IconAlignLeft className="ql-fill" />);
  icons['align']['center'] = renderToStaticMarkup(<IconAlignCenter className="ql-fill" />);
  icons['align']['right'] = renderToStaticMarkup(<IconAlignRight className="ql-fill" />);
  icons['align']['justify'] = renderToStaticMarkup(<IconAlignJustify className="ql-fill" />);
  icons['list']['bullet'] = renderToStaticMarkup(<IconListUl className="ql-fill" />);
  icons['list']['ordered'] = renderToStaticMarkup(<IconListOl className="ql-fill" />);
  icons['indent']['+1'] = renderToStaticMarkup(<IconIndent className="ql-fill" />);
  icons['indent']['-1'] = renderToStaticMarkup(<IconIndentLeft className="ql-fill" />);
  icons['link'] = renderToStaticMarkup(<IconLink className="ql-fill" />);
  icons['image'] = renderToStaticMarkup(<IconImage className="ql-fill" />);
};

import React from 'react';
import classNames from 'classnames';

import { IconCheck, IconTimes } from '../Icon';

export interface IPillsProps extends React.HTMLAttributes<HTMLDivElement> {
  label: string;
  variant?: 'default' | 'dark' | 'white';
  /**
   * @deprecated `type` is deprecated and no longer required to implement pills
   */
  type?: 'default' | 'icon' | 'initial' | 'image';
  icon?: React.ReactElement;
  initial?: string;
  imageUrl?: string;
  isDisabled?: boolean;
  isSelected?: boolean;
  isSmall?: boolean;
  isRemovable?: boolean;
  onclickRemovable?: (e: React.MouseEvent<HTMLElement>) => Promise<void>;
}

const InnerPills = (
  {
    className,
    icon,
    initial,
    imageUrl,
    isDisabled,
    isSelected,
    isSmall,
    label,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    type,
    variant = 'default',
    isRemovable,
    onclickRemovable,
    ...props
  }: IPillsProps,
  ref: React.Ref<HTMLDivElement>
) => {
  // ##### Main CSS variables used in the JSX (reusable css) #####
  const flexboxBaseClass =
    'sui-flex sui-flex-row sui-flex-nowrap sui-items-center sui-justify-center';

  // parent container heighest level html element
  let parentContainerClass = classNames(
    'sui-rounded-3xl sui-w-max',
    { 'sui-pointer-events-none': isDisabled },
    flexboxBaseClass,
    {
      'focus:sui-border-2 focus:sui-border-primary-light':
        !isRemovable && (!isDisabled || isSelected),
    }
  );

  // outer child container relates the to both out html elements col 1 & 3 (2 being centeral)
  let outerChildContainerClass = classNames(
    isSmall ? 'sui-h-5 sui-w-5' : 'sui-h-6 sui-w-6',
    'sui-rounded-full sui-text-h5 sui-font-heading sui-font-bold',
    flexboxBaseClass
  );
  const outerChildContainerInitialClass = classNames(
    isDisabled
      ? 'sui-border sui-border-lightGray sui-bg-lighGray-lightest sui-text-lightGray-darkest'
      : 'sui-border sui-border-lightGray-darker sui-bg-secondary-bg sui-text-secondary-darker'
  );
  const outerChildContainerImageClass = classNames(
    { 'sui-opacity-30': isDisabled },
    'sui-h-full sui-w-full sui-bg-contain sui-rounded-full sui-bg-no-repeat',
    'sui-border sui-border-lightGray-darker'
  );
  let outerChildContainerButtonClass = classNames(
    'sui-h-full sui-w-full sui-rounded-full',
    flexboxBaseClass,
    'focus:sui-outline-none focus:sui-border-2 focus:sui-border-primary-light'
  );

  // inner child container relates to the centeral html element col 2
  let innerChildContainerClass = classNames('sui-mx-2', flexboxBaseClass, 'sui-gap-0.5');
  const innerChildContainerIconClass = classNames('sui-h-4 sui-w-4', flexboxBaseClass);
  const innerChildContainerLabelClass = classNames(
    'sui-text-center',
    !isSmall ? 'sui-text-b4 sui-leading-6' : 'sui-text-b5 sui-leading-5',
    flexboxBaseClass
  );

  // is selected class relates to repeating state css
  const isSelectedStateClass =
    'sui-bg-primary-lighter sui-text-primary sui-border sui-border-primary';

  // ##### SWITCH statement (css helper logic - non-reusable css) #####
  switch (variant) {
    case 'default':
      parentContainerClass = classNames(
        parentContainerClass,
        { 'sui-bg-lightGray-lightest sui-text-lightGray-darkest': isDisabled && !isSelected },
        { [`${isSelectedStateClass}`]: isSelected && !isDisabled },
        { 'sui-bg-lightGray': (!isDisabled && !isSelected) || (isDisabled && isSelected) },
        {
          'hover:sui-bg-input-clear active:sui-bg-input-clear-24':
            !isRemovable && ((!isDisabled && !isSelected) || (isDisabled && isSelected)),
        },
        className
      );
      outerChildContainerButtonClass = classNames(outerChildContainerButtonClass, {
        'hover:sui-bg-input-clear active:sui-bg-input-clear-24':
          (!isDisabled && !isSelected) || (isDisabled && isSelected),
      });
      break;
    case 'dark':
      parentContainerClass = classNames(
        parentContainerClass,
        { 'sui-bg-lightGray-darkest': isDisabled && !isSelected },
        { [`${isSelectedStateClass}`]: isSelected && !isDisabled },
        { 'sui-bg-darkGray': (!isDisabled && !isSelected) || (isDisabled && isSelected) },
        {
          'hover:sui-bg-input-clear-24 active:sui-bg-input-clear-32':
            !isRemovable && ((!isDisabled && !isSelected) || (isDisabled && isSelected)),
        }
      );
      innerChildContainerClass = classNames(innerChildContainerClass, {
        'sui-text-white': !isSelected,
      });
      outerChildContainerButtonClass = classNames(
        outerChildContainerButtonClass,
        {
          'hover:sui-bg-input-clear-24 active:sui-bg-input-clear-32':
            (!isDisabled && !isSelected) || (isDisabled && isSelected),
        },
        { 'sui-text-white': !isSelected }
      );
      break;
    case 'white':
      parentContainerClass = classNames(
        parentContainerClass,
        { 'sui-border sui-border-lightGray sui-text-lightGray-darkest': isDisabled && !isSelected },
        { [`${isSelectedStateClass}`]: isSelected && !isDisabled },
        {
          'sui-bg-white sui-border sui-border-lightGray-darker':
            (!isDisabled && !isSelected) || (isDisabled && isSelected),
        },
        {
          'hover:sui-bg-primary hover:sui-bg-opacity-8 active:sui-bg-opacity-16':
            !isRemovable && ((!isDisabled && !isSelected) || (isDisabled && isSelected)),
        }
      );
      outerChildContainerClass = classNames(
        outerChildContainerClass,
        'sui--ml-px sui--mt-px sui--mb-px'
      );
      outerChildContainerButtonClass = classNames(outerChildContainerButtonClass, {
        'hover:sui-bg-primary hover:sui-bg-opacity-8 active:sui-bg-opacity-16':
          (!isDisabled && !isSelected) || (isDisabled && isSelected),
      });
      break;

    default:
      break;
  }

  // ##### main JSX holds html structure and returns componets to user #####
  return (
    <div className={parentContainerClass} ref={ref} {...props}>
      {initial && !imageUrl && (
        <div className={classNames(outerChildContainerClass, outerChildContainerInitialClass)}>
          {initial}
        </div>
      )}
      {imageUrl && !initial && (
        <div className={outerChildContainerClass}>
          <div
            style={{ backgroundImage: `url('${imageUrl}')` }}
            className={outerChildContainerImageClass}
          ></div>
        </div>
      )}
      <div className={innerChildContainerClass}>
        {isSelected && !isDisabled && (
          <span className={classNames(innerChildContainerIconClass, 'sui-mr-1.5')}>
            <IconCheck />
          </span>
        )}
        {icon && <span className={innerChildContainerIconClass}>{icon}</span>}
        <span className={innerChildContainerLabelClass} role="label">
          {label}
        </span>
      </div>
      {isRemovable && (
        <div className={classNames(outerChildContainerClass, 'sui-border-none')}>
          <div role="button" className={outerChildContainerButtonClass} onClick={onclickRemovable}>
            <IconTimes height={16} width={16} />
          </div>
        </div>
      )}
    </div>
  );
};

export const Pills = React.forwardRef(InnerPills);

import React from 'react';
import classNames from 'classnames';

import { IconTypes } from '../../../types';

export const IconFontColor = ({
  className,
  width = 24,
  height = 24,
  ariaHidden = true,
  isClickable = false,
}: IconTypes): React.ReactElement => {
  const defaultClass = classNames(
    'sui-fill-current',
    { 'sui-pointer-events-none': !isClickable },
    className
  );

  const iconVariant = (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.9444 14.2857H17.5714C17.6851 14.2857 17.7941 14.3309 17.8745 14.4112C17.9548 14.4916 18 14.6006 18 14.7143V15.5714C18 15.6851 17.9548 15.7941 17.8745 15.8745C17.7941 15.9548 17.6851 16 17.5714 16H14.1429C14.0292 16 13.9202 15.9548 13.8398 15.8745C13.7594 15.7941 13.7143 15.6851 13.7143 15.5714V14.7143C13.7143 14.6006 13.7594 14.4916 13.8398 14.4112C13.9202 14.3309 14.0292 14.2857 14.1429 14.2857H14.6673L14.0432 12.5714H9.95679L9.33268 14.2857H9.85714C9.97081 14.2857 10.0798 14.3309 10.1602 14.4112C10.2406 14.4916 10.2857 14.6006 10.2857 14.7143V15.5714C10.2857 15.6851 10.2406 15.7941 10.1602 15.8745C10.0798 15.9548 9.97081 16 9.85714 16H6.42857C6.31491 16 6.2059 15.9548 6.12553 15.8745C6.04515 15.7941 6 15.6851 6 15.5714V14.7143C6 14.6006 6.04515 14.4916 6.12553 14.4112C6.2059 14.3309 6.31491 14.2857 6.42857 14.2857H7.05562L10.5568 4.58098C10.6145 4.41152 10.7237 4.26437 10.8693 4.16017C11.0149 4.05597 11.1894 3.99996 11.3684 4H12.6316C12.8106 3.99996 12.9851 4.05597 13.1307 4.16017C13.2763 4.26437 13.3855 4.41152 13.4432 4.58098L16.9444 14.2857ZM12 6.96009L10.7371 10.4286H13.2629L12 6.96009ZM5 18C4.44772 18 4 18.4477 4 19C4 19.5523 4.44772 20 5 20H19C19.5523 20 20 19.5523 20 19C20 18.4477 19.5523 18 19 18H5Z"
    />
  );
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={defaultClass}
      aria-hidden={ariaHidden}
    >
      {iconVariant}
    </svg>
  );
};

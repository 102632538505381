import React from 'react';
import classNames from 'classnames';

import { IconTypes } from '../../../types';

type IIconUnderlineProps = {
  isLight?: boolean;
};

export const IconUnderline = ({
  className,
  width = 24,
  height = 24,
  isLight,
  ariaHidden = true,
  isClickable = false,
}: IconTypes & IIconUnderlineProps): React.ReactElement => {
  const defaultClass = classNames(
    'sui-fill-current',
    { 'sui-pointer-events-none': !isClickable },
    className
  );

  const iconVariant = isLight ? (
    <path d="M18.75 4H14.25C14.1837 4 14.1201 4.02634 14.0732 4.07322C14.0263 4.12011 14 4.1837 14 4.25V4.75C14 4.8163 14.0263 4.87989 14.0732 4.92678C14.1201 4.97366 14.1837 5 14.25 5H16V12C16 13.0609 15.5786 14.0783 14.8284 14.8284C14.0783 15.5786 13.0609 16 12 16C10.9391 16 9.92172 15.5786 9.17157 14.8284C8.42143 14.0783 8 13.0609 8 12V5H9.75C9.8163 5 9.87989 4.97366 9.92678 4.92678C9.97366 4.87989 10 4.8163 10 4.75V4.25C10 4.1837 9.97366 4.12011 9.92678 4.07322C9.87989 4.02634 9.8163 4 9.75 4H5.25C5.1837 4 5.12011 4.02634 5.07322 4.07322C5.02634 4.12011 5 4.1837 5 4.25V4.75C5 4.8163 5.02634 4.87989 5.07322 4.92678C5.12011 4.97366 5.1837 5 5.25 5H7V12C7 14.7569 9.24312 17 12 17C14.7569 17 17 14.7569 17 12V5H18.75C18.8163 5 18.8799 4.97366 18.9268 4.92678C18.9737 4.87989 19 4.8163 19 4.75V4.25C19 4.1837 18.9737 4.12011 18.9268 4.07322C18.8799 4.02634 18.8163 4 18.75 4V4ZM18.75 19H5.25C5.1837 19 5.12011 19.0263 5.07322 19.0732C5.02634 19.1201 5 19.1837 5 19.25V19.75C5 19.8163 5.02634 19.8799 5.07322 19.9268C5.12011 19.9737 5.1837 20 5.25 20H18.75C18.8163 20 18.8799 19.9737 18.9268 19.9268C18.9737 19.8799 19 19.8163 19 19.75V19.25C19 19.1837 18.9737 19.1201 18.9268 19.0732C18.8799 19.0263 18.8163 19 18.75 19Z" />
  ) : (
    <path d="M6 6H7V11C7 13.7569 9.24312 16 12 16C14.7569 16 17 13.7569 17 11V6H18C18.1326 6 18.2598 5.94732 18.3536 5.85355C18.4473 5.75979 18.5 5.63261 18.5 5.5V4.5C18.5 4.36739 18.4473 4.24021 18.3536 4.14645C18.2598 4.05268 18.1326 4 18 4H13.5C13.3674 4 13.2402 4.05268 13.1464 4.14645C13.0527 4.24021 13 4.36739 13 4.5V5.5C13 5.63261 13.0527 5.75979 13.1464 5.85355C13.2402 5.94732 13.3674 6 13.5 6H14.5V11C14.5 11.663 14.2366 12.2989 13.7678 12.7678C13.2989 13.2366 12.663 13.5 12 13.5C11.337 13.5 10.7011 13.2366 10.2322 12.7678C9.76339 12.2989 9.5 11.663 9.5 11V6H10.5C10.6326 6 10.7598 5.94732 10.8536 5.85355C10.9473 5.75979 11 5.63261 11 5.5V4.5C11 4.36739 10.9473 4.24021 10.8536 4.14645C10.7598 4.05268 10.6326 4 10.5 4H6C5.86739 4 5.74021 4.05268 5.64645 4.14645C5.55268 4.24021 5.5 4.36739 5.5 4.5V5.5C5.5 5.63261 5.55268 5.75979 5.64645 5.85355C5.74021 5.94732 5.86739 6 6 6ZM18.5 18H5.5C5.36739 18 5.24021 18.0527 5.14645 18.1464C5.05268 18.2402 5 18.3674 5 18.5V19.5C5 19.6326 5.05268 19.7598 5.14645 19.8536C5.24021 19.9473 5.36739 20 5.5 20H18.5C18.6326 20 18.7598 19.9473 18.8536 19.8536C18.9473 19.7598 19 19.6326 19 19.5V18.5C19 18.3674 18.9473 18.2402 18.8536 18.1464C18.7598 18.0527 18.6326 18 18.5 18Z" />
  );
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={defaultClass}
      aria-hidden={ariaHidden}
    >
      {iconVariant}
    </svg>
  );
};

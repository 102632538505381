import React from 'react';
import classNames from 'classnames';

import { IconTypes } from '../../../types';

export type IconChevronProps = {
  variant?: 'up' | 'down' | 'left' | 'right';
  isLight?: boolean;
};

export const IconChevron = ({
  className,
  width,
  height,
  variant = 'right',
  isLight,
  ariaHidden = true,
  isClickable = false,
}: IconTypes & IconChevronProps): React.ReactElement => {
  const defaultClass = classNames(
    'sui-fill-current sui-icon-chevron',
    { 'sui-pointer-events-none': !isClickable },
    className
  );
  const viewBox = '0 0 24 24';
  const svgWidth = width ? width : 24;
  const svgHeight = height ? height : 24;
  let iconVariant = (
    // Right
    <path d="M16.5973 12.6218L9.47667 19.7424C9.13325 20.0859 8.57647 20.0859 8.23309 19.7424L7.40258 18.9119C7.05974 18.5691 7.05908 18.0134 7.40111 17.6698L13.0444 12L7.40111 6.33019C7.05908 5.98655 7.05974 5.43091 7.40258 5.08808L8.23309 4.25757C8.57651 3.91414 9.13328 3.91414 9.47667 4.25757L16.5973 11.3782C16.9407 11.7216 16.9407 12.2784 16.5973 12.6218Z" />
  );

  if (variant === 'right' && isLight) {
    iconVariant = (
      <path d="M8.03787 4.12608L7.78426 4.37966C7.61619 4.54773 7.61619 4.82024 7.78426 4.98834L14.7959 12L7.78426 19.0117C7.61619 19.1798 7.61619 19.4523 7.78426 19.6204L8.03787 19.8739C8.20594 20.042 8.47845 20.042 8.64652 19.8739L16.2162 12.3043C16.3842 12.1363 16.3842 11.8638 16.2162 11.6957L8.64652 4.12608C8.47845 3.95797 8.20594 3.95797 8.03787 4.12608Z" />
    );
  }

  if (variant === 'down') {
    iconVariant = (
      <path d="M11.3782 16.5973L4.25757 9.47667C3.91414 9.13325 3.91414 8.57647 4.25757 8.23309L5.08808 7.40258C5.43091 7.05974 5.98655 7.05908 6.33019 7.40111L12 13.0444L17.6698 7.40111C18.0134 7.05908 18.5691 7.05974 18.9119 7.40258L19.7424 8.23309C20.0859 8.57651 20.0859 9.13328 19.7424 9.47667L12.6218 16.5973C12.2784 16.9407 11.7216 16.9407 11.3782 16.5973Z" />
    );
  }

  if (variant === 'down' && isLight) {
    iconVariant = (
      <path d="M19.874 8.0373L19.6194 7.78267C19.4508 7.61411 19.1783 7.61411 19.0097 7.78267L12.002 14.794L4.99073 7.78267C4.82217 7.61411 4.54961 7.61411 4.38105 7.78267L4.12642 8.0373C3.95786 8.20586 3.95786 8.47842 4.12642 8.64698L11.6936 16.2177C11.8621 16.3863 12.1347 16.3863 12.3033 16.2177L19.8704 8.64698C20.0426 8.47842 20.0426 8.20586 19.874 8.0373Z" />
    );
  }

  if (variant === 'left') {
    iconVariant = (
      <path d="M7.4025 11.378L14.5234 4.25749C14.8667 3.91417 15.4237 3.91417 15.767 4.25749L16.5976 5.08811C16.9405 5.43105 16.9409 5.98651 16.5991 6.33019L10.9555 11.9998L16.5987 17.6698C16.9409 18.0135 16.9402 18.5689 16.5972 18.9119L15.7666 19.7425C15.4233 20.0858 14.8664 20.0858 14.5231 19.7425L7.4025 12.6216C7.05919 12.2783 7.05919 11.7214 7.4025 11.378Z" />
    );
  }

  if (variant === 'left' && isLight) {
    iconVariant = (
      <path d="M15.9625 19.8739L16.2162 19.6203C16.3842 19.4523 16.3842 19.1798 16.2162 19.0117L9.20453 12L16.2162 4.98832C16.3842 4.82025 16.3842 4.54773 16.2162 4.37963L15.9625 4.12605C15.7945 3.95798 15.522 3.95798 15.3539 4.12605L7.78426 11.6957C7.61619 11.8637 7.61619 12.1362 7.78426 12.3043L15.3539 19.874C15.522 20.042 15.7945 20.042 15.9625 19.8739Z" />
    );
  }

  if (variant === 'up') {
    iconVariant = (
      <path d="M12.6218 7.40255L19.7424 14.5232C20.0859 14.8666 20.0859 15.4234 19.7424 15.7668L18.9119 16.5973C18.5691 16.9401 18.0134 16.9408 17.6698 16.5988L12 10.9555L6.33019 16.5988C5.98655 16.9408 5.43091 16.9402 5.08808 16.5973L4.25757 15.7668C3.91414 15.4234 3.91414 14.8666 4.25757 14.5232L11.3782 7.40259C11.7216 7.05917 12.2784 7.05917 12.6218 7.40255Z" />
    );
  }

  if (variant === 'up' && isLight) {
    iconVariant = (
      <path d="M4.12608 15.9626L4.37965 16.2162C4.54773 16.3842 4.82024 16.3842 4.98834 16.2162L12 9.20453L19.0117 16.2162C19.1798 16.3842 19.4523 16.3842 19.6204 16.2162L19.8739 15.9626C20.042 15.7945 20.042 15.522 19.8739 15.3539L12.3043 7.78426C12.1363 7.61619 11.8638 7.61619 11.6957 7.78426L4.12608 15.3539C3.95797 15.522 3.95797 15.7945 4.12608 15.9626Z" />
    );
  }
  return (
    <svg
      width={svgWidth}
      height={svgHeight}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={defaultClass}
      aria-hidden={ariaHidden}
    >
      {iconVariant}
    </svg>
  );
};

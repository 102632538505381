import React from 'react';
import classNames from 'classnames';

import { IconTypes } from '../../../types';

type IconQuestionProps = {
  variant?: 'default' | 'circle' | 'square';
};

export const IconQuestion = ({
  className,
  width,
  height,
  variant = 'default',
  ariaHidden = true,
  isClickable = false,
}: IconTypes & IconQuestionProps): React.ReactElement => {
  const defaultClass = classNames(
    'sui-fill-current',
    { 'sui-pointer-events-none': !isClickable },
    className
  );
  let viewBox = '0 0 12 16';
  let svgWidth = width ? width : 12;
  let svgHeight = height ? height : 16;
  let iconVariant = (
    // Default
    <path d="M6.01471 0C3.52036 0 1.90477 1.02197 0.636364 2.84456C0.40627 3.17519 0.477208 3.6285 0.798176 3.87188L2.14624 4.89403C2.4704 5.13981 2.93161 5.08234 3.1854 4.76441C3.96818 3.78375 4.54883 3.21913 5.77155 3.21913C6.73293 3.21913 7.92205 3.83784 7.92205 4.77009C7.92205 5.47484 7.34027 5.83678 6.39102 6.36897C5.28405 6.98959 3.81918 7.76197 3.81918 9.69413V10C3.81918 10.4142 4.15496 10.75 4.56918 10.75H6.8339C7.24811 10.75 7.5839 10.4142 7.5839 10V9.81959C7.5839 8.48022 11.4985 8.42444 11.4985 4.8C11.4986 2.0705 8.66724 0 6.01471 0ZM5.70155 11.6706C4.50793 11.6706 3.53683 12.6417 3.53683 13.8353C3.53683 15.0289 4.50793 16 5.70155 16C6.89518 16 7.86627 15.0289 7.86627 13.8353C7.86627 12.6417 6.89518 11.6706 5.70155 11.6706Z" />
  );

  if (variant === 'circle') {
    viewBox = '0 0 16 16';
    svgWidth = width ? width : 16;
    svgHeight = height ? height : 16;
    iconVariant = (
      <path d="M16 8C16 12.4193 12.418 16 8 16C3.58203 16 0 12.4193 0 8C0 3.58332 3.58203 0 8 0C12.418 0 16 3.58332 16 8ZM8.21468 2.64516C6.45671 2.64516 5.33548 3.38571 4.45503 4.70187C4.34097 4.87239 4.37913 5.10236 4.54261 5.22632L5.66194 6.07503C5.82984 6.20235 6.06906 6.17206 6.19952 6.00658C6.77577 5.27568 7.1709 4.85184 8.048 4.85184C8.707 4.85184 9.52213 5.27597 9.52213 5.915C9.52213 6.3981 9.12332 6.64619 8.47264 7.011C7.71381 7.43639 6.70968 7.96584 6.70968 9.29032V9.41935C6.70968 9.63313 6.883 9.80645 7.09677 9.80645H8.90323C9.117 9.80645 9.29032 9.63313 9.29032 9.41935V9.37635C9.29032 8.45823 11.9737 8.42 11.9737 5.93548C11.9737 4.06445 10.0329 2.64516 8.21468 2.64516ZM8 10.6452C7.18177 10.6452 6.51613 11.3108 6.51613 12.129C6.51613 12.9472 7.18177 13.6129 8 13.6129C8.81823 13.6129 9.48387 12.9472 9.48387 12.129C9.48387 11.3108 8.81823 10.6452 8 10.6452Z" />
    );
  }

  if (variant === 'square') {
    viewBox = '0 0 16 16';
    svgWidth = width ? width : 16;
    svgHeight = height ? height : 16;
    iconVariant = (
      <path d="M14.2857 0H1.71429C0.7675 0 0 0.7675 0 1.71429V14.2857C0 15.2325 0.7675 16 1.71429 16H14.2857C15.2325 16 16 15.2325 16 14.2857V1.71429C16 0.7675 15.2325 0 14.2857 0ZM8 14.2143C7.09411 14.2143 6.35714 13.4773 6.35714 12.5714C6.35714 11.6655 7.09411 10.9286 8 10.9286C8.90589 10.9286 9.64286 11.6655 9.64286 12.5714C9.64286 13.4773 8.90589 14.2143 8 14.2143ZM9.42857 9.52382V9.57143C9.42857 9.80811 9.23668 10 9 10H7C6.76332 10 6.57143 9.80811 6.57143 9.57143V9.42857C6.57143 7.96218 7.68314 7.376 8.52329 6.905C9.24368 6.50111 9.68521 6.22643 9.68521 5.69157C9.68521 4.98407 8.78275 4.5145 8.05314 4.5145C7.08207 4.5145 6.64464 4.98375 6.00661 5.79296C5.86214 5.97618 5.59732 6.00971 5.41143 5.86875L4.17218 4.92911C3.99118 4.79186 3.94893 4.53725 4.07521 4.34846C5.05 2.89132 6.29136 2.07143 8.23768 2.07143C10.2508 2.07143 12.3995 3.64279 12.3995 5.71429C12.3995 8.465 9.42857 8.50732 9.42857 9.52382Z" />
    );
  }
  return (
    <svg
      width={svgWidth}
      height={svgHeight}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={defaultClass}
      aria-hidden={ariaHidden}
    >
      {iconVariant}
    </svg>
  );
};

import React from 'react';
import classNames from 'classnames';

export interface ISwitchProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  isLarge?: boolean;
  isInvalid?: boolean;
}

export const Switch: React.FC<ISwitchProps> = ({
  className,
  checked,
  children,
  disabled,
  isLarge,
  isInvalid,
  label,
  ...props
}: ISwitchProps) => {
  let textSize = 'sui-text-b4';
  let switchSize = 'sui-w-8 sui-h-base sui-mr-2';
  let errorClass = '';
  let textColor = '';

  // Text Size
  if (isLarge) {
    textSize = 'sui-text-b3';
    switchSize = 'sui-u-switch-large sui-mr-2.5';
  }

  if (disabled) {
    textColor = 'sui-text-lightGray-darkest';
  }

  if (isInvalid) {
    errorClass = 'is-invalid';
  }

  const defaultStyle = classNames(
    'sui-flex sui-items-start sui-cursor-pointer sui-text-base',
    className,
    textSize,
    errorClass,
    textColor
  );

  const inputSwitchClass = classNames(
    'sui-bg-white sui-inline-flex sui-items-center sui-mr-2 sui-rounded-full sui-border sui-border-lightGray-darker sui-flex-no-shrink sui-px-0.5 sui-transition-colors sui-ease-in-out sui-duration-200 sui-flex-shrink-0',
    switchSize
  );

  return (
    <div className="sui-c-switch">
      <label tabIndex={0} className={defaultStyle}>
        <input
          tabIndex={-1}
          type="checkbox"
          className="sui-hidden"
          checked={checked}
          disabled={disabled}
          aria-checked={checked}
          aria-disabled={disabled}
          {...props}
        />
        <span className={`sui-input-subs sui-flex sui-items-center sui-h-${isLarge ? 6 : 5}`}>
          <span className={inputSwitchClass}></span>
        </span>
        {children ? <div>{children}</div> : label}
      </label>
    </div>
  );
};

import React from 'react';
import classNames from 'classnames';

import { IconTypes } from '../../../types';

export const LogoOnly = ({
  className,
  width = 24,
  height = 24,
  ariaHidden = true,
  isClickable = false,
}: IconTypes): React.ReactElement => {
  const defaultClass = classNames(
    'sui-fill-current',
    { 'sui-pointer-events-none': !isClickable },
    className
  );
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 42 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={defaultClass}
      aria-hidden={ariaHidden}
    >
      <path
        d="M41.8634 0.349521L23.6355 3.99635V5.27274L23.6946 5.25578C35.7382 2.31711 41.8634 0.548825 41.914 0.536103C41.9816 0.519141 42.0069 0.464015 41.9985 0.42161C41.9858 0.374964 41.9394 0.3368 41.8634 0.349521Z"
        fill="white"
      />
      <path
        d="M26.5651 1.3715C26.8479 1.18916 26.9281 1.30366 26.8437 1.5072C26.7128 1.83372 26.0965 3.81827 21.5037 7.60079C20.3048 8.58459 19.4563 9.14009 17.7298 10.1663C20.5539 6.19295 24.6781 2.60125 26.5651 1.3715ZM27.0421 0.00182516C26.1556 0.0527111 24.5135 1.37151 23.6144 2.156C20.0262 5.27276 17.3625 8.13934 15.4207 11.1374C11.3766 13.3127 6.22652 15.5475 1.106 17.2946L0.98358 17.337L0 20L0.561443 19.7795C3.81612 18.5328 9.24903 16.192 14.1754 13.5926C13.8968 14.3347 13.7575 15.0174 13.7575 15.6408C13.7575 16.1072 13.8334 16.5355 13.9896 16.9299C14.2978 17.7101 14.9099 18.3038 15.7542 18.643C17.0164 19.1477 18.7345 19.1604 20.6974 18.5625C25.7082 17.0359 31.6561 12.3501 32.952 11.4172L32.9985 11.3833C33.0491 11.3451 33.0533 11.29 33.0322 11.2519C33.0027 11.2095 32.9436 11.1967 32.8972 11.2264L32.8338 11.2646C31.445 12.1 24.695 16.1242 20.3048 17.3073C18.0717 17.9094 16.3663 17.5787 15.7246 16.6755C15.5009 16.3659 15.3953 15.9758 15.3953 15.522C15.3953 14.6358 15.809 13.5036 16.6153 12.2484C17.3541 11.8243 18.0421 11.413 18.6627 11.0229C22.9432 8.60579 27.8779 3.90732 28.0848 1.04075C28.0848 1.01954 28.0848 0.998341 28.0848 0.977139C28.0848 0.73119 27.9793 0.476761 27.7893 0.285938C27.5951 0.0866351 27.3292 -0.0151368 27.0421 0.00182516Z"
        fill="white"
      />
      <path
        d="M23.5764 4.00909L4.39872 8.15205L3.65576 10.1536L23.6355 5.27275L23.6651 5.26428V3.99213L23.5764 4.00909Z"
        fill="white"
      />
    </svg>
  );
};

import React from 'react';
import classNames from 'classnames';

import { IconOutline, IconTypes } from '../../../types';

export const IconPlus = ({
  className,
  width,
  height,
  outline,
  ariaHidden = true,
  isClickable = false,
}: IconTypes & IconOutline): React.ReactElement => {
  const defaultClass = classNames(
    'sui-fill-current',
    { 'sui-pointer-events-none': !isClickable },
    className
  );
  const viewBox = '0 0 16 16';
  const svgWidth = width ? width : 16;
  const svgHeight = height ? height : 16;
  const iconVariant = outline ? (
    <path d="M15.6667 7H9V0.333333C9 0.149167 8.85083 0 8.66667 0H7.33333C7.14917 0 7 0.149167 7 0.333333V7H0.333333C0.149167 7 0 7.14917 0 7.33333V8.66667C0 8.85083 0.149167 9 0.333333 9H7V15.6667C7 15.8508 7.14917 16 7.33333 16H8.66667C8.85083 16 9 15.8508 9 15.6667V9H15.6667C15.8508 9 16 8.85083 16 8.66667V7.33333C16 7.14917 15.8508 7 15.6667 7Z" />
  ) : (
    <path d="M14.8571 6.28571H9.71429V1.14286C9.71429 0.511786 9.2025 0 8.57143 0H7.42857C6.7975 0 6.28571 0.511786 6.28571 1.14286V6.28571H1.14286C0.511786 6.28571 0 6.7975 0 7.42857V8.57143C0 9.2025 0.511786 9.71429 1.14286 9.71429H6.28571V14.8571C6.28571 15.4882 6.7975 16 7.42857 16H8.57143C9.2025 16 9.71429 15.4882 9.71429 14.8571V9.71429H14.8571C15.4882 9.71429 16 9.2025 16 8.57143V7.42857C16 6.7975 15.4882 6.28571 14.8571 6.28571Z" />
  );

  return (
    <svg
      width={svgWidth}
      height={svgHeight}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={defaultClass}
      aria-hidden={ariaHidden}
    >
      {iconVariant}
    </svg>
  );
};

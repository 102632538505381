import React from 'react';
import { Placement } from '@popperjs/core';
import { PopperArrowProps } from 'react-popper';
import classNames from 'classnames';

import { Overlay, IOverlayProps } from './Overlay';
import { OverlayTrigger, IOverlayTriggerProps } from './OverlayTrigger';

export interface ITooltipProps
  extends React.HtmlHTMLAttributes<HTMLDivElement>,
    Omit<IOverlayProps, 'children'> {
  children: React.ReactChild;
  hideArrow?: boolean;
  isDescriptive?: boolean;
}

export interface ITooltipElementProps extends React.HtmlHTMLAttributes<HTMLDivElement> {
  hideArrow?: boolean;
  arrowProps?: PopperArrowProps;
  placement?: Placement;
  isDescriptive?: boolean;
}

export interface IUncontrolledTooltipProps
  extends Omit<React.HtmlHTMLAttributes<HTMLDivElement>, 'children'>,
    Omit<IOverlayTriggerProps, 'overlay'> {
  content: React.ReactChild;
  hideArrow?: boolean;
  isDescriptive?: boolean;
  isTruncated?: boolean;
}

const TooltipElement = React.forwardRef<HTMLDivElement, ITooltipElementProps>(
  (
    {
      className = '',
      children,
      id,
      arrowProps,
      hideArrow,
      placement,
      isDescriptive,
      ...props
    }: ITooltipElementProps,
    ref
  ) => {
    return (
      <div
        role="tooltip"
        className={`sui-c-tooltip ${className}`}
        id={id}
        ref={ref}
        data-popper-placement={placement}
        {...props}
      >
        <div
          className={classNames(
            'sui-c-tooltip-inner sui-max-w-xs',
            isDescriptive ? 'sui-p-4' : 'sui-px-4 sui-py-1'
          )}
        >
          {children}
        </div>
        {!hideArrow ? (
          <div
            className="sui-c-tooltip-arrow"
            ref={arrowProps?.ref}
            style={arrowProps?.style}
          ></div>
        ) : null}
      </div>
    );
  }
);

TooltipElement.displayName = 'TooltipElement';

export const Tooltip: React.FC<ITooltipProps> = ({
  children,
  isDescriptive,
  ...props
}: ITooltipProps) => {
  return (
    <Overlay {...props}>
      <TooltipElement isDescriptive={isDescriptive}>{children}</TooltipElement>
    </Overlay>
  );
};

const InnerUncontrolledTooltip = (
  { children, content, className, isDescriptive, isTruncated, ...props }: IUncontrolledTooltipProps,
  ref: React.Ref<HTMLDivElement>
) => {
  const tooltip = <TooltipElement isDescriptive={isDescriptive}>{content}</TooltipElement>;
  return (
    <OverlayTrigger overlay={tooltip} {...props}>
      <div
        ref={ref}
        className={classNames(
          'sui-cursor-pointer',
          {
            'sui-inline-block': !isTruncated,
            'sui-text-ellipsis sui-truncate': isTruncated,
          },
          className
        )}
      >
        {children}
      </div>
    </OverlayTrigger>
  );
};

export const UncontrolledTooltip = React.forwardRef(InnerUncontrolledTooltip);

import * as React from 'react';
import classNames from 'classnames';

import { IconCheck, IconExclamation, IconTimes } from '../Icon';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const TelusCustomOption = ({
  isMulti,
  isSelected,
  isFocused,
  isDisabled,
  ...props
}: any) => {
  const customClasses = classNames(
    { 'sui-cursor-pointer': !isDisabled },
    props.cx({
      option: true,
      'option--is-disabled': isDisabled,
      'option--is-focused': !isDisabled && isFocused,
      'option--is-selected': isSelected,
    }),
    'sui-p-1.5 sui-flex sui-items-center'
  );
  return (
    <div className={customClasses} {...props.innerProps}>
      <span className="sui-w-8 sui-flex sui-justify-center">
        <span
          className={classNames(
            'sui-w-4 sui-h-4 sui-flex sui-justify-center sui-rounded-sm sui-items-center',
            isSelected && isMulti
              ? 'sui-bg-primary sui-text-white sui-border-primary'
              : 'sui-border-button-border sui-text-transparent',
            {
              'sui-hidden': !isSelected && !isMulti,
              'sui-border-lightGray-darker': isDisabled,
              'sui-text-primary': isSelected && !isMulti,
              'sui-border': isMulti,
            }
          )}
        >
          <IconCheck width={isMulti ? 10 : 16} height={isMulti ? 8 : 12} />
        </span>
      </span>
      {props.label}
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const TelusIndicatorsContainer = (props: any) => {
  const classList = props.selectProps.className;
  const hasIcon = classList.includes('has-icon');
  let iconType = null;
  let iconColor = '';

  if (classList.includes('sui-c-input-dropdown-warning')) {
    iconType = <IconExclamation variant="circle" />;
    iconColor = 'sui-text-warning';
  }
  if (classList.includes('sui-c-input-dropdown-success')) {
    iconType = <IconCheck variant="circle" />;
    iconColor = 'sui-text-success';
  }
  if (classList.includes('sui-c-input-dropdown-danger')) {
    iconColor = 'sui-text-danger';
    iconType = <IconExclamation variant="triangle" />;
  }
  return (
    <div
      className={classNames(props.className, 'sui-flex sui-items-center sui-self-stretch')}
      {...props.innerProps}
    >
      <>
        {hasIcon ? <div className={classNames('sui-px-2', iconColor)}>{iconType}</div> : null}
        {React.Children.map(props.children, child => (child ? child : null))}
      </>
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const TelusMultiValueRemove = (props: any) => (
  <div
    title={`Remove ${props.data.label}`}
    {...props.innerProps}
    className="sui-w-6 sui-h-6 sui-flex sui-justify-center sui-items-center sui-rounded-3xl sui-cursor-pointer hover:sui-bg-input-clear"
  >
    <IconTimes height={11} width={11} />
  </div>
);

import { useEffect } from 'react';

export const useClickOutsideEvent = (
  innerRef: React.RefObject<HTMLElement>,
  onClickOutside: (event: Event) => void,
  event: keyof DocumentEventMap = 'mousedown'
): void => {
  useEffect(() => {
    const handleClickOutside = (event: Event): void => {
      if (innerRef.current && !innerRef.current.contains(event.target as Node)) {
        onClickOutside(event);
      }
    };
    document.addEventListener(event, handleClickOutside);
    return (): void => {
      document.removeEventListener(event, handleClickOutside);
    };
  }, [innerRef, onClickOutside, event]);
};

import React from 'react';
import { Route } from 'react-router-dom';

import { TabIconProps } from './TabTitle';

export interface ITabProps {
  children?: React.ReactNode;
  className?: string;
  title: string;
  disabled?: boolean;
  to?: string;
  exact?: boolean;
  innerClass?: string;
  isSettings?: boolean;
  icon?: TabIconProps;
  isHidden?: boolean;
}

export const Tab: React.FC<ITabProps> = ({
  children,
  to,
  exact,
  innerClass = 'sui-container sui-py-8',
}: ITabProps) => {
  if (!children) {
    return to ? <Route exact={exact} path={to} /> : <></>;
  }

  return to ? (
    <Route exact={exact} path={to}>
      <div className={innerClass}>{children}</div>
    </Route>
  ) : (
    <div className={innerClass}>{children}</div>
  );
};

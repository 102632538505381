import React from 'react';
import classNames from 'classnames';

import { IconTypes } from '../../../types';

type IconAngleProps = {
  variant?: 'up' | 'down' | 'left' | 'right';
  isLight?: boolean;
};

export const IconAngle = ({
  className,
  width,
  height,
  variant = 'right',
  isLight,
  ariaHidden = true,
  isClickable = false,
}: IconTypes & IconAngleProps): React.ReactElement => {
  const defaultClass = classNames(
    'sui-fill-current',
    { 'sui-pointer-events-none': !isClickable },
    className
  );
  let viewBox = '0 0 12 16';
  let svgWidth = width ? width : 12;
  let svgHeight = height ? height : 16;
  let iconVariant = (
    // Right
    <path d="M10.811 8.85l-6.798 6.798c-.47.47-1.23.47-1.694 0l-1.13-1.13c-.47-.47-.47-1.23 0-1.695l4.819-4.818-4.819-4.819c-.47-.47-.47-1.23 0-1.694L2.314.352c.47-.47 1.23-.47 1.694 0l6.798 6.798c.475.47.475 1.23.005 1.7z" />
  );

  if (variant === 'right' && isLight) {
    viewBox = '0 0 10 16';
    svgWidth = width ? width : 10;
    svgHeight = height ? height : 16;
    iconVariant = (
      <path d="M9.43311 8.53115L2.07205 15.7797C1.77836 16.0734 1.30345 16.0734 1.00976 15.7797L0.566095 15.3361C0.272402 15.0424 0.272402 14.5675 0.566095 14.2738L6.9586 8L0.572343 1.72623C0.278651 1.43253 0.278651 0.957626 0.572343 0.663933L1.01601 0.220269C1.3097 -0.0734232 1.78461 -0.0734232 2.0783 0.220269L9.43936 7.46885C9.7268 7.76255 9.7268 8.23745 9.43311 8.53115Z" />
    );
  }

  if (variant === 'down') {
    viewBox = '0 0 16 12';
    svgWidth = width ? width : 16;
    svgHeight = height ? height : 12;
    iconVariant = (
      <path d="M7.155 10.814L.353 4.012c-.47-.47-.47-1.23 0-1.696l1.13-1.13c.47-.47 1.23-.47 1.695 0L8 6.008l4.822-4.822c.47-.47 1.23-.47 1.695 0l1.13 1.13c.47.47.47 1.23 0 1.696l-6.802 6.802c-.46.47-1.22.47-1.69 0z" />
    );
  }

  if (variant === 'down' && isLight) {
    viewBox = '0 0 16 10';
    svgWidth = width ? width : 16;
    svgHeight = height ? height : 10;
    iconVariant = (
      <path d="M7.46885 9.43311L0.220269 2.07205C-0.0734232 1.77836 -0.0734232 1.30345 0.220269 1.00976L0.663933 0.566095C0.957626 0.272402 1.43253 0.272402 1.72623 0.566095L8 6.9586L14.2738 0.572344C14.5675 0.278651 15.0424 0.278651 15.3361 0.572344L15.7797 1.01601C16.0734 1.3097 16.0734 1.78461 15.7797 2.0783L8.53115 9.43936C8.23745 9.72681 7.76255 9.7268 7.46885 9.43311Z" />
    );
  }

  if (variant === 'left') {
    viewBox = '0 0 12 16';
    svgWidth = width ? width : 12;
    svgHeight = height ? height : 16;
    iconVariant = (
      <path d="M1.186 7.15L7.984.352c.47-.47 1.23-.47 1.695 0l1.13 1.13c.469.47.469 1.23 0 1.695L5.994 8l4.818 4.819c.47.47.47 1.23 0 1.694l-1.13 1.135c-.47.47-1.23.47-1.694 0L1.191 8.85a1.196 1.196 0 01-.005-1.7z" />
    );
  }

  if (variant === 'left' && isLight) {
    viewBox = '0 0 10 16';
    svgWidth = width ? width : 10;
    svgHeight = height ? height : 16;
    iconVariant = (
      <path d="M0.566882 7.46885L7.92794 0.220269C8.22164 -0.0734232 8.69654 -0.0734232 8.99024 0.220269L9.4339 0.663933C9.72759 0.957626 9.72759 1.43253 9.4339 1.72623L3.0414 8L9.42765 14.2738C9.72134 14.5675 9.72134 15.0424 9.42765 15.3361L8.98399 15.7797C8.6903 16.0734 8.21539 16.0734 7.9217 15.7797L0.560633 8.53115C0.273189 8.23745 0.273189 7.76255 0.566882 7.46885Z" />
    );
  }

  if (variant === 'up') {
    viewBox = '0 0 16 12';
    svgWidth = width ? width : 16;
    svgHeight = height ? height : 12;
    iconVariant = (
      <path d="M8.84973 1.18624L15.6476 7.98412C16.1175 8.45397 16.1175 9.21373 15.6476 9.67859L14.518 10.8082C14.0481 11.2781 13.2883 11.2781 12.8235 10.8082L8 5.99474L3.18151 10.8132C2.71165 11.2831 1.95189 11.2831 1.48704 10.8132L0.35239 9.68359C-0.117463 9.21373 -0.117463 8.45397 0.35239 7.98911L7.15027 1.19124C7.62012 0.716387 8.37988 0.716387 8.84973 1.18624Z" />
    );
  }

  if (variant === 'up' && isLight) {
    viewBox = '0 0 16 10';
    svgWidth = width ? width : 16;
    svgHeight = height ? height : 10;
    iconVariant = (
      <path d="M8.53115 0.566882L15.7797 7.92794C16.0734 8.22164 16.0734 8.69654 15.7797 8.99024L15.3361 9.4339C15.0424 9.72759 14.5675 9.72759 14.2738 9.4339L8 3.0414L1.72623 9.42765C1.43253 9.72134 0.957626 9.72134 0.663933 9.42765L0.220269 8.98399C-0.0734232 8.69029 -0.0734232 8.21539 0.220269 7.92169L7.46885 0.560633C7.76255 0.273189 8.23745 0.27319 8.53115 0.566882Z" />
    );
  }
  return (
    <svg
      width={svgWidth}
      height={svgHeight}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={defaultClass}
      aria-hidden={ariaHidden}
    >
      {iconVariant}
    </svg>
  );
};

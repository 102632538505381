import React from 'react';
import classNames from 'classnames';

import { IconTypes } from '../../../types';

type IIconFillDripProps = {
  isLight?: boolean;
};

export const IconFillDrip = ({
  className,
  width = 24,
  height = 24,
  isLight,
  ariaHidden = true,
  isClickable = false,
}: IconTypes & IIconFillDripProps): React.ReactElement => {
  const defaultClass = classNames(
    'sui-fill-current',
    { 'sui-pointer-events-none': !isClickable },
    className
  );

  const iconVariant = isLight ? (
    <path d="M18.2222 13.7776C18.2222 13.7776 16.4444 16.3512 16.4444 17.3332C16.4444 18.3151 17.2405 19.111 18.2222 19.111C19.2039 19.111 20 18.3151 20 17.3332C20 16.3512 18.2222 13.7776 18.2222 13.7776ZM18.2222 18.2221C17.7322 18.2221 17.3333 17.8232 17.3333 17.3343C17.3405 17.0626 17.7103 16.2879 18.2225 15.4251C18.7344 16.2873 19.1039 17.0615 19.1111 17.3332C19.1111 17.8232 18.7122 18.2221 18.2222 18.2221ZM17.9619 10.9181L12.1927 5.14895C12.0191 4.97534 11.7916 4.88867 11.5641 4.88867C11.3366 4.88867 11.1091 4.97534 10.9358 5.14895L8.35662 7.72813L5.58244 4.95395C5.49577 4.86728 5.35494 4.86728 5.26827 4.95395L4.95382 5.26812C4.86716 5.35478 4.86716 5.49562 4.95382 5.58229L7.72828 8.35674L4.78105 11.304C3.73965 12.3454 3.73965 14.0334 4.78105 15.0748L8.03634 18.3301C8.55717 18.8507 9.2394 19.1112 9.92162 19.1112C10.6041 19.1112 11.2864 18.851 11.8069 18.3301L17.9617 12.1754C18.3089 11.8281 18.3089 11.2654 17.9619 10.9181ZM11.1786 17.7015C10.8427 18.0371 10.3963 18.2221 9.92162 18.2221C9.4469 18.2221 9.00051 18.0371 8.66495 17.7015L5.40938 14.4459C5.21827 14.2548 5.08244 14.0254 4.99521 13.7776H15.1022L11.1786 17.7015ZM15.9911 12.8887H4.91827C4.97938 12.5287 5.14605 12.1959 5.40966 11.9323L8.35662 8.9848L10.7969 11.4251C10.9705 11.5987 11.2519 11.5987 11.4252 11.4251C11.5989 11.2515 11.5989 10.9701 11.4252 10.7968L8.98495 8.35646L11.5636 5.77756H11.5641L17.3333 11.5465L15.9911 12.8887Z" />
  ) : (
    <path d="M18.2222 13.7778C18.2222 13.7778 16.4444 16.3515 16.4444 17.3334C16.4444 18.3154 17.2405 19.1112 18.2222 19.1112C19.2039 19.1112 20 18.3154 20 17.3334C20 16.3515 18.2222 13.7778 18.2222 13.7778ZM17.9619 10.9184L12.1927 5.14919C12.0191 4.97558 11.7916 4.88892 11.5641 4.88892C11.3366 4.88892 11.1091 4.97558 10.9358 5.14919L8.66967 7.41532L6.27577 5.02114C6.10216 4.84753 5.82077 4.84753 5.64744 5.02114L5.0191 5.64947C4.84577 5.82309 4.84577 6.10448 5.0191 6.27781L7.41328 8.67171L4.78105 11.3039C3.73965 12.3451 3.73965 14.0334 4.78105 15.0748L8.03634 18.3301C8.55689 18.8506 9.2394 19.1112 9.92162 19.1112C10.6041 19.1112 11.2864 18.8509 11.8069 18.3301L17.9617 12.1753C18.3089 11.8281 18.3089 11.2656 17.9619 10.9184ZM14.7336 12.8898H5.83133C5.86911 12.7831 5.93049 12.6681 6.03772 12.5612L6.403 12.1959L8.66995 9.92894L10.2977 11.5567C10.6447 11.9037 11.2075 11.9037 11.5544 11.5567C11.9014 11.2098 11.9014 10.647 11.5544 10.3001L9.92662 8.67227L11.5641 7.03476L16.0764 11.547L14.7336 12.8898Z" />
  );
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={defaultClass}
      aria-hidden={ariaHidden}
    >
      {iconVariant}
    </svg>
  );
};

import React from 'react';
import classNames from 'classnames';

import { ITextLinkProps, TextLink } from '../TextLink';

export type DropdownItemProps = {
  icon?: React.ReactElement;
  label?: string;
  isLink?: boolean;
  children?: React.ReactNode;
  isButton?: boolean;
  isHeader?: boolean;
  isNewTab?: boolean;
};

export const DropdownItem = ({
  icon,
  label,
  isLink,
  children,
  isButton,
  isHeader,
  isNewTab,
  disabled,
  className,
  ...props
}: DropdownItemProps & ITextLinkProps): React.ReactElement => {
  const defaultClass = classNames('sui-text-base sui-flex sui-items-center sui-py-1.5', {
    'sui-cursor-pointer sui-block hover:sui-bg-lightGray-lightest active:sui-bg-primary-lightest ':
      ((icon && isLink) || isLink || isButton) && !disabled,
    'sui-pl-6 sui-pr-4.5': isButton,
    'sui-pl-5 sui-pr-base': isHeader || isLink,
  });
  const textLinkClass = classNames({ 'sui-u-inherit-text-color': !disabled }, className);
  const linkIcon = { icon: icon, isLeft: true };
  const hasContent = isLink || icon || label;
  return (
    <>
      {hasContent && (
        <div className={defaultClass}>
          {isLink ? (
            <TextLink
              {...props}
              disabled={disabled}
              className={textLinkClass}
              hasIcon={icon ? linkIcon : undefined}
              target={isNewTab ? '_blank' : '_self'}
            >
              {label}
            </TextLink>
          ) : icon ? (
            <div className="sui-flex sui-items-center sui-px-6 sui-py-1.5">
              {icon}
              <span className="sui-ml-sm sui-text-b4 sui-font-medium sui-u-inherit-text-color sui-uppercase">
                {label}
              </span>
            </div>
          ) : (
            <span
              className={classNames({
                'sui-text-b4 sui-font-medium sui-u-inherit-text-color sui-uppercase': isHeader,
              })}
            >
              {label}
            </span>
          )}
        </div>
      )}
      {children && children}
    </>
  );
};

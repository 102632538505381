import React from 'react';
import classNames from 'classnames';

import { DateRange } from '../../types';
import { isBoundedRange, isDateInRange } from '../../lib/DateUtils';

import { Day } from './Day';
import { CalendarSide } from './DatePicker';

export type WeekDates = Readonly<[Date, Date, Date, Date, Date, Date, Date]>;

export interface IWeekProps extends React.HTMLAttributes<HTMLAllCollection> {
  daysInWeek: WeekDates;
  displayedMonth: Date;
  selectedRange: DateRange;
  disabledRanges: ReadonlyArray<DateRange>;
  side?: CalendarSide;
  onSelectDate: (date: Date) => void;
}

export const Week: React.FC<IWeekProps> = ({
  daysInWeek,
  displayedMonth,
  selectedRange,
  disabledRanges,
  side,
  onSelectDate,
}: IWeekProps) => {
  const firstDayOfWeek = daysInWeek[0];
  const lastDayOfWeek = daysInWeek[daysInWeek.length - 1];
  const firstDayOfWeekIsAnotherMonth = firstDayOfWeek.getMonth() !== displayedMonth.getMonth();
  const lastDayOfWeekIsAnotherMonth = lastDayOfWeek.getMonth() !== displayedMonth.getMonth();

  let leftMarginIsSelected = false;
  let rightMarginIsSelected = false;
  if (isBoundedRange(selectedRange)) {
    leftMarginIsSelected = isDateInRange(firstDayOfWeek, selectedRange, false, true);
    rightMarginIsSelected = isDateInRange(lastDayOfWeek, selectedRange, true, false);
  }
  const leftMarginIsDisabled = disabledRanges.some(range =>
    isDateInRange(firstDayOfWeek, range, false, true)
  );
  const rightMarginIsDisabled = disabledRanges.some(range =>
    isDateInRange(lastDayOfWeek, range, true, false)
  );

  const styleClasses = {
    mainContainer: 'sui-flex',
    daysContainer: 'sui-flex',
    leftMargin: classNames(
      'sui-w-4',
      { 'sui-bg-primary-lighter': leftMarginIsSelected && !leftMarginIsDisabled },
      {
        'sui-bg-primary-lightest':
          leftMarginIsSelected && (firstDayOfWeekIsAnotherMonth || leftMarginIsDisabled),
      }
    ),
    rightMargin: classNames(
      'sui-w-4',
      { 'sui-bg-primary-lighter': rightMarginIsSelected && !rightMarginIsDisabled },
      {
        'sui-bg-primary-lightest':
          rightMarginIsSelected && (lastDayOfWeekIsAnotherMonth || rightMarginIsDisabled),
      }
    ),
  };

  return (
    <div className={styleClasses.mainContainer}>
      <div className={styleClasses.leftMargin} />
      <div className={styleClasses.daysContainer}>
        {daysInWeek.map((day, dayIndex) => (
          <Day
            key={dayIndex}
            date={day}
            displayedMonth={displayedMonth}
            selectedRange={selectedRange}
            disabledRanges={disabledRanges}
            side={side}
            onSelectDate={onSelectDate}
          />
        ))}
      </div>
      <div className={styleClasses.rightMargin} />
    </div>
  );
};

export const colorPalette: ReadonlyArray<string> = [
  '#2C2E30',
  '#545A5E',
  '#C6CBCF',
  '#FFFFFF',
  '#2B8000',
  '#66CC00',
  '#BFE797',
  '#F4F9F2',
  '#613889',
  '#7C53A5',
  '#D8CBE5',
  '#F2EFF4',
  '#0056B0',
  '#1C7CD6',
  '#42A2FC',
  '#ECF6FF',
  '#8C5415',
  '#EE8822',
  '#FACA69',
  '#FFF9EE',
  '#C12335',
  '#E12339',
  '#FF6464',
  '#FFF6F8',
];

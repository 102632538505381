import React from 'react';
import classNames from 'classnames';

import { IconTypes } from '../../../types';

export const IconExternalLink = ({
  className,
  width = 16,
  height = 16,
  ariaHidden = true,
  isClickable = false,
}: IconTypes): React.ReactElement => {
  const defaultClass = classNames(
    'sui-fill-current',
    { 'sui-pointer-events-none': !isClickable },
    className
  );
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={defaultClass}
      aria-hidden={ariaHidden}
    >
      <path d="M13.75 8h-.5a.25.25 0 00-.25.25v6.25a.5.5 0 01-.5.5h-11a.5.5 0 01-.5-.5v-11a.5.5 0 01.5-.5h6.25A.25.25 0 008 2.75v-.5A.25.25 0 007.75 2H1.5A1.5 1.5 0 000 3.5v11A1.5 1.5 0 001.5 16h11a1.5 1.5 0 001.5-1.5V8.25a.25.25 0 00-.25-.25zm1.875-8l-4.25.01A.375.375 0 0011 .387v.312a.375.375 0 00.375.375l2.813-.01.021.022-10.1 10.098a.377.377 0 000 .531l.178.177a.376.376 0 00.53 0l10.098-10.1.023.023-.011 2.812a.375.375 0 00.375.375h.312a.375.375 0 00.375-.375L16 .375A.375.375 0 0015.625 0z" />
    </svg>
  );
};

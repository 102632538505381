import React from 'react';
import classNames from 'classnames';

import { IconAngle } from '../Icon';
import { ButtonSize } from '../../types';

export interface IAccordionProps extends React.HTMLAttributes<HTMLDivElement> {
  title: string;
  index?: number;
  active?: number;
  setSelectedAccordion?: (index: number) => void;
  size?: ButtonSize;
  disabled?: boolean;
  content?: React.ReactNode;
  contentClass?: string;
}

export const Accordion: React.FC<IAccordionProps> = ({
  title,
  index,
  active,
  className,
  size = ButtonSize.LARGE,
  disabled,
  setSelectedAccordion = () => {
    -1;
  },
  content,
  contentClass,
  children,
}: IAccordionProps) => {
  const styleClasses = {
    defaultClass: classNames(
      'sui-flex sui-items-center sui-justify-between sui-w-full focus:sui-outline-none',
      className,
      {
        'sui-c-accordion-disabled': disabled,
        'sui-c-accordion-active': active === index,
        'sui-h-9 sui-px-2': size === ButtonSize.SMALL,
        'sui-h-14 sui-px-3 sui-py-4': size === ButtonSize.MEDIUM,
        'sui-h-15 sui-px-3 sui-py-4': size === ButtonSize.LARGE,
      }
    ),
    titleClass: classNames('sui-font-medium', {
      'sui-c-accordion-disabled': disabled,
      'sui-text-b4': size === ButtonSize.SMALL,
      'sui-text-b3': size === ButtonSize.MEDIUM,
      'sui-text-b2': size === ButtonSize.LARGE,
    }),
  };

  const toggle = (index: number) => {
    if (active === index) {
      return setSelectedAccordion(-1);
    }
    setSelectedAccordion(index);
  };
  return (
    <div className="sui-border-b sui-border-lightGray-darker" key={index}>
      <button
        className={styleClasses.defaultClass}
        type="button"
        onClick={() => toggle(index ?? 0)}
        disabled={disabled}
        aria-disabled={disabled}
      >
        <div className="sui-flex sui-items-baseline ">
          <h3 className={styleClasses.titleClass}>{title}</h3>
        </div>
        <div className="sui-flex sui-flex-end">
          {active === index ? (
            <IconAngle variant="up" isLight />
          ) : (
            <IconAngle variant="down" isLight />
          )}
        </div>
      </button>
      {active === index && <div className={contentClass}>{content ?? children}</div>}
    </div>
  );
};

import React from 'react';
import classNames from 'classnames';

import { IconTypes } from '../../../types';

export const IconRectangle = ({
  className,
  width = 24,
  height = 24,
  ariaHidden = true,
  isClickable = false,
}: IconTypes): React.ReactElement => {
  const defaultClass = classNames(
    'sui-fill-current',
    { 'sui-pointer-events-none': !isClickable },
    className
  );

  const iconVariant = (
    <path d="M18 8C19.1031 8 20 8.89687 20 10V18C20 19.1031 19.1031 20 18 20H6C4.89531 20 4 19.1031 4 18V10C4 8.89687 4.89531 8 6 8H18ZM18 9H6C5.44781 9 5 9.44688 5 10V18C5 18.5531 5.44781 19 6 19H18C18.5531 19 19 18.5531 19 18V10C19 9.44688 18.5531 9 18 9ZM18 6C18.275 6 18.5 6.22375 18.5 6.5C18.5 6.77625 18.275 7 18 7H6C5.72375 7 5.5 6.77625 5.5 6.5C5.5 6.22375 5.72375 6 6 6H18ZM16.5 4C16.775 4 17 4.22387 17 4.5C17 4.77625 16.775 5 16.5 5H7.5C7.225 5 7 4.77625 7 4.5C7 4.22387 7.225 4 7.5 4H16.5Z" />
  );
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={defaultClass}
      aria-hidden={ariaHidden}
    >
      {iconVariant}
    </svg>
  );
};

import React from 'react';
import classNames from 'classnames';

import { IconTypes } from '../../../types';

type IconMinusProps = {
  variant?: 'default' | 'circle' | 'square';
};

export const IconMinus = ({
  className,
  width,
  height,
  variant = 'default',
  ariaHidden = true,
  isClickable = false,
}: IconTypes & IconMinusProps): React.ReactElement => {
  const defaultClass = classNames(
    'sui-fill-current',
    { 'sui-pointer-events-none': !isClickable },
    className
  );
  let viewBox = '0 0 16 4';
  let svgWidth = width ? width : 16;
  let svgHeight = height ? height : 4;
  let iconVariant = (
    // Default
    <path d="M14.8571 0.285767H1.14286C0.511786 0.285767 0 0.797552 0 1.42862V2.57148C0 3.20255 0.511786 3.71434 1.14286 3.71434H14.8571C15.4882 3.71434 16 3.20255 16 2.57148V1.42862C16 0.797552 15.4882 0.285767 14.8571 0.285767Z" />
  );

  if (variant === 'circle') {
    viewBox = '0 0 16 16';
    svgWidth = width ? width : 16;
    svgHeight = height ? height : 16;
    iconVariant = (
      <path d="M8 0C3.58065 0 0 3.58065 0 8C0 12.4194 3.58065 16 8 16C12.4194 16 16 12.4194 16 8C16 3.58065 12.4194 0 8 0ZM3.74194 9.29032C3.52903 9.29032 3.35484 9.11613 3.35484 8.90323V7.09677C3.35484 6.88387 3.52903 6.70968 3.74194 6.70968H12.2581C12.471 6.70968 12.6452 6.88387 12.6452 7.09677V8.90323C12.6452 9.11613 12.471 9.29032 12.2581 9.29032H3.74194Z" />
    );
  }

  if (variant === 'square') {
    viewBox = '0 0 16 16';
    svgWidth = width ? width : 16;
    svgHeight = height ? height : 16;
    iconVariant = (
      <path d="M14.2857 0H1.71429C0.767857 0 0 0.767857 0 1.71429V14.2857C0 15.2321 0.767857 16 1.71429 16H14.2857C15.2321 16 16 15.2321 16 14.2857V1.71429C16 0.767857 15.2321 0 14.2857 0ZM3.28571 9.42857C3.05 9.42857 2.85714 9.23571 2.85714 9V7C2.85714 6.76429 3.05 6.57143 3.28571 6.57143H12.7143C12.95 6.57143 13.1429 6.76429 13.1429 7V9C13.1429 9.23571 12.95 9.42857 12.7143 9.42857H3.28571Z" />
    );
  }
  return (
    <svg
      width={svgWidth}
      height={svgHeight}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={defaultClass}
      aria-hidden={ariaHidden}
    >
      {iconVariant}
    </svg>
  );
};

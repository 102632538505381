import { Placement } from '@popperjs/core';
import classNames from 'classnames';
import React, {
  CSSProperties,
  Dispatch,
  ElementType,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';
import { createPortal } from 'react-dom';
import { usePopper } from 'react-popper';
import './popper-utility.css';

export interface IPopperUtilityProps extends React.HTMLAttributes<HTMLAllCollection> {
  children: React.ReactNode;
  shown?: boolean;
}

type PopperSubComponents = {
  Target: typeof Target;
  Popup: typeof Popup;
  Content: typeof Content;
  Arrow: typeof Arrow;
};

interface ITargetProps extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
  isMinWidth?: boolean;
}

interface IPopupProps extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
  placement?: Placement;
  offset?: Offset;
}

interface IContentProps extends React.HTMLAttributes<ElementType> {
  children: React.ReactNode;
}

interface IArrowProps extends React.HTMLAttributes<HTMLDivElement> {
  size?: ArrowSize;
}

type Styles = {
  [key: string]: CSSProperties;
};
type Attributes = {
  [key: string]: { [key: string]: string } | undefined;
};
type Offset = {
  mainAxis?: number;
  crossAxis?: number;
};

type ArrowSize = 'sm' | 'md' | 'lg';

type PopperUtilityContextType = {
  setReferenceElement: Dispatch<SetStateAction<HTMLElement | null>>;
  setPopperElement: Dispatch<SetStateAction<HTMLDivElement | null>>;
  setArrowElement: Dispatch<SetStateAction<HTMLDivElement | null>>;
  arrowElement: HTMLDivElement | null;
  styles: Styles;
  attributes: Attributes;
  setPlacement: Dispatch<SetStateAction<Placement>>;
  setOffset: Dispatch<SetStateAction<Offset>>;
  setTargetIsMinWidth: Dispatch<SetStateAction<boolean>>;
  minWidth: number;
  isShown: boolean;
};

const PopperUtilityContext = createContext<PopperUtilityContextType>(
  {} as PopperUtilityContextType
);

const Target: React.FC<ITargetProps> = ({ children, isMinWidth, ...props }: ITargetProps) => {
  const { setReferenceElement, setTargetIsMinWidth } = React.useContext(PopperUtilityContext);

  useEffect(() => {
    if (isMinWidth) {
      setTargetIsMinWidth(true);
    }
  }, [isMinWidth, setTargetIsMinWidth]);

  return (
    <div ref={setReferenceElement} style={{ width: 'fit-content' }} {...props}>
      {children}
    </div>
  );
};

const Popup: React.FC<IPopupProps> = ({
  children,
  className,
  placement = 'auto',
  offset = { mainAxis: 0, crossAxis: 0 },
}: IPopupProps) => {
  const {
    setPopperElement,
    setPlacement,
    setOffset,
    styles,
    attributes,
    minWidth,
    isShown,
  } = useContext(PopperUtilityContext);

  useEffect(() => {
    setPlacement(placement);
  }, [placement, setPlacement]);

  const { mainAxis, crossAxis } = offset;
  useEffect(() => {
    setOffset({ mainAxis, crossAxis });
  }, [mainAxis, crossAxis, setOffset]);

  const popupStyles = { ...styles.popper, minWidth };

  const popup = (
    <div
      className={classNames(className, 'sui-z-50')}
      id="popper"
      ref={setPopperElement}
      style={popupStyles}
      {...attributes.popper}
    >
      {children}
    </div>
  );

  return isShown ? createPortal(popup, document.body) : null;
};

const Content: React.FC<IContentProps> = ({ children, className }: IContentProps) => {
  return (
    <div className="content-wrapper">
      <div className={classNames('content', className)}>{children}</div>
    </div>
  );
};

const Arrow: React.FC<IArrowProps> = ({ className, size = 'md' }: IArrowProps) => {
  const { setArrowElement, styles, attributes } = useContext(PopperUtilityContext);

  const arrowSize: Record<ArrowSize, string> = {
    sm: 'sui-w-1.5 sui-h-1.5',
    md: 'sui-w-2 sui-h-2',
    lg: 'sui-w-3 sui-h-3',
  };

  const placement = attributes.popper?.['data-popper-placement'] || '';

  let arrowPositionStyle: CSSProperties = {};
  switch (true) {
    case placement.startsWith('top'):
      arrowPositionStyle = { translate: '0 50%', bottom: 0 };
      break;
    case placement.startsWith('right'):
      arrowPositionStyle = { translate: '-50% 0', left: 0 };
      break;
    case placement.startsWith('bottom'):
      arrowPositionStyle = { translate: '0 -50%', top: 0 };
      break;
    case placement.startsWith('left'):
      arrowPositionStyle = { translate: '50% 0', right: 0 };
      break;
  }

  const arrowStyles = { ...styles.arrow, ...arrowPositionStyle };

  const arrowClass = classNames(arrowSize[size], arrowPositionStyle, className);

  return <div ref={setArrowElement} id="arrow" className={arrowClass} style={arrowStyles} />;
};

export const PopperUtility: React.FC<IPopperUtilityProps> & PopperSubComponents = ({
  children,
  shown = false,
}: IPopperUtilityProps) => {
  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
  const [arrowElement, setArrowElement] = useState<HTMLDivElement | null>(null);
  const [placement, setPlacement] = useState<Placement>('auto');
  const [offset, setOffset] = useState<Offset>({});
  const [targetIsMinWidth, setTargetIsMinWidth] = useState(false);
  const [minWidth, setMinWidth] = useState<number>(0);
  const [isShown, setIsShown] = useState(shown);

  const arrowModifiers = { name: 'arrow', options: { element: arrowElement } };
  const offsetModifiers = {
    name: 'offset',
    options: { offset: [offset.crossAxis, offset.mainAxis] },
  };
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement,
    modifiers: [offsetModifiers, arrowModifiers],
  });

  useEffect(() => {
    if (!referenceElement || !targetIsMinWidth) {
      return;
    }
    setMinWidth(referenceElement.offsetWidth);
  }, [referenceElement, targetIsMinWidth]);

  useEffect(() => {
    setIsShown(shown);
  }, [shown]);

  return (
    <PopperUtilityContext.Provider
      value={{
        setReferenceElement,
        setPopperElement,
        arrowElement,
        setArrowElement,
        styles,
        attributes,
        setPlacement,
        setOffset,
        setTargetIsMinWidth,
        minWidth,
        isShown,
      }}
    >
      {children}
    </PopperUtilityContext.Provider>
  );
};

PopperUtility.Target = Target;
PopperUtility.Popup = Popup;
PopperUtility.Content = Content;
PopperUtility.Arrow = Arrow;

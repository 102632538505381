import React from 'react';
import classNames from 'classnames';

import { IconTypes } from '../../../types';

type IconClipboardListCheckProps = {
  isLight?: boolean;
};

export const IconClipboardListCheck = ({
  className,
  width = 24,
  height = 24,
  isLight,
  ariaHidden = true,
  isClickable = false,
}: IconTypes & IconClipboardListCheckProps): React.ReactElement => {
  const defaultClass = classNames(
    'sui-fill-current',
    { 'sui-pointer-events-none': !isClickable },
    className
  );

  const iconVariant = isLight ? (
    <path d="M16.5 6H13.7312C13.7437 5.91875 13.75 5.83438 13.75 5.75C13.75 4.78438 12.9656 4 12 4C11.0344 4 10.25 4.78438 10.25 5.75C10.25 5.83438 10.2563 5.91875 10.2688 6H7.5C6.67188 6 6 6.67188 6 7.5V18.5C6 19.3281 6.67188 20 7.5 20H16.5C17.3281 20 18 19.3281 18 18.5V7.5C18 6.67188 17.3281 6 16.5 6ZM12 5C12.4156 5 12.75 5.33437 12.75 5.75C12.75 6.16563 12.4156 6.5 12 6.5C11.5844 6.5 11.25 6.16563 11.25 5.75C11.25 5.33437 11.5844 5 12 5ZM17 18.5C17 18.775 16.775 19 16.5 19H7.5C7.225 19 7 18.775 7 18.5V7.5C7 7.225 7.225 7 7.5 7H9V7.625C9 7.83125 9.16875 8 9.375 8H14.625C14.8312 8 15 7.83125 15 7.625V7H16.5C16.775 7 17 7.225 17 7.5V18.5ZM9.5 14.25C9.08437 14.25 8.75 14.5844 8.75 15C8.75 15.4156 9.08437 15.75 9.5 15.75C9.91563 15.75 10.25 15.4156 10.25 15C10.25 14.5844 9.91563 14.25 9.5 14.25ZM14.75 14.5H11.25C11.1125 14.5 11 14.6125 11 14.75V15.25C11 15.3875 11.1125 15.5 11.25 15.5H14.75C14.8875 15.5 15 15.3875 15 15.25V14.75C15 14.6125 14.8875 14.5 14.75 14.5ZM9.94375 12.45L11.95 10.4625C12.0156 10.3969 12.0156 10.2906 11.95 10.225L11.5563 9.82812C11.4906 9.7625 11.3844 9.7625 11.3187 9.82812L9.83125 11.3031L9.1875 10.65C9.12188 10.5844 9.01563 10.5844 8.95 10.65L8.55312 11.0437C8.4875 11.1094 8.4875 11.2156 8.55312 11.2812L9.7125 12.45C9.77188 12.5156 9.87812 12.5156 9.94375 12.45V12.45ZM14.75 11.5H12.325L11.3156 12.5H14.75C14.8875 12.5 15 12.3875 15 12.25V11.75C15 11.6125 14.8875 11.5 14.75 11.5Z" />
  ) : (
    <path d="M16.5 6H14C14 4.89688 13.1031 4 12 4C10.8969 4 10 4.89688 10 6H7.5C6.67188 6 6 6.67188 6 7.5V18.5C6 19.3281 6.67188 20 7.5 20H16.5C17.3281 20 18 19.3281 18 18.5V7.5C18 6.67188 17.3281 6 16.5 6ZM12 5.25C12.4156 5.25 12.75 5.58438 12.75 6C12.75 6.41563 12.4156 6.75 12 6.75C11.5844 6.75 11.25 6.41563 11.25 6C11.25 5.58438 11.5844 5.25 12 5.25ZM8.05 10.5437L8.44688 10.15C8.5125 10.0844 8.61875 10.0844 8.68438 10.15L9.32812 10.8L10.8156 9.325C10.8813 9.25937 10.9875 9.25937 11.0531 9.325L11.4469 9.72188C11.5125 9.7875 11.5125 9.89375 11.4469 9.95937L9.44062 11.9469C9.375 12.0125 9.26875 12.0125 9.20312 11.9469L8.05 10.7812C7.98438 10.7156 7.98438 10.6094 8.05 10.5437ZM9 16.25C8.58437 16.25 8.25 15.9156 8.25 15.5C8.25 15.0844 8.58437 14.75 9 14.75C9.41563 14.75 9.75 15.0844 9.75 15.5C9.75 15.9156 9.41563 16.25 9 16.25ZM16 15.75C16 15.8875 15.8875 16 15.75 16H11.25C11.1125 16 11 15.8875 11 15.75V15.25C11 15.1125 11.1125 15 11.25 15H15.75C15.8875 15 16 15.1125 16 15.25V15.75ZM16 11.75C16 11.8875 15.8656 12 15.7 12H10.8156L11.825 11H15.7C15.8656 11 16 11.1125 16 11.25V11.75Z" />
  );
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={defaultClass}
      aria-hidden={ariaHidden}
    >
      {iconVariant}
    </svg>
  );
};

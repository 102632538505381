import React from 'react';
import classNames from 'classnames';

import { IconCheck } from '../Icon';

export interface IScrollListItemProps extends React.HTMLAttributes<HTMLAllCollection> {
  itemId: number;
  label: string;
  isSelected?: boolean;
  isDisabled?: boolean;
  onClickItem: (id: number) => void;
  ariaLabel?: string;
}

export const ScrollListItem: React.FC<IScrollListItemProps> = ({
  itemId,
  label,
  isSelected,
  isDisabled,
  onClickItem,
  ariaLabel,
}: IScrollListItemProps) => {
  const styleClasses = {
    mainContainer: classNames(
      'sui-w-full sui-h-10 sui-flex sui-justify-center sui-items-center sui-gap-2',
      'sui-border sui-border-transparent sui-cursor-pointer focus:sui-outline-none',
      { 'sui-bg-primary-lightest': isSelected },
      'hover:sui-bg-lightGray-lightest',
      {
        'focus:sui-bg-lightGray-lightest focus:sui-border-y-frame-border': !isSelected,
      },
      'active:sui-bg-primary-lightest',
      {
        'sui-text-lightGray-darkest disabled:sui-text-disabled sui-pointer-events-none': isDisabled,
      }
    ),
    iconContainer: 'sui-w-5 sui-h-5 sui-text-primary sui-flex sui-items-center sui-justify-center',
    labelContainer: 'sui-text-b3 sui-font-body sui-font-normal',
  };

  return (
    <button
      className={styleClasses.mainContainer}
      disabled={isDisabled}
      onClick={() => onClickItem(itemId)}
      aria-label={`${ariaLabel} item ${label}`}
    >
      <div className={styleClasses.iconContainer}>{isSelected && <IconCheck />}</div>
      <div className={styleClasses.labelContainer}>{label}</div>
      <div className={styleClasses.iconContainer}></div>
    </button>
  );
};

import React from 'react';
import classNames from 'classnames';

import { IconTypes } from '../../../types';

export const IconCalendarClock = ({
  className,
  width = 24,
  height = 24,
  ariaHidden = true,
  isClickable = false,
}: IconTypes): React.ReactElement => {
  const defaultClass = classNames(
    'sui-fill-current',
    { 'sui-pointer-events-none': !isClickable },
    className
  );

  const iconVariant = (
    <path d="M7.11111 5C7.33333 5 7.55556 5.22222 7.55556 5.44444V6.77778H12.8889V5.44444C12.8889 5.22222 13.0833 5 13.3333 5C13.5556 5 13.7778 5.22222 13.7778 5.44444V6.77778H14.6667C15.6389 6.77778 16.4444 7.58333 16.4444 8.55556V10.3333H4.88889V17.4444C4.88889 17.9444 5.27778 18.3333 5.77778 18.3333H12.2222C12.5 18.6944 12.8056 18.9722 13.1667 19.2222H5.77778C4.77778 19.2222 4 18.4444 4 17.4444V8.55556C4 7.58333 4.77778 6.77778 5.77778 6.77778H6.66667V5.44444C6.66667 5.22222 6.86111 5 7.11111 5ZM14.6667 7.66667H5.77778C5.27778 7.66667 4.88889 8.08333 4.88889 8.55556V9.44444H15.5556V8.55556C15.5556 8.08333 15.1389 7.66667 14.6667 7.66667ZM16 13C16.2222 13 16.4444 13.2222 16.4444 13.4444V14.7778H17.7778C18 14.7778 18.2222 15 18.2222 15.2222C18.2222 15.4722 18 15.6667 17.7778 15.6667H16.4444V17C16.4444 17.25 16.2222 17.4444 16 17.4444C15.75 17.4444 15.5556 17.25 15.5556 17V15.6667H14.2222C13.9722 15.6667 13.7778 15.4722 13.7778 15.2222C13.7778 15 13.9722 14.7778 14.2222 14.7778H15.5556V13.4444C15.5556 13.2222 15.75 13 16 13ZM12 15.2222C12 13.0278 13.7778 11.2222 16 11.2222C18.1944 11.2222 20 13.0278 20 15.2222C20 17.4444 18.1944 19.2222 16 19.2222C13.7778 19.2222 12 17.4444 12 15.2222ZM16 18.3333C17.6944 18.3333 19.1111 16.9444 19.1111 15.2222C19.1111 13.5278 17.6944 12.1111 16 12.1111C14.2778 12.1111 12.8889 13.5278 12.8889 15.2222C12.8889 16.9444 14.2778 18.3333 16 18.3333Z" />
  );
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={defaultClass}
      aria-hidden={ariaHidden}
    >
      {iconVariant}
    </svg>
  );
};

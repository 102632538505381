import React from 'react';
import classNames from 'classnames';

interface ButtonGroupProps extends React.HTMLAttributes<HTMLDivElement> {
  clearStyle?: boolean;
}

export const ButtonGroup: React.FC<ButtonGroupProps> = ({
  children,
  className,
  clearStyle,
  ...props
}: ButtonGroupProps) => {
  const defaultClass = classNames({ 'sui-flex sui-gap-2': !clearStyle }, className);

  return (
    <div className={defaultClass} {...props}>
      {children}
    </div>
  );
};

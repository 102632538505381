import React, { useCallback } from 'react';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';

export type TabIconProps = {
  icon: React.ReactChild;
  iconHover?: React.ReactChild;
  iconOnly?: boolean;
};

export interface ITabTitleProps {
  title: string;
  index: number;
  activeTab: number;
  setSelectedTab: (index: number) => void;
  className?: string;
  isLarge?: boolean;
  disabled?: boolean;
  to?: string;
  exact?: boolean;
  isSettings?: boolean;
  icon?: TabIconProps;
}

export const TabTitle: React.FC<ITabTitleProps> = ({
  title,
  index,
  activeTab,
  className,
  disabled,
  icon,
  isLarge,
  isSettings,
  setSelectedTab,
  to,
  exact,
}: ITabTitleProps) => {
  const onClick = useCallback(() => {
    setSelectedTab(index);
  }, [setSelectedTab, index]);
  const tabTitle = classNames('sui-font-medium', isLarge ? 'sui-text-b3' : 'sui-text-b4', {
    'sui-c-tab-disabled': disabled,
  });

  const defaultClass = classNames(
    'sui-c-tab',
    {
      'sui-c-tab-active': activeTab === index && !to,
      'sui-c-tab-disabled': disabled,
      'sui-ml-auto': isSettings,
    },
    className
  );

  const iconHovered =
    icon &&
    (icon.iconHover ? (
      <>
        <span className="sui-c-tab-icon-main">{icon.icon}</span>
        <span className="sui-c-tab-icon-active">{icon.iconHover}</span>
      </>
    ) : (
      <span>{icon.icon}</span>
    ));

  const titleWrapper = icon ? (
    icon.iconOnly ? (
      iconHovered
    ) : (
      <div className="sui-flex sui-items-center sui-justify-center sui-gap-2">
        {iconHovered}
        <span>{title}</span>
      </div>
    )
  ) : (
    title
  );

  return (
    <li className={defaultClass} role="tab" aria-labelledby={title}>
      {to ? (
        <NavLink to={to} exact={exact} className={tabTitle} activeClassName="sui-c-tab-active">
          {titleWrapper}
        </NavLink>
      ) : (
        <button
          type="button" // Prevent auto submission of forms
          className={tabTitle}
          onClick={onClick}
          disabled={disabled}
          aria-disabled={disabled}
        >
          {titleWrapper}
        </button>
      )}
    </li>
  );
};

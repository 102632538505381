import React from 'react';
import classNames from 'classnames';

import { IconOutline, IconTypes } from '../../../types';

type IconUserProps = {
  variant?: 'user' | 'user-alt' | 'circle';
};

export const IconUser = ({
  className,
  width,
  height,
  variant = 'user',
  outline,
  ariaHidden = true,
  isClickable = false,
}: IconTypes & IconUserProps & IconOutline): React.ReactElement => {
  const defaultClass = classNames(
    'sui-fill-current',
    { 'sui-pointer-events-none': !isClickable },
    className
  );
  let viewBox = '0 0 14 16';
  let svgWidth = width ? width : 14;
  let svgHeight = height ? height : 16;
  let iconVariant = outline ? (
    // User
    <path d="M9.8 9C8.90312 9 8.47188 9.5 7 9.5C5.52812 9.5 5.1 9 4.2 9C1.88125 9 0 10.8813 0 13.2V14.5C0 15.3281 0.671875 16 1.5 16H12.5C13.3281 16 14 15.3281 14 14.5V13.2C14 10.8813 12.1187 9 9.8 9ZM13 14.5C13 14.775 12.775 15 12.5 15H1.5C1.225 15 1 14.775 1 14.5V13.2C1 11.4344 2.43437 10 4.2 10C4.8125 10 5.42188 10.5 7 10.5C8.575 10.5 9.1875 10 9.8 10C11.5656 10 13 11.4344 13 13.2V14.5ZM7 8C9.20938 8 11 6.20937 11 4C11 1.79063 9.20938 0 7 0C4.79063 0 3 1.79063 3 4C3 6.20937 4.79063 8 7 8ZM7 1C8.65312 1 10 2.34687 10 4C10 5.65312 8.65312 7 7 7C5.34688 7 4 5.65312 4 4C4 2.34687 5.34688 1 7 1Z" />
  ) : (
    <path d="M7 8C9.20938 8 11 6.20937 11 4C11 1.79063 9.20938 0 7 0C4.79063 0 3 1.79063 3 4C3 6.20937 4.79063 8 7 8ZM9.8 9H9.27812C8.58437 9.31875 7.8125 9.5 7 9.5C6.1875 9.5 5.41875 9.31875 4.72188 9H4.2C1.88125 9 0 10.8813 0 13.2V14.5C0 15.3281 0.671875 16 1.5 16H12.5C13.3281 16 14 15.3281 14 14.5V13.2C14 10.8813 12.1187 9 9.8 9Z" />
  );

  if (variant === 'user-alt') {
    viewBox = '0 0 16 16';
    svgWidth = width ? width : 16;
    svgHeight = height ? height : 16;
    iconVariant = outline ? (
      <path d="M8 1C9.93125 1 11.5 2.56875 11.5 4.5C11.5 6.43125 9.93125 8 8 8C6.06875 8 4.5 6.43125 4.5 4.5C4.5 2.56875 6.06875 1 8 1ZM12 11C13.6531 11 15 12.3469 15 14V15H1V14C1 12.3469 2.34687 11 4 11C6.65625 11 6.10313 11.5 8 11.5C9.90312 11.5 9.34062 11 12 11ZM8 0C5.51562 0 3.5 2.01562 3.5 4.5C3.5 6.98438 5.51562 9 8 9C10.4844 9 12.5 6.98438 12.5 4.5C12.5 2.01562 10.4844 0 8 0ZM12 10C9.1125 10 9.78125 10.5 8 10.5C6.225 10.5 6.88438 10 4 10C1.79063 10 0 11.7906 0 14V15C0 15.5531 0.446875 16 1 16H15C15.5531 16 16 15.5531 16 15V14C16 11.7906 14.2094 10 12 10Z" />
    ) : (
      <path d="M8 9C10.4844 9 12.5 6.98438 12.5 4.5C12.5 2.01562 10.4844 0 8 0C5.51562 0 3.5 2.01562 3.5 4.5C3.5 6.98438 5.51562 9 8 9ZM12 10H10.2781C9.58437 10.3188 8.8125 10.5 8 10.5C7.1875 10.5 6.41875 10.3188 5.72188 10H4C1.79063 10 0 11.7906 0 14V14.5C0 15.3281 0.671875 16 1.5 16H14.5C15.3281 16 16 15.3281 16 14.5V14C16 11.7906 14.2094 10 12 10Z" />
    );
  }

  if (variant === 'circle') {
    viewBox = '0 0 16 16';
    svgWidth = width ? width : 16;
    svgHeight = height ? height : 16;
    iconVariant = outline ? (
      <path d="M8 0C3.58065 0 0 3.58065 0 8C0 12.4194 3.58065 16 8 16C12.4194 16 16 12.4194 16 8C16 3.58065 12.4194 0 8 0ZM12.129 13.6C10.971 14.4548 9.54516 14.9677 8 14.9677C6.45484 14.9677 5.02903 14.4548 3.87097 13.6V13.1613C3.87097 12.0226 4.79677 11.0968 5.93548 11.0968C6.29355 11.0968 6.82258 11.4645 8 11.4645C9.18064 11.4645 9.70323 11.0968 10.0645 11.0968C11.2032 11.0968 12.129 12.0226 12.129 13.1613V13.6ZM13.1161 12.7129C12.8968 11.2161 11.6226 10.0645 10.0645 10.0645C9.40323 10.0645 9.08387 10.4323 8 10.4323C6.91613 10.4323 6.6 10.0645 5.93548 10.0645C4.37742 10.0645 3.10323 11.2161 2.88387 12.7129C1.73871 11.471 1.03226 9.81936 1.03226 8C1.03226 4.15806 4.15806 1.03226 8 1.03226C11.8419 1.03226 14.9677 4.15806 14.9677 8C14.9677 9.81936 14.2613 11.471 13.1161 12.7129ZM8 3.6129C6.43226 3.6129 5.16129 4.88387 5.16129 6.45161C5.16129 8.01935 6.43226 9.29032 8 9.29032C9.56774 9.29032 10.8387 8.01935 10.8387 6.45161C10.8387 4.88387 9.56774 3.6129 8 3.6129ZM8 8.25806C7.00323 8.25806 6.19355 7.44839 6.19355 6.45161C6.19355 5.45484 7.00323 4.64516 8 4.64516C8.99677 4.64516 9.80645 5.45484 9.80645 6.45161C9.80645 7.44839 8.99677 8.25806 8 8.25806Z" />
    ) : (
      <path d="M8 0C3.58065 0 0 3.58065 0 8C0 12.4194 3.58065 16 8 16C12.4194 16 16 12.4194 16 8C16 3.58065 12.4194 0 8 0ZM8 3.09677C9.56774 3.09677 10.8387 4.36774 10.8387 5.93548C10.8387 7.50323 9.56774 8.77419 8 8.77419C6.43226 8.77419 5.16129 7.50323 5.16129 5.93548C5.16129 4.36774 6.43226 3.09677 8 3.09677ZM8 14.1935C6.10645 14.1935 4.40968 13.3355 3.27419 11.9935C3.88065 10.8516 5.06774 10.0645 6.45161 10.0645C6.52903 10.0645 6.60645 10.0774 6.68064 10.1C7.1 10.2355 7.53871 10.3226 8 10.3226C8.46129 10.3226 8.90322 10.2355 9.31935 10.1C9.39355 10.0774 9.47097 10.0645 9.54839 10.0645C10.9323 10.0645 12.1194 10.8516 12.7258 11.9935C11.5903 13.3355 9.89355 14.1935 8 14.1935Z" />
    );
  }
  return (
    <svg
      width={svgWidth}
      height={svgHeight}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={defaultClass}
      aria-hidden={ariaHidden}
    >
      {iconVariant}
    </svg>
  );
};

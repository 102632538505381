import React from 'react';
import classNames from 'classnames';

export interface IRadioProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'size'> {
  label?: string;
  isLarge?: boolean;
  isInvalid?: boolean;
}

export const Radio: React.FC<IRadioProps> = ({
  children,
  label,
  checked,
  className,
  isLarge,
  disabled,
  isInvalid,
  ...props
}: IRadioProps) => {
  let textSize = 'sui-text-b4';
  let radioSize = 'sui-w-base sui-h-base sui-mr-2';
  let errorClass = '';
  let textColor = '';

  // Text Size
  if (isLarge) {
    textSize = 'sui-text-b3';
    radioSize = 'sui-u-radio-large sui-mr-2.5';
  }

  if (disabled) {
    textColor = 'sui-text-lightGray-darkest';
  }

  if (isInvalid) {
    errorClass = 'is-invalid';
  }
  const defaultClass = classNames(
    'sui-flex sui-items-start sui-cursor-pointer sui-text-base sui-w-full',
    className,
    textSize,
    errorClass,
    textColor
  );

  const inputRadioClass = classNames(
    'sui-rounded-full sui-border sui-border-lightGray-darkest sui-bg-white sui-flex-no-shrink sui-inline-flex sui-justify-center sui-items-center sui-flex-shrink-0',
    radioSize
  );
  return (
    <div className="sui-c-radio-button">
      <label className={defaultClass}>
        <input
          type="radio"
          className="sui-sr-only"
          checked={checked}
          disabled={disabled}
          aria-checked={checked}
          aria-disabled={disabled}
          {...props}
        />
        <span className={`sui-input-subs sui-flex sui-items-center sui-h-${isLarge ? 6 : 5}`}>
          <span className={inputRadioClass}></span>
        </span>
        {children ? <div>{children}</div> : label}
      </label>
    </div>
  );
};

import React from 'react';
import classNames from 'classnames';

export interface ITableProps extends React.HTMLAttributes<HTMLTableElement> {
  noHover?: boolean;
  responsive?: boolean;
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  isEmpty?: React.ReactChild;
}

export const Table: React.FC<ITableProps> = ({
  children,
  className,
  noHover,
  responsive = false,
  size,
  isEmpty,
  ...props
}: ITableProps) => {
  let tableSize = 'sui-u-table-md';
  // Sizes
  if (size === 'xs') {
    tableSize = 'sui-u-table-xs';
  }
  if (size === 'sm') {
    tableSize = 'sui-u-table-sm';
  }
  if (size === 'lg') {
    tableSize = 'sui-u-table-lg';
  }
  if (size === 'xl') {
    tableSize = 'sui-u-table-xl';
  }

  let noHoverClass = '';

  if (noHover) {
    noHoverClass = 'no-hover';
  }

  const defaultStyle = classNames(
    'sui-c-table sui-w-full sui-text-left',
    className,
    tableSize,
    noHoverClass
  );

  if (responsive) {
    return (
      <div className="sui-c-table-container sui-w-full sui-overflow-x-auto">
        <table className={defaultStyle} {...props}>
          {children}
          {isEmpty ? (
            <tbody role="rowgroup">
              <tr>
                <td colSpan={100} className="sui-py-20">
                  {isEmpty}
                </td>
              </tr>
            </tbody>
          ) : null}
        </table>
      </div>
    );
  }

  return (
    <table className={defaultStyle} {...props}>
      {children}
      {isEmpty ? (
        <tbody role="rowgroup">
          <tr>
            <td colSpan={100} className="sui-py-20">
              {isEmpty}
            </td>
          </tr>
        </tbody>
      ) : null}
    </table>
  );
};

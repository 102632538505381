import React, { ReactElement } from 'react';
import classNames from 'classnames';

import { ToolbarType } from '../../types';
import { UncontrolledTooltip } from '../Tooltip';

import { colorPalette } from './colorPalette';
import { importCustomIconsToQuill } from './Icons';

export interface IToolbarProps extends React.HTMLAttributes<HTMLDivElement> {
  id: string;
  type: ToolbarType;
  disabled: boolean;
}

const TooltippedOption = ({
  tooltipText,
  children,
}: {
  tooltipText?: string;
  children: ReactElement;
}) => {
  return (
    <>
      {tooltipText ? (
        <UncontrolledTooltip content={tooltipText}>
          <>{children}</>
        </UncontrolledTooltip>
      ) : (
        <>{children}</>
      )}
    </>
  );
};

export const Toolbar: React.FC<IToolbarProps> = ({ id, type, disabled }: IToolbarProps) => {
  const colorOptions = colorPalette.map((color: string) => <option key={color} value={color} />);

  importCustomIconsToQuill();

  return (
    <div id={`toolbar-${id}`} className={classNames({ 'ql-disabled': disabled })}>
      {type === 'simple' ? (
        <>
          <div className="ql-formats">
            <TooltippedOption tooltipText={disabled ? '' : 'Font size'}>
              <select className="ql-size">
                <option value="small" />
                <option selected />
                <option value="large" />
                <option value="huge" />
              </select>
            </TooltippedOption>
          </div>
          <div className="ql-formats">
            <TooltippedOption tooltipText={disabled ? '' : 'Bold'}>
              <button className="ql-bold" />
            </TooltippedOption>
            <TooltippedOption tooltipText={disabled ? '' : 'Italic'}>
              <button className="ql-italic" />
            </TooltippedOption>
            <TooltippedOption tooltipText={disabled ? '' : 'Underline'}>
              <button className="ql-underline" />
            </TooltippedOption>
          </div>
          <div className="ql-formats">
            <TooltippedOption tooltipText={disabled ? '' : 'Link'}>
              <button className="ql-link" />
            </TooltippedOption>
          </div>
        </>
      ) : (
        <>
          <div className="ql-formats">
            <TooltippedOption tooltipText={disabled ? '' : 'Font size'}>
              <select className="ql-size">
                <option value="small" />
                <option selected />
                <option value="large" />
                <option value="huge" />
              </select>
            </TooltippedOption>
          </div>
          <div className="ql-formats">
            <TooltippedOption tooltipText={disabled ? '' : 'Bold'}>
              <button className="ql-bold" />
            </TooltippedOption>
            <TooltippedOption tooltipText={disabled ? '' : 'Italic'}>
              <button className="ql-italic" />
            </TooltippedOption>
            <TooltippedOption tooltipText={disabled ? '' : 'Underline'}>
              <button className="ql-underline" />
            </TooltippedOption>
            <TooltippedOption tooltipText={disabled ? '' : 'Strikethrough'}>
              <button className="ql-strike" />
            </TooltippedOption>
          </div>
          <div className="ql-formats">
            <TooltippedOption tooltipText={disabled ? '' : 'Text color'}>
              <select className="ql-color">{colorOptions}</select>
            </TooltippedOption>
            <TooltippedOption tooltipText={disabled ? '' : 'Background color'}>
              <select className="ql-background">{colorOptions}</select>
            </TooltippedOption>
          </div>
          <div className="ql-formats">
            <select className="ql-align" />
            <TooltippedOption tooltipText={disabled ? '' : 'Bullet list'}>
              <button className="ql-list" value="bullet" />
            </TooltippedOption>
            <TooltippedOption tooltipText={disabled ? '' : 'Numbered list'}>
              <button className="ql-list" value="ordered" />
            </TooltippedOption>
            <TooltippedOption tooltipText={disabled ? '' : 'Indent more'}>
              <button className="ql-indent" value="+1" />
            </TooltippedOption>
            <TooltippedOption tooltipText={disabled ? '' : 'Indent less'}>
              <button className="ql-indent" value="-1" />
            </TooltippedOption>
          </div>
          <div className="ql-formats">
            <TooltippedOption tooltipText={disabled ? '' : 'Link'}>
              <button className="ql-link" />
            </TooltippedOption>
            <TooltippedOption tooltipText={disabled ? '' : 'Insert image'}>
              <button className="ql-image" />
            </TooltippedOption>
          </div>
        </>
      )}
    </div>
  );
};

import React from 'react';
import classNames from 'classnames';

import { DateRange } from '../../types';
import { currentDate, isBoundedRange, isDateInRange } from '../../lib/DateUtils';

import { CalendarSide } from './DatePicker';

export interface IDayProps extends React.HTMLAttributes<HTMLAllCollection> {
  date: Date;
  displayedMonth: Date;
  selectedRange: DateRange;
  disabledRanges: ReadonlyArray<DateRange>;
  side?: CalendarSide;
  onSelectDate: (day: Date) => void;
}

export const Day: React.FC<IDayProps> = ({
  date,
  displayedMonth,
  selectedRange,
  disabledRanges,
  side,
  onSelectDate,
}: IDayProps) => {
  const isOutsideCurrentMonth = date.getMonth() !== displayedMonth.getMonth();
  const isCurrentDate = date.getTime() === currentDate.getTime();
  const isSelectedRangeFirst = date.getTime() === selectedRange.start?.getTime();
  const isSelectedRangeLast = date.getTime() === selectedRange.end?.getTime();
  let isInSelectedRange = false;
  if (isBoundedRange(selectedRange)) {
    isInSelectedRange = isDateInRange(date, selectedRange, false, false);
  }
  const isInDisabledRange = disabledRanges.some(range => isDateInRange(date, range, true, true));

  const isDisabled = isOutsideCurrentMonth || isInDisabledRange;
  const isRangeBoundary = isSelectedRangeFirst || isSelectedRangeLast;

  const dayClickHandler = () => {
    onSelectDate(date);
  };

  const dateLong = date.toLocaleDateString('en', {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });

  const styleClasses = {
    background: classNames(
      'sui-absolute sui-inset-y-0 sui-z-0',
      isDisabled ? 'sui-bg-primary-lightest' : 'sui-bg-primary-lighter',
      { 'sui-left-1/2 sui-right-0': isSelectedRangeFirst && selectedRange.end },
      { 'sui-left-0 sui-right-1/2': isSelectedRangeLast },
      { 'sui-inset-x-0': isInSelectedRange }
    ),
    day: classNames(
      'sui-flex sui-items-center sui-justify-center',
      'sui-w-10 sui-h-10 sui-rounded-full sui-text-b4 sui-relative sui-z-10 sui-border sui-border-transparent',
      'hover:sui-underline',
      'active:sui-border-lightGray',
      'focus:sui-border-lightGray focus:sui-outline-none',
      { 'sui-text-primary': isInSelectedRange && !isOutsideCurrentMonth },
      { 'sui-text-white sui-bg-primary': isRangeBoundary },
      {
        'sui-text-primary sui-border-primary': isCurrentDate && !isRangeBoundary && !isDisabled,
      },
      { 'sui-text-lightGray sui-pointer-events-none': isDisabled },
      { 'sui-text-primary-light': isDisabled && isInSelectedRange },
      {
        'sui-text-lightGray sui-border-lightGray-darkest': isDisabled && isCurrentDate,
      },
      { 'sui-text-primary-light sui-bg-primary-lighter': isDisabled && isRangeBoundary }
    ),
  };

  const sideLabel = side ? `${side} side ` : '';

  return (
    <div className="sui-relative">
      <div className={styleClasses.background} />
      <button
        className={styleClasses.day}
        aria-label={`${sideLabel}${dateLong}`}
        onClick={dayClickHandler}
        disabled={isDisabled}
      >
        {date.getDate()}
      </button>
    </div>
  );
};

import React from 'react';
import classNames from 'classnames';

import { IconOutline, IconTypes } from '../../../types';

export type IconExclamationProps = {
  variant?: 'default' | 'circle' | 'square' | 'triangle';
};

export const IconExclamation = ({
  className,
  width = 24,
  height = 24,
  variant = 'default',
  ariaHidden = true,
  isClickable = false,
  outline,
}: IconTypes & IconExclamationProps & IconOutline): React.ReactElement => {
  const defaultClass = classNames(
    'sui-fill-current sui-icon-exclamation',
    { 'sui-pointer-events-none': !isClickable },
    className
  );
  let iconVariant = outline ? (
    <path d="M13.3536 5C13.5689 5 13.74 5.18084 13.728 5.39581L13.2697 13.6458C13.2587 13.8445 13.0943 14 12.8953 14H11.1047C10.9057 14 10.7414 13.8445 10.7303 13.6458L10.272 5.39581C10.26 5.18084 10.4311 5 10.6464 5H13.3536ZM12 15C13.1028 15 14 15.8972 14 17C14 18.1028 13.1028 19 12 19C10.8972 19 10 18.1028 10 17C10 15.8972 10.8972 15 12 15ZM13.3536 4H10.6464C9.85803 4 9.22978 4.66394 9.27353 5.45128L9.73188 13.7013C9.75225 14.0678 9.91512 14.3943 10.1648 14.6283C9.45666 15.1776 9 16.0365 9 17C9 18.6542 10.3458 20 12 20C13.6542 20 15 18.6542 15 17C15 16.0365 14.5433 15.1776 13.8352 14.6283C14.0849 14.3943 14.2478 14.0678 14.2681 13.7013L14.7265 5.45128C14.7702 4.66409 14.1421 4 13.3536 4Z" />
  ) : (
    <path d="M14.5 17.5C14.5 18.8785 13.3785 20 12 20C10.6215 20 9.5 18.8785 9.5 17.5C9.5 16.1215 10.6215 15 12 15C13.3785 15 14.5 16.1215 14.5 17.5ZM9.78938 4.78747L10.2144 13.2875C10.2343 13.6866 10.5638 14 10.9634 14H13.0366C13.4362 14 13.7657 13.6866 13.7856 13.2875L14.2106 4.78747C14.232 4.35906 13.8905 4 13.4616 4H10.5384C10.1095 4 9.76797 4.35906 9.78938 4.78747Z" />
  );

  if (variant === 'circle') {
    iconVariant = outline ? (
      <path d="M12 5.03226C15.8265 5.03226 18.9677 8.13145 18.9677 12C18.9677 15.8481 15.8513 18.9677 12 18.9677C8.15342 18.9677 5.03226 15.8528 5.03226 12C5.03226 8.15474 8.14845 5.03226 12 5.03226ZM12 4C7.58203 4 4 7.58332 4 12C4 16.4193 7.58203 20 12 20C16.418 20 20 16.4193 20 12C20 7.58332 16.418 4 12 4ZM11.6294 7.87097H12.3706C12.5907 7.87097 12.7665 8.05426 12.7574 8.27419L12.5316 13.6935C12.5229 13.9009 12.3524 14.0645 12.1448 14.0645H11.8552C11.6476 14.0645 11.477 13.9009 11.4684 13.6935L11.2426 8.27419C11.2335 8.05426 11.4093 7.87097 11.6294 7.87097ZM12 14.7097C11.5012 14.7097 11.0968 15.1141 11.0968 15.6129C11.0968 16.1117 11.5012 16.5161 12 16.5161C12.4988 16.5161 12.9032 16.1117 12.9032 15.6129C12.9032 15.1141 12.4988 14.7097 12 14.7097Z" />
    ) : (
      <path d="M20 12C20 16.4193 16.418 20 12 20C7.58203 20 4 16.4193 4 12C4 7.58332 7.58203 4 12 4C16.418 4 20 7.58332 20 12ZM12 13.6129C11.1805 13.6129 10.5161 14.2773 10.5161 15.0968C10.5161 15.9163 11.1805 16.5806 12 16.5806C12.8195 16.5806 13.4839 15.9163 13.4839 15.0968C13.4839 14.2773 12.8195 13.6129 12 13.6129ZM10.5912 8.27916L10.8305 12.6663C10.8417 12.8715 11.0114 13.0323 11.217 13.0323H12.783C12.9886 13.0323 13.1583 12.8715 13.1695 12.6663L13.4088 8.27916C13.4209 8.05742 13.2444 7.87097 13.0223 7.87097H10.9777C10.7556 7.87097 10.5791 8.05742 10.5912 8.27916Z" />
    );
  }

  if (variant === 'square') {
    iconVariant = outline ? (
      <path d="M11.8393 14.2857H12.1607C12.3893 14.2857 12.5786 14.1036 12.5893 13.875L12.8393 7.875C12.85 7.63214 12.6536 7.42857 12.4107 7.42857H11.5893C11.3464 7.42857 11.15 7.63214 11.1607 7.875L11.4107 13.875C11.4214 14.1036 11.6107 14.2857 11.8393 14.2857ZM18.2857 4H5.71429C4.76786 4 4 4.76786 4 5.71429V18.2857C4 19.2321 4.76786 20 5.71429 20H18.2857C19.2321 20 20 19.2321 20 18.2857V5.71429C20 4.76786 19.2321 4 18.2857 4ZM18.8571 18.2857C18.8571 18.6 18.6 18.8571 18.2857 18.8571H5.71429C5.4 18.8571 5.14286 18.6 5.14286 18.2857V5.71429C5.14286 5.4 5.4 5.14286 5.71429 5.14286H18.2857C18.6 5.14286 18.8571 5.4 18.8571 5.71429V18.2857ZM12 15C11.4464 15 11 15.4464 11 16C11 16.5536 11.4464 17 12 17C12.5536 17 13 16.5536 13 16C13 15.4464 12.5536 15 12 15Z" />
    ) : (
      <path d="M18.2857 4H5.71429C4.7675 4 4 4.7675 4 5.71429V18.2857C4 19.2325 4.7675 20 5.71429 20H18.2857C19.2325 20 20 19.2325 20 18.2857V5.71429C20 4.7675 19.2325 4 18.2857 4ZM10.8682 7.42857H13.1318C13.3777 7.42857 13.5731 7.635 13.5598 7.8805L13.2948 12.7376C13.2824 12.9649 13.0945 13.1429 12.8669 13.1429H11.1331C10.9055 13.1429 10.7176 12.9649 10.7052 12.7376L10.4402 7.8805C10.4269 7.635 10.6223 7.42857 10.8682 7.42857ZM12 17.0714C11.0927 17.0714 10.3571 16.3359 10.3571 15.4286C10.3571 14.5213 11.0927 13.7857 12 13.7857C12.9073 13.7857 13.6429 14.5213 13.6429 15.4286C13.6429 16.3359 12.9073 17.0714 12 17.0714Z" />
    );
  }

  if (variant === 'triangle') {
    iconVariant = outline ? (
      <path d="M11.5051 9.3333H12.4913C12.5857 9.3333 12.6607 9.41108 12.6579 9.50553L12.4496 14.9502C12.4468 15.0391 12.3718 15.1113 12.2829 15.1113H11.7135C11.6246 15.1113 11.5496 15.0419 11.5468 14.9502L11.3384 9.50553C11.3357 9.41108 11.4107 9.3333 11.5051 9.3333ZM11.9996 15.6669C11.569 15.6669 11.2218 16.0141 11.2218 16.4447C11.2218 16.8753 11.569 17.2225 11.9996 17.2225C12.4302 17.2225 12.7774 16.8753 12.7774 16.4447C12.7774 16.0141 12.4302 15.6669 11.9996 15.6669ZM19.8194 17.1114L13.1552 5.55537C12.6441 4.66644 11.3579 4.66644 10.844 5.55537L4.17982 17.1114C3.66868 17.9976 4.3076 19.1115 5.33542 19.1115H18.6665C19.6888 19.1115 20.3333 18.0003 19.8194 17.1114ZM18.6665 18.2226H5.33264C4.99096 18.2226 4.77706 17.8531 4.94651 17.5559L11.6135 5.99983C11.7829 5.70537 12.2135 5.70259 12.3829 5.99983L19.0499 17.5559C19.2221 17.8503 19.0082 18.2226 18.6665 18.2226Z" />
    ) : (
      <path d="M19.8199 17.1115C20.3326 18.0002 19.6891 19.1111 18.665 19.1111H5.33484C4.30881 19.1111 3.6682 17.9985 4.17992 17.1115L10.8451 5.55517C11.3581 4.66603 12.6429 4.66764 13.1549 5.55517L19.8199 17.1115V17.1115ZM12 14.7222C11.2943 14.7222 10.7222 15.2943 10.7222 16C10.7222 16.7057 11.2943 17.2778 12 17.2778C12.7057 17.2778 13.2778 16.7057 13.2778 16C13.2778 15.2943 12.7057 14.7222 12 14.7222ZM10.7869 10.1293L10.9929 13.9071C11.0026 14.0839 11.1487 14.2222 11.3258 14.2222H12.6743C12.8513 14.2222 12.9974 14.0839 13.0071 13.9071L13.2131 10.1293C13.2236 9.93836 13.0715 9.7778 12.8803 9.7778H11.1197C10.9285 9.7778 10.7765 9.93836 10.7869 10.1293V10.1293Z" />
    );
  }

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={defaultClass}
      aria-hidden={ariaHidden}
    >
      {iconVariant}
    </svg>
  );
};

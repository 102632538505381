import React from 'react';
import classNames from 'classnames';

import { PageHeaderVariant } from '../../types';
import { Breadcrumbs, BreadcrumbsItems } from '../Breadcrumbs';

export interface IPageHeaderProps {
  title?: string;
  subTitle?: string;
  className?: string;
  innerPadding?: string;
  children?: React.ReactNode;
  backButton?: React.ReactNode;
  breadcrumbs?: BreadcrumbsItems;
  variant?: PageHeaderVariant;
  isTelus?: boolean;
  badge?: React.ReactNode;
  editButton?: React.ReactNode;
  isSubInfoStacked?: boolean;
}

const InnerPageHeader = (
  {
    title,
    subTitle,
    children,
    backButton,
    breadcrumbs,
    className,
    innerPadding = 'sui-py-base sui-px-xl',
    isTelus,
    badge,
    editButton,
    isSubInfoStacked,
    variant = PageHeaderVariant.TEXT,
  }: IPageHeaderProps,
  ref: React.Ref<HTMLDivElement>
) => {
  const defaultStyle = classNames(
    'sui-z-10',
    { 'sui-sticky sui-top-14': !isTelus },
    variant === PageHeaderVariant.SEARCHBAR
      ? 'sui-bg-secondary-bg sui-c-pageheader-search-bar'
      : 'sui-bg-white sui-border-b sui-border-black-10 ',
    className
  );
  const headerTitleStyle = classNames('sui-text-b1 sui-font-bold sui-text-darkGray-darkest', {
    'sui-self-center': variant === PageHeaderVariant.SEARCHBAR,
  });
  return isTelus ? (
    <div className={defaultStyle}>
      <div className={innerPadding}>
        {breadcrumbs ? <Breadcrumbs isTelus items={breadcrumbs} className="sui-mb-1.5" /> : null}
        {variant === PageHeaderVariant.INPUT ? (
          <div className="sui-flex sui-w-full sui-items-center sui-gap-4" ref={ref}>
            {children}
          </div>
        ) : (
          <>
            <div className="sui-flex sui-justify-between">
              <div
                className={classNames('sui-flex sui-items-center sui-gap-4', {
                  'sui-max-h-8': backButton,
                })}
              >
                {backButton && <div className="sui--ml-1.5 sui--mr-1">{backButton}</div>}
                <h1 className={headerTitleStyle}>{title}</h1>
                {editButton ? editButton : null}
                {!isSubInfoStacked ? (
                  <div className="sui-flex sui-items-center sui-gap-4 sui-max-h-8">
                    {badge ? badge : null}
                    {subTitle ? (
                      <p className="sui-text-lightGray-darker sui-text-b3 sui-font-medium sui-mt-xs">
                        {subTitle}
                      </p>
                    ) : null}
                  </div>
                ) : null}
              </div>
              <>{children}</>
            </div>
            <div>
              {isSubInfoStacked && (
                <div className="sui-flex sui-flex-col sui-items-start sui-gap-4 sui-mb-4">
                  {subTitle && (
                    <p className="sui-text-lightGray-darker sui-text-b4 sui-font-normal sui-mt-5">
                      {subTitle}
                    </p>
                  )}
                  {badge}
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  ) : (
    <div className={defaultStyle}>
      <div className={innerPadding}>
        {breadcrumbs ? <Breadcrumbs items={breadcrumbs} className="sui-mb-1" /> : null}
        {variant === PageHeaderVariant.INPUT ? (
          <div className="sui-flex sui-justify-between sui-c-pageheader-input">{children}</div>
        ) : (
          <div className="sui-flex sui-items-center sui-justify-between">
            <div className="sui-flex sui-items-baseline">
              <h1 className="sui-text-darkGray-darker sui-text-h1 sui-font-bold">{title}</h1>
              {subTitle ? (
                <p className="sui-text-lightGray-darkest sui-ml-sm sui-text-b3">{subTitle}</p>
              ) : null}
            </div>
            <>{children}</>
          </div>
        )}
      </div>
    </div>
  );
};

export const PageHeader = React.forwardRef(InnerPageHeader);

import React, { useState } from 'react';
import classNames from 'classnames';

import { IconChevron } from '../Icon';
import { ScrollListArrowDirection as ArrowDirection, TextListItem } from '../../types';

import { ScrollListItem } from './ScrollListItem';

export interface IScrollListProps extends React.HTMLAttributes<HTMLAllCollection> {
  items: ReadonlyArray<TextListItem>;
  maxItemsToShow?: number;
  onSelectItem: (item: TextListItem) => void;
  ariaLabel?: string;
}

type ItemWithId = TextListItem & { id: number };

const ItemList = ({
  items,
  onSelectItem,
  ariaLabel,
}: {
  items: ReadonlyArray<ItemWithId>;
  onSelectItem: (itemId: number) => void;
  ariaLabel?: string;
}) => {
  return (
    <>
      {items.map(item => (
        <ScrollListItem
          key={item.id}
          itemId={item.id}
          label={item.label}
          isSelected={item.isSelected}
          isDisabled={item.isDisabled}
          onClickItem={onSelectItem}
          ariaLabel={ariaLabel}
        />
      ))}
    </>
  );
};

const ArrowButton = ({
  direction,
  isDisabled,
  onClickArrow,
  ariaLabel,
}: {
  direction: ArrowDirection;
  isDisabled?: boolean;
  onClickArrow: (direction: ArrowDirection) => void;
  ariaLabel?: string;
}) => {
  const styleClasses = {
    mainContainer: classNames(
      'sui-w-full sui-h-6 sui-flex sui-items-center sui-justify-center',
      'focus:sui-outline-none sui-border sui-border-transparent',
      { 'hover:sui-bg-lightGray-lightest': !isDisabled },
      'focus:sui-bg-lightGray-lightest focus:sui-border-y-frame-border',
      'active:sui-text-primary',
      {
        'sui-text-lightGray-darkest sui-pointer-events-none': isDisabled,
      }
    ),
    iconWrapper: 'sui-w-4 sui-h-4 sui-flex sui-items-center sui-justify-center',
  };
  return (
    <button
      className={styleClasses.mainContainer}
      disabled={isDisabled}
      onClick={() => onClickArrow(direction)}
      aria-label={ariaLabel}
    >
      <div className={styleClasses.iconWrapper}>
        <IconChevron variant={direction} />
      </div>
    </button>
  );
};

export const ScrollList: React.FC<IScrollListProps> = ({
  items,
  maxItemsToShow = 5,
  onSelectItem,
  ariaLabel = 'items',
}: IScrollListProps) => {
  const itemsWithIds = items.map((item, index) => {
    return { id: index, ...item };
  });
  const [firstItemId, setFirstItemId] = useState<number>(calculateFirstItemId());
  const [listItems, setListItems] = useState<ReadonlyArray<ItemWithId>>(itemsWithIds);
  const shownItems = listItems.slice(firstItemId, firstItemId + maxItemsToShow);
  const maxFirstItemId = listItems.length - maxItemsToShow;
  const arrowsEnabled = { up: firstItemId > 0, down: firstItemId < maxFirstItemId };

  const clickArrowHandler = (direction: ArrowDirection) => {
    setFirstItemId(previousState => {
      const newFirstId = direction === ArrowDirection.UP ? previousState - 1 : previousState + 1;

      return boundIdToList(newFirstId);
    });
  };
  const selectItemHandler = (itemId: number) => {
    setIdAsSelected(itemId);

    const selectedItemWithoutId = getSelectedItemWithoutId(itemId);
    onSelectItem(selectedItemWithoutId);
  };

  function calculateFirstItemId(): number {
    const selectedItems = itemsWithIds.filter(item => item.isSelected);
    if (!selectedItems.length) {
      return 0;
    }
    const firstSelectedItemId = selectedItems[0].id;
    const topItemId = firstSelectedItemId - Math.ceil(maxItemsToShow / 2) + 1;
    return boundIdToList(topItemId);
  }

  function boundIdToList(id: number) {
    if (id < 0) {
      return 0;
    }
    if (id > maxFirstItemId) {
      return maxFirstItemId;
    }
    return id;
  }

  const setIdAsSelected = (itemId: number) => {
    setListItems(previousState => {
      const newItemList = previousState.map(item => {
        return {
          ...item,
          isSelected: item.id === itemId,
        };
      });
      return newItemList;
    });
  };

  const getSelectedItemWithoutId = (itemId: number): TextListItem => {
    let selectedItemWithId: ItemWithId = { id: 0, label: '' };
    for (let i = 0; i < listItems.length; i++) {
      const currentItem = listItems[i];
      if (currentItem.id === itemId) {
        selectedItemWithId = currentItem;
        selectedItemWithId.isSelected = true;
        break;
      }
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { id: _, ...selectedItemWithoutId } = selectedItemWithId;
    return selectedItemWithoutId;
  };

  return (
    <div className="sui-flex sui-flex-col">
      <ArrowButton
        direction={ArrowDirection.UP}
        onClickArrow={clickArrowHandler}
        isDisabled={!arrowsEnabled.up}
        ariaLabel={`${ariaLabel} selector up arrow`}
      />
      <ItemList items={shownItems} onSelectItem={selectItemHandler} ariaLabel={ariaLabel} />
      <ArrowButton
        direction={ArrowDirection.DOWN}
        onClickArrow={clickArrowHandler}
        isDisabled={!arrowsEnabled.down}
        ariaLabel={`${ariaLabel} selector down arrow`}
      />
    </div>
  );
};

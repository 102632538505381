import React from 'react';
import classNames from 'classnames';

import { IconTypes } from '../../../types';

export type IconCalendarProps = {
  isLight?: boolean;
};

export const IconCalendarAlt = ({
  className,
  width,
  height,
  isLight,
  ariaHidden = true,
  isClickable = false,
}: IconTypes & IconCalendarProps): React.ReactElement => {
  const defaultClass = classNames(
    'sui-fill-current sui-icon-calendar-alt',
    { 'sui-pointer-events-none': !isClickable },
    className
  );
  const viewBox = '0 0 24 24';
  const svgWidth = width ? width : 24;
  const svgHeight = height ? height : 24;
  const iconVariant = isLight ? (
    <path d="M17.5 6H16V4.375C16 4.16875 15.8313 4 15.625 4H15.375C15.1687 4 15 4.16875 15 4.375V6H9V4.375C9 4.16875 8.83125 4 8.625 4H8.375C8.16875 4 8 4.16875 8 4.375V6H6.5C5.67188 6 5 6.67188 5 7.5V18.5C5 19.3281 5.67188 20 6.5 20H17.5C18.3281 20 19 19.3281 19 18.5V7.5C19 6.67188 18.3281 6 17.5 6ZM6.5 7H17.5C17.775 7 18 7.225 18 7.5V9H6V7.5C6 7.225 6.225 7 6.5 7ZM17.5 19H6.5C6.225 19 6 18.775 6 18.5V10H18V18.5C18 18.775 17.775 19 17.5 19ZM9.625 14H8.375C8.16875 14 8 13.8313 8 13.625V12.375C8 12.1687 8.16875 12 8.375 12H9.625C9.83125 12 10 12.1687 10 12.375V13.625C10 13.8313 9.83125 14 9.625 14ZM12.625 14H11.375C11.1687 14 11 13.8313 11 13.625V12.375C11 12.1687 11.1687 12 11.375 12H12.625C12.8313 12 13 12.1687 13 12.375V13.625C13 13.8313 12.8313 14 12.625 14ZM15.625 14H14.375C14.1687 14 14 13.8313 14 13.625V12.375C14 12.1687 14.1687 12 14.375 12H15.625C15.8313 12 16 12.1687 16 12.375V13.625C16 13.8313 15.8313 14 15.625 14ZM12.625 17H11.375C11.1687 17 11 16.8313 11 16.625V15.375C11 15.1687 11.1687 15 11.375 15H12.625C12.8313 15 13 15.1687 13 15.375V16.625C13 16.8313 12.8313 17 12.625 17ZM9.625 17H8.375C8.16875 17 8 16.8313 8 16.625V15.375C8 15.1687 8.16875 15 8.375 15H9.625C9.83125 15 10 15.1687 10 15.375V16.625C10 16.8313 9.83125 17 9.625 17ZM15.625 17H14.375C14.1687 17 14 16.8313 14 16.625V15.375C14 15.1687 14.1687 15 14.375 15H15.625C15.8313 15 16 15.1687 16 15.375V16.625C16 16.8313 15.8313 17 15.625 17Z" />
  ) : (
    <path d="M5 18.5C5 19.3281 5.67188 20 6.5 20H17.5C18.3281 20 19 19.3281 19 18.5V10H5V18.5ZM15 12.375C15 12.1687 15.1687 12 15.375 12H16.625C16.8313 12 17 12.1687 17 12.375V13.625C17 13.8313 16.8313 14 16.625 14H15.375C15.1687 14 15 13.8313 15 13.625V12.375ZM15 16.375C15 16.1687 15.1687 16 15.375 16H16.625C16.8313 16 17 16.1687 17 16.375V17.625C17 17.8313 16.8313 18 16.625 18H15.375C15.1687 18 15 17.8313 15 17.625V16.375ZM11 12.375C11 12.1687 11.1687 12 11.375 12H12.625C12.8313 12 13 12.1687 13 12.375V13.625C13 13.8313 12.8313 14 12.625 14H11.375C11.1687 14 11 13.8313 11 13.625V12.375ZM11 16.375C11 16.1687 11.1687 16 11.375 16H12.625C12.8313 16 13 16.1687 13 16.375V17.625C13 17.8313 12.8313 18 12.625 18H11.375C11.1687 18 11 17.8313 11 17.625V16.375ZM7 12.375C7 12.1687 7.16875 12 7.375 12H8.625C8.83125 12 9 12.1687 9 12.375V13.625C9 13.8313 8.83125 14 8.625 14H7.375C7.16875 14 7 13.8313 7 13.625V12.375ZM7 16.375C7 16.1687 7.16875 16 7.375 16H8.625C8.83125 16 9 16.1687 9 16.375V17.625C9 17.8313 8.83125 18 8.625 18H7.375C7.16875 18 7 17.8313 7 17.625V16.375ZM17.5 6H16V4.5C16 4.225 15.775 4 15.5 4H14.5C14.225 4 14 4.225 14 4.5V6H10V4.5C10 4.225 9.775 4 9.5 4H8.5C8.225 4 8 4.225 8 4.5V6H6.5C5.67188 6 5 6.67188 5 7.5V9H19V7.5C19 6.67188 18.3281 6 17.5 6Z" />
  );

  return (
    <svg
      width={svgWidth}
      height={svgHeight}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={defaultClass}
      aria-hidden={ariaHidden}
    >
      {iconVariant}
    </svg>
  );
};

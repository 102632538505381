import React from 'react';
import classNames from 'classnames';

import { ValidationType } from '../../types';
import { Label } from '../Label';

type LabelProps = {
  text: string;
  tooltip?: string;
};
export interface ITextareaProps extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  resize?: 'y' | 'x' | boolean;
  validationType?: ValidationType;
  /**
   * @deprecated `tooltip` will be removed in a future release. Use the `tooltip` key of the `label` object prop instead.
   */
  tooltip?: string;
  /**
   * @deprecated `label` as a `string` type will be removed in a future release. Use the `text` key of the `label` object prop instead.
   */
  label?: string | LabelProps;
  /**
   * @deprecated `isTelus` will be removed in a future release.
   */
  isTelus?: boolean;
}

const InnerTextarea = (
  {
    className,
    rows = 5,
    validationType = ValidationType.DEFAULT,
    resize,
    disabled,
    label,
    tooltip,
    required,
    id,
    ...props
  }: ITextareaProps,
  ref: React.Ref<HTMLTextAreaElement>
) => {
  let borderColor =
    'sui-border-lightGray hover:sui-border-lightGray-darkest focus:sui-border-primary focus:sui-shadow-borderActive';
  if (validationType === ValidationType.INFO) {
    borderColor =
      'sui-border-info hover:sui-border-info-dark focus:sui-border-info-dark focus:sui-shadow-borderInfo';
  }
  if (validationType === ValidationType.WARNING) {
    borderColor =
      'sui-border-warning hover:sui-border-warning-dark focus:sui-border-warning-dark focus:sui-shadow-borderWarning';
  }
  if (validationType === ValidationType.SUCCESS) {
    borderColor =
      'sui-border-success hover:sui-border-success-dark focus:sui-border-success-dark focus:sui-shadow-borderActive';
  }
  if (validationType === ValidationType.ERROR) {
    borderColor =
      'sui-border-danger hover:sui-border-danger-dark focus:sui-border-danger-dark focus:sui-shadow-borderDanger';
  }

  const defaultStyle = classNames(
    'sui-border sui-w-full sui-rounded sui-p-4 sui-placeholder-lightGray-darkest sui-text-darkGray-darker sui-c-textarea',
    'focus:sui-outline-none',
    disabled
      ? 'sui-border-lightGray sui-bg-lightGray-lighter sui-text-lightGray-darkest'
      : classNames(borderColor, 'sui-bg-white'),
    resize === true ? 'sui-resize' : 'sui-resize-none',
    { 'sui-resize-y': resize === 'y', 'sui-resize-x': resize === 'x' },
    className
  );

  const labelText = typeof label === 'object' ? label.text : label;

  const labelTooltip = typeof label === 'object' && label.tooltip;

  const hasLabel = (typeof label === 'object' && label.text) || label;

  return (
    <>
      {hasLabel && (
        <Label required={required} tooltip={labelTooltip || tooltip} isDescriptive={true}>
          {labelText}
        </Label>
      )}
      <div className="sui-relative">
        <textarea
          aria-disabled={disabled}
          aria-required={required}
          disabled={disabled}
          required={required}
          className={defaultStyle}
          rows={rows}
          id={id}
          ref={ref}
          {...props}
        />
      </div>
    </>
  );
};

export const Textarea = React.forwardRef(InnerTextarea);

import React from 'react';
import classNames from 'classnames';

import { IconTypes } from '../../../types';

type IconEllipsisProps = {
  variant?: 'horizontal' | 'horizontal-alt' | 'vertical' | 'vertical-alt';
};

export const IconEllipsis = ({
  className,
  width,
  height,
  variant = 'horizontal',
  ariaHidden = true,
  isClickable = false,
}: IconTypes & IconEllipsisProps): React.ReactElement => {
  const defaultClass = classNames(
    'sui-fill-current',
    { 'sui-pointer-events-none': !isClickable },
    className
  );
  let viewBox = '0 0 16 6';
  let svgWidth = width ? width : 16;
  let svgHeight = height ? height : 6;
  let iconVariant = (
    // Horizontal
    <path d="M10.3226 3.00007C10.3226 4.28394 9.28387 5.32265 8 5.32265C6.71613 5.32265 5.67742 4.28394 5.67742 3.00007C5.67742 1.7162 6.71613 0.67749 8 0.67749C9.28387 0.67749 10.3226 1.7162 10.3226 3.00007ZM13.6774 0.67749C12.3935 0.67749 11.3548 1.7162 11.3548 3.00007C11.3548 4.28394 12.3935 5.32265 13.6774 5.32265C14.9613 5.32265 16 4.28394 16 3.00007C16 1.7162 14.9613 0.67749 13.6774 0.67749ZM2.32258 0.67749C1.03871 0.67749 0 1.7162 0 3.00007C0 4.28394 1.03871 5.32265 2.32258 5.32265C3.60645 5.32265 4.64516 4.28394 4.64516 3.00007C4.64516 1.7162 3.60645 0.67749 2.32258 0.67749Z" />
  );

  if (variant === 'horizontal-alt') {
    viewBox = '0 0 16 6';
    svgWidth = width ? width : 16;
    svgHeight = height ? height : 6;
    iconVariant = (
      <path d="M8 2.54846C8.24839 2.54846 8.45161 2.75168 8.45161 3.00007C8.45161 3.24846 8.24839 3.45168 8 3.45168C7.75161 3.45168 7.54839 3.24846 7.54839 3.00007C7.54839 2.75168 7.75161 2.54846 8 2.54846ZM8 0.67749C6.71613 0.67749 5.67742 1.7162 5.67742 3.00007C5.67742 4.28394 6.71613 5.32265 8 5.32265C9.28387 5.32265 10.3226 4.28394 10.3226 3.00007C10.3226 1.7162 9.28387 0.67749 8 0.67749ZM13.6774 2.54846C13.9258 2.54846 14.129 2.75168 14.129 3.00007C14.129 3.24846 13.9258 3.45168 13.6774 3.45168C13.429 3.45168 13.2258 3.24846 13.2258 3.00007C13.2258 2.75168 13.429 2.54846 13.6774 2.54846ZM13.6774 0.67749C12.3935 0.67749 11.3548 1.7162 11.3548 3.00007C11.3548 4.28394 12.3935 5.32265 13.6774 5.32265C14.9613 5.32265 16 4.28394 16 3.00007C16 1.7162 14.9613 0.67749 13.6774 0.67749ZM2.32258 2.54846C2.57097 2.54846 2.77419 2.75168 2.77419 3.00007C2.77419 3.24846 2.57097 3.45168 2.32258 3.45168C2.07419 3.45168 1.87097 3.24846 1.87097 3.00007C1.87097 2.75168 2.07419 2.54846 2.32258 2.54846ZM2.32258 0.67749C1.03871 0.67749 0 1.7162 0 3.00007C0 4.28394 1.03871 5.32265 2.32258 5.32265C3.60645 5.32265 4.64516 4.28394 4.64516 3.00007C4.64516 1.7162 3.60645 0.67749 2.32258 0.67749Z" />
    );
  }

  if (variant === 'vertical') {
    viewBox = '0 0 6 16';
    svgWidth = width ? width : 6;
    svgHeight = height ? height : 16;
    iconVariant = (
      <path d="M2.99999 5.67742C4.28387 5.67742 5.32258 6.71613 5.32258 8C5.32258 9.28387 4.28387 10.3226 2.99999 10.3226C1.71612 10.3226 0.677414 9.28387 0.677414 8C0.677414 6.71613 1.71612 5.67742 2.99999 5.67742ZM0.677414 2.32258C0.677414 3.60645 1.71612 4.64516 2.99999 4.64516C4.28387 4.64516 5.32258 3.60645 5.32258 2.32258C5.32258 1.03871 4.28387 0 2.99999 0C1.71612 0 0.677414 1.03871 0.677414 2.32258ZM0.677414 13.6774C0.677414 14.9613 1.71612 16 2.99999 16C4.28387 16 5.32258 14.9613 5.32258 13.6774C5.32258 12.3935 4.28387 11.3548 2.99999 11.3548C1.71612 11.3548 0.677414 12.3935 0.677414 13.6774Z" />
    );
  }

  if (variant === 'vertical-alt') {
    viewBox = '0 0 6 16';
    svgWidth = width ? width : 6;
    svgHeight = height ? height : 16;
    iconVariant = (
      <path d="M3 7.54839C3.24839 7.54839 3.45162 7.75161 3.45162 8C3.45162 8.24839 3.24839 8.45161 3 8.45161C2.75162 8.45161 2.54839 8.24839 2.54839 8C2.54839 7.75161 2.75162 7.54839 3 7.54839ZM3 5.67742C1.71613 5.67742 0.677422 6.71613 0.677422 8C0.677422 9.28387 1.71613 10.3226 3 10.3226C4.28387 10.3226 5.32258 9.28387 5.32258 8C5.32258 6.71613 4.28387 5.67742 3 5.67742ZM3 1.87097C3.24839 1.87097 3.45162 2.07419 3.45162 2.32258C3.45162 2.57097 3.24839 2.77419 3 2.77419C2.75162 2.77419 2.54839 2.57097 2.54839 2.32258C2.54839 2.07419 2.75162 1.87097 3 1.87097ZM3 0C1.71613 0 0.677422 1.03871 0.677422 2.32258C0.677422 3.60645 1.71613 4.64516 3 4.64516C4.28387 4.64516 5.32258 3.60645 5.32258 2.32258C5.32258 1.03871 4.28387 0 3 0ZM3 13.2258C3.24839 13.2258 3.45162 13.429 3.45162 13.6774C3.45162 13.9258 3.24839 14.129 3 14.129C2.75162 14.129 2.54839 13.9258 2.54839 13.6774C2.54839 13.429 2.75162 13.2258 3 13.2258ZM3 11.3548C1.71613 11.3548 0.677422 12.3935 0.677422 13.6774C0.677422 14.9613 1.71613 16 3 16C4.28387 16 5.32258 14.9613 5.32258 13.6774C5.32258 12.3935 4.28387 11.3548 3 11.3548Z" />
    );
  }
  return (
    <svg
      width={svgWidth}
      height={svgHeight}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={defaultClass}
      aria-hidden={ariaHidden}
    >
      {iconVariant}
    </svg>
  );
};

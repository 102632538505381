import React, { useEffect, useState, useRef } from 'react';
import classNames from 'classnames';

import { DatePickerSelectionMode, DatePickerViewVariant, DateRange } from '../../types';
import {
  MONTHS_IN_A_YEAR,
  currentFirstOfMonthDate,
  getFirstOfXMonthsLater,
  getModulo,
  isBoundedRange,
} from '../../lib/DateUtils';
import { Button } from '../Button';

import { Calendar } from './Calendar';

export interface IDatePickerProps extends React.HTMLAttributes<HTMLAllCollection> {
  isMondayBased?: boolean;
  selectionMode?: DatePickerSelectionMode;
  viewVariant?: DatePickerViewVariant;
  preSelectedDateRange?: DateRange;
  disabledRanges?: ReadonlyArray<DateRange>;
  onSelectDate: (dateRange: DateRange) => void;
  onClickConfirmSelection?: () => void;
}

export type DisplayedMonths = { left: Date; right: Date };
export type CalendarSide = 'left' | 'right';
export type HeaderArrowDirection = 'left' | 'right';

const SingleCalendar = ({
  isMondayBased,
  preSelectedDateRange = { start: null, end: null },
  disabledRanges,
  onSelectDate,
}: {
  isMondayBased: boolean;
  preSelectedDateRange: DateRange;
  disabledRanges: ReadonlyArray<DateRange>;
  onSelectDate: (date: Date) => void;
}) => {
  const props = {
    isMondayBased,
    selectedDateRange: preSelectedDateRange,
    disabledRanges,
    onSelectDate,
  };
  const initialMonthToDisplay = isBoundedRange(preSelectedDateRange)
    ? preSelectedDateRange.start
    : currentFirstOfMonthDate;
  const [displayedMonth, setDisplayedMonth] = useState<Date>(initialMonthToDisplay);

  const clickArrowHandler = (direction: HeaderArrowDirection) => {
    setDisplayedMonth(previousState => {
      const monthToMove = direction === 'left' ? -1 : 1;
      return getFirstOfXMonthsLater(previousState, monthToMove);
    });
  };

  return (
    <Calendar
      {...props}
      displayedMonth={displayedMonth}
      onClickArrows={clickArrowHandler}
      onSelectMonth={setDisplayedMonth}
      onSelectYear={setDisplayedMonth}
    />
  );
};

const DoubleCalendar = ({
  isMondayBased,
  preSelectedDateRange = { start: null, end: null },
  disabledRanges,
  onSelectDate,
}: {
  isMondayBased: boolean;
  preSelectedDateRange: DateRange;
  disabledRanges: ReadonlyArray<DateRange>;
  onSelectDate: (date: Date) => void;
}) => {
  const props = {
    isMondayBased,
    selectedDateRange: preSelectedDateRange,
    disabledRanges,
    onSelectDate,
  };

  let monthToDisplayOnLeft = currentFirstOfMonthDate;
  let monthToDisplayOnRight = getFirstOfXMonthsLater(currentFirstOfMonthDate, 1);
  if (isBoundedRange(preSelectedDateRange)) {
    monthToDisplayOnLeft = preSelectedDateRange.start;
    monthToDisplayOnRight = preSelectedDateRange.end;
    if (monthToDisplayOnLeft.getMonth() === monthToDisplayOnRight.getMonth()) {
      monthToDisplayOnRight = getFirstOfXMonthsLater(monthToDisplayOnLeft, 1);
    }
  }
  const [displayedMonths, setDisplayedMonths] = useState<DisplayedMonths>({
    left: monthToDisplayOnLeft,
    right: monthToDisplayOnRight,
  });

  const arrowClickHandler = (side: CalendarSide, direction: HeaderArrowDirection) => {
    const sideToChange = displayedMonths[side];
    const amountToChange = direction === 'left' ? -1 : 1;
    const firstOfTargetMonth = getFirstOfXMonthsLater(sideToChange, amountToChange);

    setNewDisplayedMonths(side, firstOfTargetMonth, 1);
  };

  const selectYearHandler = (side: CalendarSide, firstOfMonthOfSelectedYear: Date) => {
    const leftSideMonthIndex = displayedMonths.left.getMonth();
    const rightSideMonthIndex = displayedMonths.right.getMonth();
    const minimumDistanceBetweenSidesInMonths = getModulo(
      rightSideMonthIndex - leftSideMonthIndex,
      MONTHS_IN_A_YEAR
    );

    setNewDisplayedMonths(side, firstOfMonthOfSelectedYear, minimumDistanceBetweenSidesInMonths);
  };

  const setNewDisplayedMonths = (
    clickedSide: CalendarSide,
    selectedMonthOrYear: Date,
    numberOfMonthsToPushBy: number
  ) => {
    setDisplayedMonths(previousState => {
      let { left, right } = previousState;

      if (clickedSide === 'left') {
        left = selectedMonthOrYear;
      }
      if (clickedSide === 'right') {
        right = selectedMonthOrYear;
      }
      if (clickedSide === 'left' && selectedMonthOrYear >= right) {
        right = getFirstOfXMonthsLater(left, numberOfMonthsToPushBy);
      }
      if (clickedSide === 'right' && selectedMonthOrYear <= left) {
        left = getFirstOfXMonthsLater(right, -numberOfMonthsToPushBy);
      }

      return { left, right };
    });
  };

  return (
    <>
      <Calendar
        {...props}
        displayedMonth={displayedMonths.left}
        side={'left'}
        onClickArrows={(direction: HeaderArrowDirection) => arrowClickHandler('left', direction)}
        onSelectMonth={(firstOfSelectedMonth: Date) =>
          setNewDisplayedMonths('left', firstOfSelectedMonth, 1)
        }
        onSelectYear={(year: Date) => selectYearHandler('left', year)}
      />
      <Calendar
        {...props}
        displayedMonth={displayedMonths.right}
        side={'right'}
        onClickArrows={(direction: HeaderArrowDirection) => arrowClickHandler('right', direction)}
        onSelectMonth={(firstOfSelectedMonth: Date) =>
          setNewDisplayedMonths('right', firstOfSelectedMonth, 1)
        }
        onSelectYear={(year: Date) => selectYearHandler('right', year)}
      />
    </>
  );
};

export const DatePicker: React.FC<IDatePickerProps> = ({
  isMondayBased = false,
  selectionMode = DatePickerSelectionMode.SINGLE_DATE,
  viewVariant = DatePickerViewVariant.SINGLE,
  preSelectedDateRange = { start: null, end: null },
  disabledRanges = [],
  onClickConfirmSelection,
  onSelectDate,
}: IDatePickerProps) => {
  const isMounted = useRef(false);
  const [selectedDateRange, setSelectedDateRange] = useState<DateRange>(preSelectedDateRange);

  const selectDateHandler = (date: Date) => {
    const oneDateAlreadySelected = selectedDateRange.start && !selectedDateRange.end;
    const twoDatesAlreadySelected = selectedDateRange.start && selectedDateRange.end;
    const noDatesAlreadySelected = !selectedDateRange.start && !selectedDateRange.end;

    if (selectionMode === DatePickerSelectionMode.SINGLE_DATE) {
      setSelectedDateRange({ start: date, end: date });
      return;
    }

    if (noDatesAlreadySelected || twoDatesAlreadySelected) {
      setSelectedDateRange({ start: date, end: null });
      return;
    }

    if (oneDateAlreadySelected) {
      if (selectedDateRange.start && selectedDateRange.start < date) {
        setSelectedDateRange(previousState => {
          return { start: previousState.start, end: date };
        });
      } else {
        setSelectedDateRange(previousState => {
          return { start: date, end: previousState.start };
        });
      }
    }
  };

  useEffect(() => {
    if (isMounted.current) {
      isBoundedRange(selectedDateRange) && onSelectDate(selectedDateRange);
    } else {
      isMounted.current = true;
    }
  }, [selectedDateRange, onSelectDate]);

  const styleClasses = {
    mainContainer: 'sui-inline-block sui-u-shadow-telus sui-bg-white sui-rounded-sm',
    calendarContainer: 'sui-flex',
    confirmSelectionWrapper: classNames(
      'sui-p-4 sui-flex sui-justify-end',
      'sui-border sui-border-transparent sui-border-t-lightGray'
    ),
  };

  const props = {
    isMondayBased,
    preSelectedDateRange: selectedDateRange,
    disabledRanges,
    onSelectDate: selectDateHandler,
  };

  return (
    <div className={styleClasses.mainContainer}>
      <div className={styleClasses.calendarContainer}>
        {viewVariant === DatePickerViewVariant.SINGLE ? (
          <SingleCalendar {...props} />
        ) : (
          <DoubleCalendar {...props} />
        )}
      </div>
      {onClickConfirmSelection && (
        <div className={styleClasses.confirmSelectionWrapper}>
          <Button onClick={onClickConfirmSelection}>Apply</Button>
        </div>
      )}
    </div>
  );
};

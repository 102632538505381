import React from 'react';
import classNames from 'classnames';

import { InputVariant, ValidationType } from '../../types';
import { IconCheck, IconExclamation, IconTimes } from '../Icon';
import { Label } from '../Label';

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  clearField?: () => void;
  variant?: InputVariant;
  validationType?: ValidationType;
  validationIcon?: boolean;
  label?: string;
  tooltip?: React.ReactChild;
  prefixIcon?: React.ReactElement;
  isTelus?: boolean;
}

const InnerInput = (
  {
    className,
    placeholder,
    disabled,
    variant = InputVariant.SIMPLE,
    validationType,
    validationIcon,
    label,
    clearField,
    required,
    tooltip,
    id,
    prefixIcon,
    isTelus,
    ...props
  }: InputProps,
  ref: React.Ref<HTMLInputElement>
) => {
  let borderColor =
    'sui-border-lightGray hover:sui-border-lightGray-darkest focus:sui-border-primary';
  let iconType = <IconExclamation variant="circle" />;
  let iconColor = 'sui-text-info';
  let labelColor = 'sui-text-lightGray-darker';
  if (validationType === ValidationType.INFO) {
    borderColor = 'sui-border-info hover:sui-border-info focus:sui-border-info';
    labelColor = 'sui-text-info';
    className = 'sui-c-input-info';
  }
  if (validationType === ValidationType.WARNING) {
    borderColor =
      'sui-border-warning hover:sui-border-warning focus:sui-border-warning focus:sui-shadow-borderWarning';
    iconColor = 'sui-text-warning';
    labelColor = 'sui-text-warning';
    className = 'sui-c-input-warning';
  }
  if (validationType === ValidationType.SUCCESS) {
    borderColor =
      'sui-border-success hover:sui-border-success focus:sui-border-success focus:sui-shadow-borderSuccess';
    iconType = <IconCheck variant="circle" />;
    iconColor = 'sui-text-success';
    labelColor = 'sui-text-success';
    className = 'sui-c-input-success';
  }
  if (validationType === ValidationType.ERROR) {
    borderColor =
      'sui-border-danger hover:sui-border-danger focus:sui-border-danger focus:sui-shadow-borderDanger';
    iconColor = 'sui-text-danger';
    labelColor = 'sui-text-danger';
    iconType = <IconExclamation variant="triangle" />;
    className = 'sui-c-input-error';
  }

  const iconClass = classNames('sui-absolute sui-right-3 sui-u-input-icon', iconColor);
  const prefixIconClass = classNames(
    'sui-absolute sui-left-2 sui-h-6 sui-w-6 sui-flex sui-items-center sui-justify-center',
    {
      'sui-z-10 sui-u-input-prefix-icon':
        variant === InputVariant.LABEL || !isTelus || (variant === InputVariant.SEARCH && !isTelus),

      'sui-u-input-prefix-icon': variant === InputVariant.SEARCH && !isTelus,
      'sui-h-full': variant === InputVariant.SEARCH && isTelus,
    }
  );
  const defaultStyle = classNames(
    className,
    { 'sui-pr-10': validationIcon || (variant === InputVariant.SEARCH && !isTelus) },
    'focus:sui-outline-none focus:sui-shadow-borderFocus',
    disabled
      ? classNames(
          'sui-border-lightGray sui-text-lightGray-darkest',
          variant === InputVariant.TEXT || variant === InputVariant.MULTI_STRING
            ? 'sui-bg-white'
            : 'sui-bg-lightGray-lighter',
          { 'sui-px-10': prefixIcon }
        )
      : classNames(borderColor, 'sui-bg-white'),
    variant === InputVariant.TEXT || variant === InputVariant.MULTI_STRING
      ? classNames(
          'focus:sui-shadow-noBorder',
          isTelus
            ? classNames(
                'sui-placeholder-lightGray-darkest sui-font-heading sui-text-h1 sui-font-bold sui-flex-1',
                'hover:sui-bg-lightGray focus:sui-bg-white'
              )
            : classNames('sui-placeholder-lightGray', {
                'sui-text-b5': variant === InputVariant.MULTI_STRING,
              })
        )
      : classNames(
          'sui-font-normal sui-border sui-rounded sui-w-full sui-placeholder-lightGray-darkest sui-text-darkGray-darker',
          variant === InputVariant.TELUS || (variant === InputVariant.SEARCH && isTelus)
            ? 'sui-h-8 sui-px-4 sui-py-1.5'
            : 'sui-h-14 sui-p-base',
          { 'sui-px-10': prefixIcon }
        )
  );

  const floatingLabelStyle = classNames(
    'sui-flex sui-w-full sui-relative',
    placeholder ? 'sui-c-input-placeholder' : 'sui-c-input',
    { 'sui-c-input-prefix-icon': prefixIcon }
  );
  const clearIconStyle = classNames(
    'sui-text-darkGray-darkest',
    disabled ? 'sui-opacity-50' : 'sui-cursor-pointer'
  );
  const defaultInput = (
    <input
      aria-disabled={disabled}
      aria-label={placeholder}
      aria-required={required}
      className={defaultStyle}
      disabled={disabled}
      placeholder={placeholder}
      id={id}
      ref={ref}
      {...props}
    />
  );
  return (
    <>
      {variant === InputVariant.SIMPLE &&
        (validationIcon ? (
          <div className="sui-flex sui-w-full sui-relative">
            {defaultInput}
            <span className={iconClass}>{iconType}</span>
          </div>
        ) : (
          <div className="sui-flex sui-w-full sui-relative">
            {prefixIcon && <span className={prefixIconClass}>{prefixIcon}</span>}
            {defaultInput}
          </div>
        ))}

      {variant === InputVariant.LABEL && (
        <div className="sui-flex sui-w-full sui-relative">
          {prefixIcon && <span className={prefixIconClass}>{prefixIcon}</span>}
          <div className={floatingLabelStyle}>
            {label && (
              <label className="sui-w-full">
                <input
                  aria-disabled={disabled}
                  aria-label={label}
                  aria-required={required}
                  required={required}
                  disabled={disabled}
                  className={defaultStyle}
                  placeholder={placeholder ? placeholder : label}
                  {...props}
                />
                <span className={labelColor}>{label}</span>
              </label>
            )}
            {validationIcon && <span className={iconClass}>{iconType}</span>}
          </div>
        </div>
      )}
      {variant === InputVariant.SEARCH && (
        <>
          {isTelus ? (
            <>
              {label && (
                <Label tooltip={tooltip} required={required} htmlFor={id}>
                  {label}
                </Label>
              )}
              <div className="sui-w-full sui-flex sui-relative">
                {prefixIcon && (
                  <span className={prefixIconClass}>
                    <span>{prefixIcon}</span>
                  </span>
                )}
                {defaultInput}
                {clearField && props.value && (
                  <span
                    className={classNames(
                      'sui-absolute sui-right-3 sui-u-input-clear-icon sui-cursor-pointer',
                      { 'sui-hidden': !props.value }
                    )}
                    onClick={clearField}
                  >
                    <IconTimes className={clearIconStyle} />
                  </span>
                )}

                {validationIcon && <span className={iconClass}>{iconType}</span>}
              </div>
            </>
          ) : (
            <div className="sui-flex sui-w-full sui-relative">
              {prefixIcon && <span className={prefixIconClass}>{prefixIcon}</span>}
              {label ? (
                <div className={floatingLabelStyle}>
                  <label className="sui-w-full">
                    <input
                      aria-disabled={disabled}
                      aria-label={label}
                      aria-required={required}
                      required={required}
                      disabled={disabled}
                      className={defaultStyle}
                      placeholder={placeholder ? placeholder : label}
                      {...props}
                    />
                    <span className={labelColor}>{label}</span>
                  </label>
                  {validationIcon && <span className={iconClass}>{iconType}</span>}
                </div>
              ) : (
                defaultInput
              )}
              {clearField && props.value && (
                <span
                  className={classNames('sui-absolute sui-right-3 sui-u-input-clear-icon', {
                    'sui-hidden': !props.value,
                  })}
                  onClick={clearField}
                >
                  <IconTimes className={clearIconStyle} />
                </span>
              )}
            </div>
          )}
        </>
      )}
      {variant === InputVariant.TEXT &&
        (isTelus ? (
          <>
            {label && (
              <Label tooltip={tooltip} required={required} htmlFor={id}>
                {label}
              </Label>
            )}
            <div className="sui-w-full sui-flex sui-relative">
              {defaultInput}
              {validationIcon && <span className={iconClass}>{iconType}</span>}
            </div>
          </>
        ) : validationIcon ? (
          <div className="sui-w-full sui-flex sui-relative">
            {defaultInput}
            <span className={iconClass}>{iconType}</span>
          </div>
        ) : (
          defaultInput
        ))}

      {variant === InputVariant.TELUS && (
        <>
          {label && (
            <Label tooltip={tooltip} required={required} htmlFor={id}>
              {label}
            </Label>
          )}
          <div className="sui-w-full sui-flex sui-relative">
            {defaultInput}
            {validationIcon && <span className={iconClass}>{iconType}</span>}
          </div>
        </>
      )}

      {variant === InputVariant.MULTI_STRING &&
        (isTelus ? (
          <>
            {label && (
              <Label tooltip={tooltip} required={required} htmlFor={id}>
                {label}
              </Label>
            )}
            <div className="sui-w-full sui-flex sui-relative">
              {defaultInput}
              {validationIcon && <span className={iconClass}>{iconType}</span>}
            </div>
          </>
        ) : validationIcon ? (
          <div className="sui-w-full sui-flex sui-relative">
            {defaultInput}
            <span className={iconClass}>{iconType}</span>
          </div>
        ) : (
          defaultInput
        ))}
    </>
  );
};

export const Input = React.forwardRef(InnerInput);

import React from 'react';
import classNames from 'classnames';

import { IconTypes } from '../../../types';

type IIconItalicProps = {
  isLight?: boolean;
};

export const IconItalic = ({
  className,
  width = 24,
  height = 24,
  isLight,
  ariaHidden = true,
  isClickable = false,
}: IconTypes & IIconItalicProps): React.ReactElement => {
  const defaultClass = classNames(
    'sui-fill-current',
    { 'sui-pointer-events-none': !isClickable },
    className
  );

  const iconVariant = isLight ? (
    <path d="M17.7142 4.28571V4.85714C17.7142 4.93292 17.6841 5.00559 17.6305 5.05917C17.577 5.11276 17.5043 5.14286 17.4285 5.14286H14.3035L10.8749 18.8571H13.9999C14.0757 18.8571 14.1484 18.8872 14.202 18.9408C14.2555 18.9944 14.2856 19.0671 14.2856 19.1429V19.7143C14.2856 19.7901 14.2555 19.8627 14.202 19.9163C14.1484 19.9699 14.0757 20 13.9999 20H6.57136C6.49558 20 6.42291 19.9699 6.36933 19.9163C6.31575 19.8627 6.28564 19.7901 6.28564 19.7143V19.1429C6.28564 19.0671 6.31575 18.9944 6.36933 18.9408C6.42291 18.8872 6.49558 18.8571 6.57136 18.8571H9.69636L13.1249 5.14286H9.99993C9.92415 5.14286 9.85148 5.11276 9.7979 5.05917C9.74432 5.00559 9.71422 4.93292 9.71422 4.85714V4.28571C9.71422 4.20994 9.74432 4.13727 9.7979 4.08368C9.85148 4.0301 9.92415 4 9.99993 4H17.4285C17.5043 4 17.577 4.0301 17.6305 4.08368C17.6841 4.13727 17.7142 4.20994 17.7142 4.28571Z" />
  ) : (
    <path d="M17.7142 4.57143V5.71429C17.7142 5.86584 17.654 6.01118 17.5468 6.11835C17.4397 6.22551 17.2943 6.28571 17.1428 6.28571H14.9014L12.0442 17.7143H13.7142C13.8658 17.7143 14.0111 17.7745 14.1183 17.8817C14.2254 17.9888 14.2856 18.1342 14.2856 18.2857V19.4286C14.2856 19.5801 14.2254 19.7255 14.1183 19.8326C14.0111 19.9398 13.8658 20 13.7142 20H6.85707C6.70552 20 6.56018 19.9398 6.45301 19.8326C6.34585 19.7255 6.28564 19.5801 6.28564 19.4286V18.2857C6.28564 18.1342 6.34585 17.9888 6.45301 17.8817C6.56018 17.7745 6.70552 17.7143 6.85707 17.7143H9.0985L11.9556 6.28571H10.2856C10.1341 6.28571 9.98875 6.22551 9.88158 6.11835C9.77442 6.01118 9.71422 5.86584 9.71422 5.71429V4.57143C9.71422 4.41988 9.77442 4.27453 9.88158 4.16737C9.98875 4.0602 10.1341 4 10.2856 4H17.1428C17.2943 4 17.4397 4.0602 17.5468 4.16737C17.654 4.27453 17.7142 4.41988 17.7142 4.57143Z" />
  );
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={defaultClass}
      aria-hidden={ariaHidden}
    >
      {iconVariant}
    </svg>
  );
};

import React from 'react';
import classNames from 'classnames';

import { DateRange } from '../../types';
import {
  currentMonthIndex,
  currentYear,
  getLastDayOfMonth,
  isBoundedRange,
  monthNamesLong,
  monthNamesShort,
} from '../../lib/DateUtils';

import { CalendarSide } from './DatePicker';

export interface IMonthProps extends React.HTMLAttributes<HTMLAllCollection> {
  monthIndex: number;
  displayedYear: number;
  selectedRange: DateRange;
  disabledRanges: ReadonlyArray<DateRange>;
  side?: CalendarSide;
  onSelectMonth: (firstOfMonth: Date) => void;
}

export const Month: React.FC<IMonthProps> = ({
  monthIndex,
  displayedYear,
  selectedRange,
  disabledRanges,
  side,
  onSelectMonth,
}: IMonthProps) => {
  const isCurrentMonth = displayedYear === currentYear && currentMonthIndex === monthIndex;
  const firstOfMonth = new Date(displayedYear, monthIndex, 1);
  const lastOfMonth = getLastDayOfMonth(firstOfMonth);
  let isInSelectedRange = false;
  if (isBoundedRange(selectedRange)) {
    isInSelectedRange = selectedRange.start <= lastOfMonth && firstOfMonth <= selectedRange.end;
  }

  const isInDisabledRange = disabledRanges.some(range => {
    const startCondition = range.start ? range.start <= firstOfMonth : true;
    const endCondition = range.end ? lastOfMonth <= range.end : true;

    if (!startCondition && !endCondition) {
      return;
    }

    return startCondition && endCondition;
  });

  const sideLabel = side ? `${side} side ` : '';

  const styleClasses = {
    monthLabel: classNames(
      'sui-flex sui-items-center sui-justify-center',
      'sui-w-14 sui-h-14 sui-text-b3 sui-rounded-full sui-border sui-border-transparent',
      'hover:sui-underline',
      'active:sui-border-lightGray',
      'focus:sui-border-lightGray focus:sui-outline-none',
      {
        'sui-text-primary sui-border-primary': isCurrentMonth && !isInSelectedRange,
      },
      { 'sui-text-white sui-bg-primary': isInSelectedRange },
      { 'sui-text-lightGray sui-pointer-events-none': isInDisabledRange && !isInSelectedRange },
      {
        'sui-text-primary-light sui-bg-primary-lighter sui-pointer-events-none':
          isInDisabledRange && isInSelectedRange,
      }
    ),
  };

  const monthNameShort = monthNamesShort[monthIndex];
  const monthNameLong = monthNamesLong[monthIndex];

  return (
    <button
      className={styleClasses.monthLabel}
      aria-label={`${sideLabel}${monthNameLong}, ${displayedYear}`}
      onClick={() => onSelectMonth(firstOfMonth)}
      disabled={isInDisabledRange}
    >
      {monthNameShort}
    </button>
  );
};

import React from 'react';
import classNames from 'classnames';

import { ButtonSize } from '../../types';

export interface IAccordionsProps extends React.HTMLAttributes<HTMLDivElement> {
  active?: number;
  size?: ButtonSize;
  className?: string;
}

export const Accordions: React.FC<IAccordionsProps> = ({
  className,
  children,
  size = ButtonSize.LARGE,
  active = -1,
}: IAccordionsProps) => {
  const styleClasses = {
    contentSize: classNames({
      'sui-text-b3': size === ButtonSize.LARGE,
      'sui-text-b4': size === ButtonSize.MEDIUM,
      'sui-text-b5': size === ButtonSize.SMALL,
    }),
    contentPadding: classNames({
      'sui-px-3 sui-pt-3 sui-pb-6': size === ButtonSize.LARGE,
      'sui-px-3 sui-pt-2 sui-pb-6': size === ButtonSize.MEDIUM,
      'sui-pt-2 sui-pb-4 sui-pl-2 sui-pr-4': size === ButtonSize.SMALL,
    }),
  };
  const [toggle, setToggle] = React.useState(active);

  return (
    <div className={className}>
      {React.Children.map(children, (child, index) => {
        if (React.isValidElement(child)) {
          return React.cloneElement(child, {
            ...child.props,
            key: index,
            index: index,
            title: child.props.title,
            setSelectedAccordion: setToggle,
            active: child.props.disabled ? -1 : toggle,
            disabled: child.props.disabled,
            size: size,
            contentClass: classNames(
              'sui-font-regular',
              styleClasses.contentSize,
              styleClasses.contentPadding
            ),
            content: child.props.content,
          });
        }
        return null;
      })}
    </div>
  );
};

import React from 'react';

import { ButtonSize } from '../../types';
import { Avatar } from '../Avatar';
import { Dropdown, DropdownItem } from '../Dropdown';
import { ITextLinkProps } from '../TextLink';
import { UncontrolledTooltip } from '../Tooltip';

export type DropdownListProps = ReadonlyArray<{
  label: string;
  icon: React.ReactElement;
  isLink?: ITextLinkProps;
  isGroup?: React.ReactNode;
  isHidden?: boolean;
}>;

export type UserDetailsProps = Readonly<{
  name: string;
  initials: string;
  email: string;
  userID?: string;
}>;

export interface IHeaderUserProfileProps extends React.HTMLAttributes<HTMLDivElement> {
  userDetails: UserDetailsProps;
  dropdownList: DropdownListProps;
}

export const HeaderUserProfile: React.FC<IHeaderUserProfileProps> = ({
  userDetails,
  dropdownList,
}: IHeaderUserProfileProps) => {
  return (
    <div className="sui-nav-settings">
      <Dropdown
        offset={{ horizontal: 0, vertical: 4 }}
        titleElement={<Avatar initials={userDetails.initials} type="medium" />}
        isUserProfile
        isTelus
      >
        <div className="sui-pb-4">
          <div className="sui-pt-6 sui-px-5">
            <div className="sui-px-1">
              <UncontrolledTooltip isDescriptive isTruncated content={userDetails.name}>
                <div className="sui-text-ellipsis sui-truncate sui-font-bold sui-text-b3">
                  {userDetails.name}
                </div>
              </UncontrolledTooltip>
              <UncontrolledTooltip isDescriptive isTruncated content={userDetails.email}>
                <div className="sui-text-ellipsis sui-truncate sui-text-b4 sui-text-darkGray-lighter">
                  {userDetails.email}
                </div>
              </UncontrolledTooltip>
              {userDetails.userID && (
                <div className=" sui-text-b4 sui-mt-4">
                  <div className="sui-text-darkGray sui-font-medium">Unique Identifier:</div>
                  <div className="sui-mt-1 sui-text-lightGray-darkest">{userDetails.userID}</div>
                </div>
              )}
            </div>
            <hr className="sui-border-lightGray sui-mt-base sui-mb-2.5" />
          </div>
          {dropdownList.map((dropdownItem, dropdownIndex) => {
            if (dropdownItem.isHidden) {
              return;
            }
            const dropdownLink = dropdownItem.isLink;
            const dropdownLabel = dropdownItem.label;
            const dropdownIcon = dropdownItem.icon;
            const dropdownGroup = dropdownItem.isGroup;
            return dropdownLink ? (
              <DropdownItem
                key={dropdownIndex}
                isLink
                label={dropdownLabel}
                className="sui-flex-1 sui-px-6 sui-py-1.5"
                size={ButtonSize.LARGE}
                icon={dropdownIcon}
                {...dropdownLink}
              />
            ) : (
              <DropdownItem
                key={dropdownIndex}
                isHeader
                label={dropdownLabel}
                className="sui-flex-1"
                icon={dropdownIcon}
                {...dropdownLink}
              >
                <div className="sui-flex sui-flex-col sui-pl-14">{dropdownGroup}</div>
              </DropdownItem>
            );
          })}
        </div>
      </Dropdown>
    </div>
  );
};

import React from 'react';
import classNames from 'classnames';

import { IconOutline, IconTypes } from '../../../types';

export type IconCheckProps = {
  variant?: 'default' | 'circle' | 'double' | 'square' | 'shield';
};

export const IconCheck = ({
  className,
  width = 24,
  height = 24,
  variant = 'default',
  ariaHidden = true,
  isClickable = false,
  outline,
}: IconTypes & IconCheckProps & IconOutline): React.ReactElement => {
  const defaultClass = classNames(
    'sui-fill-current sui-icon-check',
    { 'sui-pointer-events-none': !isClickable },
    className
  );
  let iconVariant = outline ? (
    <path d="M18.8505 6.06992L8.72811 16.1923L5.1495 12.6137C4.9801 12.4443 4.70544 12.4443 4.53601 12.6137L4.12705 13.0227C3.95765 13.192 3.95765 13.4667 4.12705 13.6361L8.42138 17.9305C8.59078 18.0999 8.86544 18.0999 9.03488 17.9305L19.873 7.09241C20.0423 6.92301 20.0423 6.64835 19.873 6.47891L19.464 6.06992C19.2946 5.90052 19.0199 5.90052 18.8505 6.06992Z" />
  ) : (
    <path d="M9.43431 17.7314L4.2343 12.5314C3.9219 12.219 3.9219 11.7124 4.2343 11.4L5.36565 10.2686C5.67806 9.95617 6.18462 9.95617 6.49703 10.2686L10 13.7716L17.503 6.26861C17.8154 5.9562 18.3219 5.9562 18.6344 6.26861L19.7657 7.39998C20.0781 7.71239 20.0781 8.21892 19.7657 8.53136L10.5657 17.7314C10.2532 18.0438 9.74672 18.0438 9.43431 17.7314Z" />
  );

  if (variant === 'circle') {
    iconVariant = outline ? (
      <path d="M12 4C7.58171 4 4 7.58171 4 12C4 16.4183 7.58171 20 12 20C16.4183 20 20 16.4183 20 12C20 7.58171 16.4183 4 12 4ZM12 18.9677C8.17213 18.9677 5.03226 15.8692 5.03226 12C5.03226 8.17216 8.13081 5.03226 12 5.03226C15.8279 5.03226 18.9677 8.13081 18.9677 12C18.9677 15.8278 15.8692 18.9677 12 18.9677ZM16.5687 10.098L10.7468 15.8733C10.595 16.0238 10.3499 16.0228 10.1994 15.8711L7.4291 13.0784C7.27855 12.9266 7.27952 12.6815 7.43129 12.531L7.70613 12.2584C7.8579 12.1078 8.103 12.1088 8.25355 12.2606L10.4786 14.5036L15.7509 9.27358C15.9026 9.12303 16.1477 9.124 16.2983 9.27577L16.5709 9.55061C16.7215 9.70239 16.7205 9.94748 16.5687 10.098Z" />
    ) : (
      <path d="M20 12C20 16.4183 16.4183 20 12 20C7.58171 20 4 16.4183 4 12C4 7.58171 7.58171 4 12 4C16.4183 4 20 7.58171 20 12ZM11.0746 16.2359L17.0101 10.3005C17.2117 10.0989 17.2117 9.7721 17.0101 9.57055L16.2802 8.84064C16.0787 8.63906 15.7519 8.63906 15.5503 8.84064L10.7097 13.6812L8.44971 11.4213C8.24816 11.2197 7.92135 11.2197 7.71977 11.4213L6.98987 12.1512C6.78832 12.3527 6.78832 12.6795 6.98987 12.8811L10.3447 16.2359C10.5463 16.4375 10.8731 16.4375 11.0746 16.2359Z" />
    );
  }

  if (variant === 'double') {
    iconVariant = outline ? (
      <path d="M19.8911 8.53561L19.4568 8.14276C19.3843 8.04741 19.2882 7.99991 19.1672 7.99991C19.0461 7.99991 18.95 8.04741 18.8775 8.14276L8.70597 18.1425L5.12212 14.6069C5.04962 14.5116 4.95355 14.4641 4.83248 14.4641C4.71141 14.4641 4.61534 14.5116 4.54285 14.6069L4.10857 14.9998C4.03607 15.0951 4 15.2022 4 15.3212C4 15.4401 4.03607 15.5355 4.10857 15.6069L8.41633 19.8568C8.5124 19.9521 8.61525 19.9996 8.72418 19.9996C8.83311 19.9996 8.9356 19.9521 9.03203 19.8568L19.8914 9.14274C19.9639 9.07132 20 8.97596 20 8.85703C20 8.73811 19.9636 8.63097 19.8911 8.53561ZM8.54168 13.0009C8.63632 13.0955 8.73739 13.1427 8.84418 13.1427C8.95096 13.1427 9.05203 13.0955 9.14667 13.0009L17.0358 5.13569C17.1068 5.06426 17.1426 4.96962 17.1426 4.85141C17.1426 4.7332 17.1068 4.62677 17.0358 4.53213L16.6086 4.14178C16.5376 4.04714 16.4429 4 16.324 4C16.2051 4 16.1104 4.04714 16.0394 4.14178L8.82632 11.2977L6.24602 8.72525C6.17495 8.63061 6.08031 8.58347 5.96138 8.58347C5.84246 8.58347 5.74782 8.63061 5.67675 8.72525L5.24961 9.1156C5.17855 9.21024 5.14283 9.31667 5.14283 9.43488C5.14283 9.55309 5.17855 9.64773 5.24961 9.7188L8.54168 13.0009Z" />
    ) : (
      <path d="M19.7815 9.46248L18.5442 8.22516C18.2505 7.93145 17.7755 7.93145 17.4849 8.22516L10.0017 15.7084L6.52092 12.2246C6.22721 11.9309 5.75228 11.9309 5.4617 12.2246L4.22126 13.465C3.92755 13.7587 3.92755 14.2337 4.22126 14.5274L9.47049 19.7797C9.76419 20.0734 10.2391 20.0734 10.5297 19.7797L19.7784 10.5248C20.0721 10.228 20.0721 9.75306 19.7815 9.46248ZM9.64859 12.7745C9.84231 12.9713 10.161 12.9713 10.3547 12.7745L16.8538 6.2692C17.0475 6.07235 17.0475 5.75677 16.8538 5.56305L15.4415 4.14763C15.2478 3.95079 14.9291 3.95079 14.7353 4.14763L10.0017 8.88132L8.27066 7.1472C8.07694 6.95035 7.75824 6.95035 7.56452 7.1472L6.1491 8.56261C5.95538 8.75946 5.95538 9.07504 6.1491 9.26876L9.64859 12.7745Z" />
    );
  }

  if (variant === 'square') {
    iconVariant = outline ? (
      <path d="M18.2857 4H5.71429C4.7675 4 4 4.7675 4 5.71429V18.2857C4 19.2325 4.7675 20 5.71429 20H18.2857C19.2325 20 20 19.2325 20 18.2857V5.71429C20 4.7675 19.2325 4 18.2857 4ZM18.2857 5.14286C18.6008 5.14286 18.8571 5.39921 18.8571 5.71429V18.2857C18.8571 18.6008 18.6008 18.8571 18.2857 18.8571H5.71429C5.39921 18.8571 5.14286 18.6008 5.14286 18.2857V5.71429C5.14286 5.39921 5.39921 5.14286 5.71429 5.14286H18.2857ZM17.0607 8.65332L16.7589 8.34904C16.5922 8.181 16.3208 8.17989 16.1528 8.34661L10.3156 14.137L7.85218 11.6536C7.6855 11.4856 7.41414 11.4845 7.24611 11.6512L6.94182 11.953C6.77379 12.1197 6.77268 12.3911 6.93939 12.5591L10.0065 15.651C10.1731 15.819 10.4445 15.8201 10.6125 15.6534L17.0583 9.25939C17.2263 9.09271 17.2274 8.82136 17.0607 8.65332Z" />
    ) : (
      <path d="M18.2857 20H5.71429C4.7675 20 4 19.2325 4 18.2857V5.71429C4 4.7675 4.7675 4 5.71429 4H18.2857C19.2325 4 20 4.7675 20 5.71429V18.2857C20 19.2325 19.2325 20 18.2857 20ZM10.9755 16.4979L17.5469 9.92646C17.7701 9.70332 17.7701 9.3415 17.5469 9.11836L16.7388 8.31025C16.5157 8.08711 16.1539 8.08707 15.9307 8.31025L10.5714 13.6695L8.06932 11.1674C7.84618 10.9442 7.48436 10.9442 7.26118 11.1674L6.45307 11.9755C6.22993 12.1986 6.22993 12.5604 6.45307 12.7836L10.1674 16.4979C10.3905 16.7211 10.7523 16.7211 10.9755 16.4979Z" />
    );
  }
  if (variant === 'shield') {
    iconVariant = outline ? (
      <path d="M18.579 6.6151L12.5784 4.11486C12.3956 4.03903 12.1997 4 12.0018 4C11.8039 4 11.608 4.03903 11.4252 4.11486L5.4246 6.6151C4.86517 6.84637 4.49951 7.3933 4.49951 7.99961C4.49951 14.2033 8.07799 18.4913 11.4221 19.8851C11.7908 20.0383 12.2065 20.0383 12.5753 19.8851C15.2537 18.7694 19.501 14.9159 19.501 7.99961C19.501 7.3933 19.1353 6.84637 18.579 6.6151ZM12.194 18.9632C12.069 19.0132 11.9315 19.0132 11.8096 18.9632C8.74993 17.7506 5.49961 13.5001 5.49961 7.99961C5.49961 7.79647 5.6215 7.6152 5.80589 7.53707L11.8065 5.03682C11.9284 4.98682 12.069 4.98682 12.1909 5.03682L18.1915 7.53707C18.379 7.6152 18.5009 7.79647 18.4978 7.99961C18.5009 13.5001 15.2506 17.7506 12.194 18.9632V18.9632ZM16.4507 8.80594C16.3038 8.65905 16.0663 8.65905 15.9194 8.80281L10.8126 13.8689L8.65617 11.6968C8.50928 11.55 8.27175 11.55 8.12487 11.6937L7.85921 11.9594C7.71233 12.1063 7.71233 12.3438 7.85609 12.4907L10.5407 15.1972C10.6876 15.3441 10.9251 15.3441 11.072 15.2003L16.7132 9.60602C16.8601 9.45913 16.8601 9.22161 16.7163 9.07472L16.4507 8.80594Z" />
    ) : (
      <path d="M18.579 6.6151L12.5784 4.11486C12.3956 4.03903 12.1997 4 12.0018 4C11.8039 4 11.608 4.03903 11.4252 4.11486L5.4246 6.6151C4.86517 6.84637 4.49951 7.3933 4.49951 7.99961C4.49951 14.2033 8.07799 18.4913 11.4221 19.8851C11.7908 20.0383 12.2065 20.0383 12.5753 19.8851C15.2537 18.7694 19.501 14.9159 19.501 7.99961C19.501 7.3933 19.1353 6.84637 18.579 6.6151ZM17.1039 10.1842L11.3533 15.9348C11.1595 16.1285 10.8408 16.1285 10.647 15.9348L7.39667 12.6844C7.2029 12.4907 7.2029 12.1719 7.39667 11.9781L8.10299 11.2718C8.29676 11.078 8.61554 11.078 8.80931 11.2718L11.0001 13.4626L15.6912 8.77156C15.885 8.57779 16.2038 8.57779 16.3975 8.77156L17.1039 9.47788C17.3008 9.67478 17.3008 9.99043 17.1039 10.1842Z" />
    );
  }
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={defaultClass}
      aria-hidden={ariaHidden}
    >
      {iconVariant}
    </svg>
  );
};

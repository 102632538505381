import React from 'react';
import { createPortal } from 'react-dom';
import { Placement } from '@popperjs/core';
import { Transition } from 'react-transition-group';
import { Popper } from 'react-popper';
import classNames from 'classnames';

export interface IOverlayProps extends React.HtmlHTMLAttributes<HTMLDivElement> {
  children: React.ReactElement;
  placement?: Placement;
  show: boolean;
  target: HTMLElement | null;
  offset?: { horizontal: number; vertical: number };
  fade?: boolean;
}

export const Overlay: React.FC<IOverlayProps> = ({
  children,
  offset = { horizontal: 0, vertical: 0 },
  placement = 'top',
  show,
  target,
  fade = true,
  className = '',
  ...props
}: IOverlayProps) => {
  const { horizontal, vertical } = offset;
  const [exited, setExited] = React.useState(!show);
  const targetElement = target ?? document.body;
  const nodeRef = React.useRef(null);
  const modifiers = [
    {
      name: 'offset',
      enabled: true,
      options: {
        offset: [horizontal, vertical],
      },
    },
  ];

  if (show) {
    if (exited) {
      setExited(false);
    }
  } else if (!fade && !exited) {
    setExited(true);
  }

  if (exited) {
    return null;
  }

  const getPopper = (popperClassNames: ReadonlyArray<string> = ['show']): React.ReactElement => (
    <Popper referenceElement={targetElement} modifiers={modifiers} placement={placement}>
      {({ ref, style, arrowProps, placement }) =>
        React.cloneElement(children, {
          ref: ref,
          className: classNames(...popperClassNames, children.props.className, className),
          style: style,
          arrowProps: arrowProps,
          placement: placement,
          ...props,
        })
      }
    </Popper>
  );

  let child = getPopper();

  if (fade) {
    child = (
      <Transition
        in={show}
        timeout={150}
        mountOnEnter={false}
        unmountOnExit={false}
        appear={true}
        enter={true}
        exit={true}
        onExited={() => setExited(true)}
        nodeRef={nodeRef}
      >
        {state => getPopper(['sui-c-fade', state === 'entered' ? 'show' : ''])}
      </Transition>
    );
  }

  return createPortal(child, document.body);
};

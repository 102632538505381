import React from 'react';

export enum ButtonSize {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
}

export enum StateSize {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
}

export enum ButtonType {
  BUTTON = 'button',
  LINK = 'link',
  NAVIGATION = 'nav',
  NAVIGATION_WHITE = 'nav-white',
  NAVIGATION_LINK = 'nav-link',
  SUB_HEADER = 'sub-header',
  BREADCRUMBS = 'breadcrumbs',
}

export enum ButtonVariant {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  TERTIARY = 'tertiary',
  NEGATIVE = 'negative',
  WARNING = 'warning',
  LINK = 'link',
  /**
   * @deprecated `ICON` variant is deprecated. Use `variant="secondary"` and `border="none"` instead
   */
  ICON = 'icon',
}

export enum InputVariant {
  LABEL = 'label',
  TEXT = 'text',
  TELUS = 'telus',
  SIMPLE = 'simple',
  SEARCH = 'search',
  MULTI_STRING = 'multi-string',
}

export enum PageHeaderVariant {
  TEXT = 'text',
  INPUT = 'input',
  SEARCHBAR = 'search-bar',
}

export enum SmallCardVariant {
  DEFAULT = 'default',
  DISABLED = 'disabled',
  SELECTED = 'selected',
  DRAGGABLE = 'draggable',
}

export enum ValidationType {
  DEFAULT = 'default',
  ERROR = 'error',
  WARNING = 'warning',
  SUCCESS = 'success',
  INFO = 'info',
}

export enum BadgesType {
  GENERIC = 'generic',
  ONGOING = 'on-going',
  SUCCESS = 'success',
  WARNING = 'warning',
  ERROR = 'error',
  PAUSE = 'pause',
  NEUTRAL = 'neutral',
}

export enum BadgeSize {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
}
export enum BadgeVariant {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
}

export enum ComponentSize {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
}

export type IconTypes = {
  className?: string;
  width?: number;
  height?: number;
  ariaHidden?: boolean;
  isClickable?: boolean;
};

export type IconOutline = {
  outline?: boolean;
};

export type HasIcon = {
  icon: React.ReactNode;
  isLeft?: boolean;
};

export type NavigationLinks = ReadonlyArray<{
  label: string;
  path: string;
  exact?: boolean;
  isHidden?: boolean;
  external?: boolean;
  isSamePage?: boolean;
  // TODO: (Remove) This is a Temporary fix for PMP Header Navigation
  isActive?: boolean;
}>;

export type SubNavigationVerticalLinks = ReadonlyArray<{
  label: string;
  path: string;
  isDisabled?: boolean;
}>;

export type NavBarItem = {
  icon: React.ReactNode;
  path: string;
  title: string;
  isHidden?: boolean;
  isExternal?: boolean;
  exact?: boolean;
  isSamePage?: boolean;
  onClick?: () => void;
  isActive?: boolean;
  notification?: boolean | number;
};

export enum SubNavigationVariantProps {
  TAB = 'tab',
  TABBOXED = 'tab-boxed',
}

export enum ModalSize {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
  XLARGE = 'xlarge',
}
export enum ModalVariant {
  DIALOG = 'dialog',
  SIDE_PANEL = 'side-panel',
  FULL_SCREEN = 'full-screen',
}

export enum DatePickerSelectionMode {
  SINGLE_DATE = 'singleDate',
  RANGE = 'range',
}

export enum DatePickerViewVariant {
  SINGLE = 'single',
  DOUBLE = 'double',
}

export enum ScrollListArrowDirection {
  UP = 'up',
  DOWN = 'down',
}

export type DateRange = {
  start: Date | null;
  end: Date | null;
};

export type TextListItem = {
  label: string;
  isSelected?: boolean;
  isDisabled?: boolean;
};

export type ToolbarType = 'simple' | 'advanced';

export type DataGridRowModel = {
  id: string | number;
  [key: string]: unknown;
};

export type DataGridColumnType = 'string' | 'number' | 'date' | 'dateTime' | 'boolean';

export type DataGridColumn = {
  field: string;
  label?: string;
  type?: DataGridColumnType;
  width?: number;
  formatValueForDisplay?: (value: DataGridFormatValueParams) => string;
};

export type DataGridFormatValueParams<V = any> = {
  field: string;
  value: V;
};

import React from 'react';
import classNames from 'classnames';
import { Link, NavLink } from 'react-router-dom';
import { Location, LocationDescriptor } from 'history';

import { ButtonSize, ButtonType, HasIcon, ButtonVariant } from '../../types';

export interface ITextLinkProps extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  hasIcon?: HasIcon;
  disabled?: boolean;
  as?: ButtonType;
  size?: ButtonSize;
  to?: LocationDescriptor | Location;
  exact?: boolean;
  variant?: ButtonVariant;
}

export const TextLink: React.FC<ITextLinkProps> = ({
  children,
  hasIcon,
  className,
  disabled,
  as = ButtonType.LINK,
  size = ButtonSize.MEDIUM,
  to,
  exact,
  variant = ButtonVariant.PRIMARY,
  ...props
}: ITextLinkProps) => {
  let textColor = 'sui-text-primary';
  let textSize = 'sui-font-heading sui-text-h4';
  let linkAs = 'sui-c-link';
  // Types
  if (as === ButtonType.BUTTON) {
    textColor = '';
    let buttonVariant = 'sui-c-btn-primary hover:sui-text-white';
    if (variant === ButtonVariant.SECONDARY) {
      buttonVariant = 'sui-c-btn-secondary hover:sui-text-darkGray-darkest';
    }
    if (variant === ButtonVariant.TERTIARY) {
      buttonVariant = 'sui-c-btn-tertiary hover:sui-text-darkGray-darkest';
    }
    if (variant === ButtonVariant.NEGATIVE) {
      buttonVariant = 'sui-c-btn-negative hover:sui-text-white';
    }
    linkAs = classNames(
      buttonVariant,
      'sui-rounded sui-inline-flex sui-items-center hover:sui-no-underline',
      {
        'sui-bg-lightGray': disabled,
        'sui-u-btn-medium sui-px-3.5': size === ButtonSize.MEDIUM,
        'sui-u-btn-large sui-px-3.5': size === ButtonSize.LARGE,
        'sui-u-btn-small sui-px-3': size === ButtonSize.SMALL,
      }
    );
  }
  if (as === ButtonType.LINK) {
    if (variant === ButtonVariant.NEGATIVE) {
      textColor = 'sui-text-danger hover:sui-text-danger';
    }
  }
  if (as === ButtonType.NAVIGATION) {
    textColor = 'sui-text-lightGray-darkest';
    textSize = 'sui-text-b3';
    linkAs = 'sui-c-navigation';
  }
  if (as === ButtonType.NAVIGATION_WHITE) {
    textColor = 'sui-text-darkGray-darkest';
    textSize = 'sui-text-b3';
    linkAs = 'sui-c-navigation-white';
  }
  if (as === ButtonType.NAVIGATION_LINK) {
    textColor = 'sui-text-darkGray-darkest';
    linkAs = 'sui-c-nav-link';
  }
  if (as === ButtonType.SUB_HEADER) {
    textColor = 'sui-text-darkGray-darkest';
    linkAs = 'sui-c-sub-header-links';
  }
  if (as === ButtonType.BREADCRUMBS) {
    textColor = 'sui-text-darkGray';
  }

  // Sizes
  if (size === ButtonSize.LARGE) {
    textSize = 'sui-font-heading  sui-text-h3';
  }
  if (size === ButtonSize.SMALL) {
    textSize = 'sui-font-heading sui-text-h5';
  }

  const styleDisabled = disabled ? 'sui-text-lightGray-darkest sui-pointer-events-none' : textColor;

  const defaultClass = classNames('sui-cursor-pointer', styleDisabled, textSize, linkAs, className);

  const LinkContent = ({ icon, children }: { icon?: HasIcon; children: React.ReactNode }) => {
    return (
      <>
        {icon ? (
          <>
            {icon.isLeft && icon.icon}
            <span className={icon.isLeft ? 'sui-ml-sm' : 'sui-mr-sm'}>{children}</span>
            {!icon.isLeft && icon.icon}
          </>
        ) : (
          <>{children}</>
        )}
      </>
    );
  };

  return (
    <>
      {to ? (
        as === ButtonType.NAVIGATION ||
        as === ButtonType.NAVIGATION_WHITE ||
        as === ButtonType.NAVIGATION_LINK ||
        as === ButtonType.SUB_HEADER ? (
          <NavLink
            to={to}
            exact={exact}
            activeClassName="active"
            className={defaultClass}
            {...props}
          >
            <LinkContent icon={hasIcon}>{children}</LinkContent>
          </NavLink>
        ) : (
          <Link to={to} className={defaultClass} {...props}>
            <LinkContent icon={hasIcon}>{children}</LinkContent>
          </Link>
        )
      ) : (
        <a className={defaultClass} {...props}>
          <LinkContent icon={hasIcon}>{children}</LinkContent>
        </a>
      )}
    </>
  );
};

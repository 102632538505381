import React, { useState } from 'react';
import classNames from 'classnames';

import { IconChevron, IconCaretDown } from '../Icon';
import { DateRange, TextListItem } from '../../types';
import {
  monthNamesShort,
  getCalendarViewWeeks,
  getWeekdayLabels,
  currentDate,
  isDateInRange,
} from '../../lib/DateUtils';
import { ScrollList } from '../ScrollList';

import { Week } from './Week';
import { Month } from './Month';
import { CalendarSide, HeaderArrowDirection } from './DatePicker';

export interface ICalendarProps extends React.HTMLAttributes<HTMLAllCollection> {
  isMondayBased?: boolean;
  displayedMonth: Date;
  selectedDateRange: DateRange;
  disabledRanges?: ReadonlyArray<DateRange>;
  side?: CalendarSide;
  onSelectDate: (date: Date) => void;
  onSelectMonth: (firstOfMonth: Date) => void;
  onSelectYear: (firstOfMonthOfSelectedYear: Date) => void;
  onClickArrows: (direction: HeaderArrowDirection) => void;
}

enum ViewType {
  DAY = 'day',
  MONTH = 'month',
  YEAR = 'year',
}

const getYearList = (
  preSelectedYear: number,
  disabledRanges: ReadonlyArray<DateRange>
): ReadonlyArray<TextListItem> => {
  const firstEpochYear = 1970;
  const upperLimit = currentDate.getFullYear() + 100;
  const yearList = [];
  for (let year = upperLimit - 1; year >= firstEpochYear; year--) {
    const firstDayOfYear = new Date(year, 0, 1);
    const lastDayOfYear = new Date(year + 1, 0, 0);

    const label = year.toString();
    const isSelected = year === preSelectedYear;
    const isDisabled = disabledRanges.some(range => {
      return (
        isDateInRange(firstDayOfYear, range, true, true) &&
        isDateInRange(lastDayOfYear, range, true, true)
      );
    });

    const item: TextListItem = { label, isSelected, isDisabled };
    yearList.push(item);
  }
  return yearList;
};

const Header = ({
  displayedMonth,
  side,
  onChangeView,
  onArrowClick,
}: {
  displayedMonth: Date;
  side?: CalendarSide;
  onChangeView: (view: ViewType) => void;
  onArrowClick: (direction: HeaderArrowDirection) => void;
}) => {
  const monthName = monthNamesShort[displayedMonth.getMonth()];
  const year = displayedMonth.getFullYear();
  const styleClasses = {
    mainContainer: classNames(
      'sui-flex sui-justify-between sui-items-center sui-px-4 sui-py-3',
      'sui-text-white sui-text-b3 sui-font-body sui-font-normal sui-bg-primary',
      { 'sui-rounded-tl-sm': side === 'left' || !side },
      { 'sui-rounded-tr-sm': side === 'right' || !side }
    ),
    monthYearContainer: 'sui-flex sui-h-6',
    headerItems: classNames(
      'sui-border sui-border-transparent focus:sui-outline-none',
      'hover:sui-border-transparent hover:sui-border-b-white hover:sui-rounded-none',
      'active:sui-border-white active:sui-rounded',
      'focus-visible:sui-border-white focus-visible:sui-rounded'
    ),
    monthContainer: 'sui-flex sui-px-0.5 sui-items-center',
    yearContainer: 'sui-flex sui-px-0.5 sui-items-center',
    monthLabel: 'sui-w-10 sui-text-center',
    yearLabel: 'sui-w-50 sui-text-center',
    arrowIconWrapper: 'sui-flex sui-items-center sui-justify-center sui-w-6 sui-h-6',
    caretIconWrapper: 'sui-opacity-50',
  };

  const viewClickHandler = (view: ViewType) => {
    onChangeView(view);
  };

  const arrowClickHandler = (direction: HeaderArrowDirection) => {
    onArrowClick(direction);
  };

  const sideLabel = side ? `${side} side ` : '';

  return (
    <div className={styleClasses.mainContainer}>
      <button
        className={classNames(styleClasses.arrowIconWrapper, styleClasses.headerItems)}
        aria-label={`${sideLabel}left arrow button`}
        onClick={() => arrowClickHandler('left')}
      >
        <IconChevron variant="left" />
      </button>
      <div className={styleClasses.monthYearContainer}>
        <button
          className={classNames(styleClasses.monthContainer, styleClasses.headerItems)}
          onClick={() => viewClickHandler(ViewType.MONTH)}
          aria-label={`${sideLabel}month selection button`}
        >
          <span className={styleClasses.monthLabel}>{monthName}</span>
          <IconCaretDown className={styleClasses.caretIconWrapper} width={16} height={16} />
        </button>
        <button
          className={classNames(styleClasses.yearContainer, styleClasses.headerItems)}
          onClick={() => viewClickHandler(ViewType.YEAR)}
          aria-label={`${sideLabel}year selection button`}
        >
          <span className={styleClasses.yearLabel}>{year}</span>
          <IconCaretDown className={styleClasses.caretIconWrapper} width={16} height={16} />
        </button>
      </div>
      <button
        className={classNames(styleClasses.arrowIconWrapper, styleClasses.headerItems)}
        aria-label={`${sideLabel}right arrow button`}
        onClick={() => arrowClickHandler('right')}
      >
        <IconChevron variant="right" />
      </button>
    </div>
  );
};

const DaysGrid = ({
  isMondayBased,
  displayedMonth,
  selectedRange,
  disabledRanges,
  side,
  onSelectDate,
}: {
  isMondayBased: boolean;
  displayedMonth: Date;
  selectedRange: DateRange;
  disabledRanges: ReadonlyArray<DateRange>;
  side?: CalendarSide;
  onSelectDate: (date: Date) => void;
}) => {
  const calendarViewWeeks = getCalendarViewWeeks(displayedMonth, isMondayBased);
  const weekdayLabels = getWeekdayLabels(isMondayBased);

  const styleClasses = {
    gridContainer: 'sui-grid sui-gap-1 sui-py-2',
    weekdayLabelContainer:
      'sui-flex sui-uppercase sui-text-b6 sui-w-full sui-px-4 sui-justify-between',
    weekdayLabel: 'sui-w-10 sui-text-center',
  };

  return (
    <div className={styleClasses.gridContainer}>
      <div className={styleClasses.weekdayLabelContainer}>
        {weekdayLabels.map(weekday => (
          <span key={weekday} className={styleClasses.weekdayLabel}>
            {weekday}
          </span>
        ))}
      </div>
      {calendarViewWeeks.map((daysInWeek, weekIndex) => (
        <Week
          key={weekIndex}
          daysInWeek={daysInWeek}
          displayedMonth={displayedMonth}
          selectedRange={selectedRange}
          disabledRanges={disabledRanges}
          side={side}
          onSelectDate={onSelectDate}
        />
      ))}
    </div>
  );
};

const MonthsGrid = ({
  displayedYear,
  selectedRange,
  disabledRanges,
  side,
  onSelectMonth,
}: {
  displayedYear: number;
  selectedRange: DateRange;
  disabledRanges: ReadonlyArray<DateRange>;
  side?: CalendarSide;
  onSelectMonth: (firstOfMonth: Date) => void;
}) => {
  const styleClasses = {
    gridContainer:
      'sui-grid sui-grid-cols-3 sui-grid-rows-4 sui-px-4 sui-py-2 sui-gap-x-14 sui-gap-y-1',
  };

  return (
    <div className={styleClasses.gridContainer}>
      {monthNamesShort.map((_, monthIndex) => (
        <Month
          key={monthIndex}
          monthIndex={monthIndex}
          displayedYear={displayedYear}
          selectedRange={selectedRange}
          disabledRanges={disabledRanges}
          side={side}
          onSelectMonth={onSelectMonth}
        />
      ))}
    </div>
  );
};

const YearsGrid = ({
  preSelectedYear,
  disabledRanges = [],
  side,
  onSelectYear,
}: {
  preSelectedYear: number;
  disabledRanges?: ReadonlyArray<DateRange>;
  side?: CalendarSide;
  onSelectYear: (year: number) => void;
}) => {
  const yearList = getYearList(preSelectedYear, disabledRanges);

  const ariaPrefix = side ? `${side} year` : `year`;

  return (
    <ScrollList
      items={yearList}
      onSelectItem={(item: TextListItem) => onSelectYear(parseInt(item.label))}
      ariaLabel={ariaPrefix}
    />
  );
};

export const Calendar: React.FC<ICalendarProps> = ({
  isMondayBased = false,
  displayedMonth,
  selectedDateRange,
  disabledRanges = [],
  side,
  onSelectDate,
  onSelectMonth,
  onSelectYear,
  onClickArrows,
}: ICalendarProps) => {
  const [view, setView] = useState<ViewType>(ViewType.DAY);

  const changeViewHandler = (newView: ViewType) => {
    setView(previousState => (newView === previousState ? ViewType.DAY : newView));
  };

  const selectYearHandler = (selectedYear: number) => {
    setView(ViewType.DAY);
    onSelectYear(new Date(selectedYear, displayedMonth.getMonth(), 1));
  };

  const selectMonthHandler = (firstOfMonth: Date) => {
    setView(ViewType.DAY);
    onSelectMonth(firstOfMonth);
  };

  const styleClasses = {
    mainContainer: 'sui-w-312 sui-flex sui-flex-col',
    bodyContainer: classNames(
      'sui-flex-auto',
      side === 'left' ? 'sui-border-r sui-border-r-lightGray' : ''
    ),
  };

  return (
    <div className={styleClasses.mainContainer}>
      <Header
        displayedMonth={displayedMonth}
        side={side}
        onChangeView={changeViewHandler}
        onArrowClick={(direction: HeaderArrowDirection) => onClickArrows(direction)}
      />
      <div className={styleClasses.bodyContainer}>
        {view === ViewType.DAY && (
          <DaysGrid
            isMondayBased={isMondayBased}
            displayedMonth={displayedMonth}
            selectedRange={selectedDateRange}
            disabledRanges={disabledRanges}
            side={side}
            onSelectDate={onSelectDate}
          />
        )}
        {view === ViewType.MONTH && (
          <MonthsGrid
            displayedYear={displayedMonth.getFullYear()}
            selectedRange={selectedDateRange}
            disabledRanges={disabledRanges}
            side={side}
            onSelectMonth={selectMonthHandler}
          />
        )}
        {view === ViewType.YEAR && (
          <YearsGrid
            preSelectedYear={displayedMonth.getFullYear()}
            disabledRanges={disabledRanges}
            side={side}
            onSelectYear={selectYearHandler}
          />
        )}
      </div>
    </div>
  );
};

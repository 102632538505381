import React from 'react';
import classNames from 'classnames';

import { IconTypes } from '../../../types';

export type IconLinkProps = {
  variant?: 'default' | 'horizontal' | 'unlink';
};

export const IconLink = ({
  className,
  width = 24,
  height = 24,
  ariaHidden = true,
  isClickable = false,
  variant,
}: IconTypes & IconLinkProps): React.ReactElement => {
  const defaultClass = classNames(
    'sui-fill-current sui-icon-link',
    {
      'sui-pointer-events-none': !isClickable,
      'sui-c-horizontal': variant === 'horizontal',
      'sui-c-unlink': variant === 'unlink',
    },
    className
  );

  let iconVariant = (
    <path d="M14.2066 9.79347C16.0737 11.6625 16.0481 14.659 14.2179 16.4994C14.2144 16.5031 14.2104 16.5072 14.2066 16.511L12.1066 18.611C10.2544 20.4631 7.24104 20.4629 5.38914 18.611C3.53695 16.7591 3.53695 13.7453 5.38914 11.8935L6.5487 10.7339C6.8562 10.4264 7.38576 10.6308 7.40163 11.0653C7.42188 11.6192 7.5212 12.1756 7.70445 12.7129C7.76651 12.8948 7.72216 13.0961 7.58623 13.232L7.17726 13.641C6.30145 14.5168 6.27398 15.9428 7.14117 16.8272C8.01691 17.7203 9.45635 17.7256 10.3388 16.8431L12.4388 14.7435C13.3198 13.8625 13.3161 12.4386 12.4388 11.5613C12.3232 11.4458 12.2067 11.3562 12.1157 11.2935C12.0513 11.2493 11.9981 11.1907 11.9604 11.1223C11.9227 11.0539 11.9016 10.9776 11.8986 10.8996C11.8862 10.5693 12.0032 10.2291 12.2641 9.96813L12.9221 9.31016C13.0946 9.13763 13.3652 9.11644 13.5653 9.25607C13.7944 9.41605 14.009 9.59588 14.2066 9.79347ZM18.6108 5.38904C16.7589 3.53711 13.7455 3.53686 11.8933 5.38904L9.79335 7.48904C9.7896 7.49279 9.78554 7.49685 9.7821 7.5006C7.95191 9.34097 7.92626 12.3375 9.79335 14.2065C9.99094 14.4041 10.2055 14.5839 10.4346 14.7439C10.6347 14.8835 10.9054 14.8623 11.0779 14.6898L11.7358 14.0318C11.9968 13.7709 12.1137 13.4306 12.1014 13.1004C12.0984 13.0224 12.0772 12.9461 12.0395 12.8777C12.0018 12.8093 11.9487 12.7507 11.8843 12.7065C11.7933 12.6438 11.6768 12.5541 11.5611 12.4387C10.6838 11.5614 10.6802 10.1375 11.5611 9.25651L13.6611 7.15682C14.5436 6.27435 15.983 6.27967 16.8588 7.17276C17.726 8.05713 17.6985 9.48319 16.8227 10.359L16.4137 10.768C16.2778 10.9039 16.2334 11.1052 16.2955 11.2871C16.4787 11.8244 16.5781 12.3808 16.5983 12.9346C16.6142 13.3692 17.1437 13.5736 17.4512 13.2661L18.6108 12.1065C20.463 10.2547 20.463 7.24092 18.6108 5.38904Z" />
  );

  if (variant === 'horizontal') {
    iconVariant = (
      <path d="M11.2816 7C13.1992 7 14.752 8.6175 14.752 10.615C14.752 12.3825 13.5256 13.89 11.8528 14.18L11.8072 14.19C11.3872 14.2625 10.9912 13.9675 10.9216 13.5325C10.852 13.095 11.1352 12.6825 11.5528 12.61L11.5984 12.6025C12.532 12.44 13.216 11.6 13.216 10.615C13.216 9.5025 12.3496 8.6 11.2816 8.6H7.4704C6.4024 8.6 5.536 9.5025 5.536 10.615C5.536 11.6 6.21976 12.44 7.1536 12.6025L7.1992 12.61C7.6168 12.6825 7.9 13.095 7.8304 13.5325C7.7608 13.9675 7.3648 14.2625 6.9448 14.19L6.8992 14.18C5.22664 13.89 4 12.3825 4 10.615C4 8.6175 5.55376 7 7.4704 7H11.2816ZM12.0784 16.6C10.1608 16.6 8.608 14.9825 8.608 12.985C8.608 11.2175 9.8344 9.71 11.5072 9.42L11.5528 9.41C11.9728 9.3375 12.3688 9.6325 12.4384 10.0675C12.508 10.505 12.2248 10.9175 11.8072 10.99L11.7616 10.9975C10.828 11.16 10.144 11.9775 10.144 12.985C10.144 14.0975 11.0104 15 12.0784 15H15.8896C16.9576 15 17.824 14.0975 17.824 12.985C17.824 11.9775 17.14 11.16 16.2064 10.9975L16.1608 10.99C15.7432 10.9175 15.46 10.505 15.5296 10.0675C15.5992 9.6325 15.9952 9.3375 16.4152 9.41L16.4608 9.42C18.1336 9.71 19.36 11.2175 19.36 12.985C19.36 14.9825 17.8072 16.6 15.8896 16.6H12.0784Z" />
    );
  }

  if (variant === 'unlink') {
    iconVariant = (
      <path d="M13.5026 16.6846C13.649 16.831 13.649 17.0684 13.5026 17.2149L12.1065 18.611C10.2546 20.4629 7.24113 20.463 5.38902 18.611C3.53703 16.7589 3.53703 13.7455 5.38902 11.8935L6.7851 10.4974C6.93154 10.351 7.16898 10.351 7.31544 10.4974L8.55288 11.7348C8.69931 11.8813 8.69931 12.1187 8.55288 12.2652L7.15679 13.6612C6.27954 14.5385 6.27954 15.9659 7.15679 16.8432C8.03403 17.7204 9.46147 17.7205 10.3388 16.8432L11.7348 15.4471C11.8813 15.3007 12.1187 15.3007 12.2652 15.4471L13.5026 16.6846ZM11.7348 8.55284C11.8813 8.69928 12.1187 8.69928 12.2652 8.55284L13.6612 7.15679C14.5385 6.27945 15.9659 6.27951 16.8432 7.15679C17.7205 8.03407 17.7205 9.46147 16.8432 10.3387L15.4471 11.7348C15.3007 11.8812 15.3007 12.1187 15.4471 12.2651L16.6846 13.5026C16.831 13.649 17.0684 13.649 17.2149 13.5026L18.611 12.1065C20.463 10.2545 20.463 7.24104 18.611 5.38902C16.7589 3.53703 13.7455 3.53703 11.8935 5.38902L10.4974 6.78507C10.351 6.93151 10.351 7.16895 10.4974 7.31541L11.7348 8.55284ZM19.0732 19.7803L19.7803 19.0732C20.0732 18.7803 20.0732 18.3054 19.7803 18.0125L5.98745 4.21968C5.69455 3.92677 5.21967 3.92677 4.9268 4.21968L4.21968 4.9268C3.92677 5.2197 3.92677 5.69458 4.21968 5.98745L18.0125 19.7803C18.3054 20.0732 18.7803 20.0732 19.0732 19.7803Z" />
    );
  }

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={defaultClass}
      aria-hidden={ariaHidden}
    >
      {iconVariant}
    </svg>
  );
};

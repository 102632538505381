import React, { useRef, useMemo } from 'react';
import ReactQuill from 'react-quill';
import classNames from 'classnames';

import { ToolbarType, ValidationType } from '../../types';

import { Toolbar } from './Toolbar';

import 'react-quill/dist/quill.snow.css';
import './richTextEditor.css';

export interface IRichTextEditorProps extends React.HTMLAttributes<HTMLAllCollection> {
  value?: string;
  placeholder?: string;
  toolbarType?: ToolbarType;
  helperText?: string;
  validationType?: ValidationType.SUCCESS | ValidationType.WARNING | ValidationType.ERROR;
  disabled?: boolean;
  isResizable?: boolean;
  onContentChange?: (rawValue: string, displayValue: string) => void;
}

export const RichTextEditor: React.FC<IRichTextEditorProps> = ({
  value = '',
  placeholder,
  toolbarType = 'simple',
  helperText,
  validationType,
  disabled,
  isResizable,
  onContentChange,
}: IRichTextEditorProps) => {
  const quillRef = useRef<ReactQuill>(null);
  const helperTextClass = classNames('sui-py-1.5 sui-text-b4 sui-text-lightGray-darkest', {
    'sui-text-success': validationType === ValidationType.SUCCESS,
    'sui-text-warning': validationType === ValidationType.WARNING,
    'sui-text-danger-dark': validationType === ValidationType.ERROR,
  });

  const validationTypeClass = validationType ? `state-${validationType}` : ``;

  const quill = quillRef.current;
  const toolbar = quill?.getEditor().getModule('toolbar').container;
  if (toolbar) {
    toolbar.addEventListener('mousedown', (e: MouseEvent) => e.preventDefault());
  }

  const id = useMemo(() => Math.random().toString(16).slice(2), []);

  const contentChangeHandler = (value: string) => {
    const editorElement = document.createElement('div');
    editorElement.classList.add('ql-editor');
    editorElement.innerHTML = value;

    onContentChange && onContentChange(value, editorElement.outerHTML);
  };

  return (
    <div
      id={`rich-text-editor-${id}`}
      className={classNames(validationTypeClass, 'ql-toolbar-editor')}
    >
      <Toolbar id={id} type={toolbarType} disabled={!!disabled} />
      <ReactQuill
        ref={quillRef}
        value={value}
        placeholder={placeholder}
        theme="snow"
        readOnly={disabled}
        modules={{ toolbar: { container: `#toolbar-${id}` } }}
        className={classNames({ 'ql-resizable': isResizable })}
        onChange={contentChangeHandler}
      />
      <p className={helperTextClass}>{helperText}</p>
    </div>
  );
};

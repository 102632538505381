import React, { ReactNode, ReactElement } from 'react';
import classNames from 'classnames';

import { ButtonSize, StateSize } from '../../types';

export interface IStateProps {
  actions?: ReactElement;
  description?: ReactNode;
  icon: ReactElement;
  /**
   * @deprecated `ButtonSize` will be removed in a future release. Use `StateSize` instead in size props.
   */
  size?: ButtonSize | StateSize;
  title: string | ReactElement;
}

const sizeVariant = { ...StateSize, ...ButtonSize };

export const State: React.FC<IStateProps> = ({
  actions,
  description,
  icon,
  size = sizeVariant.MEDIUM,
  title,
}: IStateProps) => {
  let iconSize = 'sui-u-icon-md';
  let titleSize = 'sui-text-b1';
  let descriptionSize = 'sui-text-b4';

  if (size === sizeVariant.SMALL) {
    iconSize = 'sui-text-dh3 sui-mb-2';
    titleSize = 'sui-text-b2';
    descriptionSize = 'sui-text-b5';
  }

  if (size === sizeVariant.LARGE) {
    iconSize = 'sui-u-icon-lg';
    titleSize = 'sui-font-heading sui-text-dh2';
    descriptionSize = 'sui-text-b3';
  }

  const flexBaseClass = 'sui-flex sui-flex-col sui-items-center sui-justify-center';

  const iconClass = classNames('sui-u-icon-inline sui-text-lightGray-darkest', iconSize);

  const titleClass = classNames('sui-font-normal sui-text-darkGray sui-mb-0.5', titleSize);

  const descriptionClassName = classNames('sui-text-darkGray sui-text-center', descriptionSize);

  const actionsClass = classNames(flexBaseClass, 'sui-gap-1.5 sui-text-center sui-mt-6');

  return (
    <div className={classNames(flexBaseClass, 'sui-h-full')}>
      {React.cloneElement(icon, {
        className: iconClass,
      })}
      <p className={titleClass}>{title}</p>
      {description && <p className={descriptionClassName}>{description}</p>}

      {actions && <div className={actionsClass}>{actions}</div>}
    </div>
  );
};

import React from 'react';
import classNames from 'classnames';

import { IconOutline, IconTypes } from '../../../types';

export type IconSpinnerProps = {
  variant?: 'telus' | 'dotted' | 'inverted';
};

export const IconSpinner = ({
  className,
  width = 24,
  height = 24,
  ariaHidden = true,
  isClickable = false,
  variant = 'telus',
  outline,
}: IconTypes & IconSpinnerProps & IconOutline): React.ReactElement => {
  const defaultClass = classNames(
    'sui-fill-current sui-icon-spinner',
    { 'sui-pointer-events-none': !isClickable },
    className
  );
  let viewBox = '-4 -4 24 24';
  let iconVariant = (
    <>
      <path
        d="M8 -3.49691e-07C12.4183 -5.4282e-07 16 3.58172 16 8C16 12.4183 12.4183 16 8 16C3.58172 16 -1.56562e-07 12.4183 -3.49691e-07 8C-5.4282e-07 3.58172 3.58172 -1.56562e-07 8 -3.49691e-07ZM8 14.8C11.7555 14.8 14.8 11.7555 14.8 8C14.8 4.24446 11.7555 1.2 8 1.2C4.24446 1.2 1.2 4.24446 1.2 8C1.2 11.7555 4.24446 14.8 8 14.8Z"
        fill="#F5F5F5"
      />
      <path d="M8 -3.49691e-07C9.7324 -4.25417e-07 11.418 0.562354 12.8034 1.60252C14.1887 2.64269 15.199 4.10446 15.6823 5.76807C16.1657 7.43168 16.0959 9.20724 15.4836 10.8278C14.8712 12.4484 13.7493 13.8264 12.2866 14.7546C10.8239 15.6829 9.09939 16.1113 7.37233 15.9753C5.64527 15.8394 4.009 15.1465 2.7095 14.0009C1.41001 12.8552 0.517525 11.3187 0.166217 9.6223C-0.185091 7.92589 0.0237636 6.16129 0.761384 4.59376L1.84718 5.1047C1.2202 6.43709 1.04267 7.93701 1.34128 9.37895C1.6399 10.8209 2.39851 12.1269 3.50308 13.1008C4.60765 14.0746 5.99848 14.6635 7.46648 14.779C8.93448 14.8946 10.4003 14.5305 11.6436 13.7414C12.8869 12.9524 13.8405 11.7811 14.361 10.4036C14.8815 9.02615 14.9408 7.51693 14.53 6.10286C14.1192 4.68879 13.2604 3.44629 12.0829 2.56214C10.9053 1.678 9.47254 1.2 8 1.2L8 -3.49691e-07Z" />
    </>
  );
  if (variant === 'dotted') {
    viewBox = '0 0 24 24';
    iconVariant = outline ? (
      <path d="M13 5C13 5.55228 12.5523 6 12 6C11.4477 6 11 5.55228 11 5C11 4.44772 11.4477 4 12 4C12.5523 4 13 4.44772 13 5ZM12 18C11.4477 18 11 18.4477 11 19C11 19.5523 11.4477 20 12 20C12.5523 20 13 19.5523 13 19C13 18.4477 12.5523 18 12 18ZM20 12C20 11.4477 19.5523 11 19 11C18.4477 11 18 11.4477 18 12C18 12.5523 18.4477 13 19 13C19.5523 13 20 12.5523 20 12ZM6 12C6 11.4477 5.55228 11 5 11C4.44772 11 4 11.4477 4 12C4 12.5523 4.44772 13 5 13C5.55228 13 6 12.5523 6 12ZM7.05025 15.9497C6.49797 15.9497 6.05025 16.3975 6.05025 16.9497C6.05025 17.502 6.49797 17.9497 7.05025 17.9497C7.60253 17.9497 8.05025 17.502 8.05025 16.9497C8.05025 16.3975 7.60253 15.9497 7.05025 15.9497ZM16.9497 15.9497C16.3975 15.9497 15.9497 16.3975 15.9497 16.9497C15.9497 17.502 16.3975 17.9497 16.9497 17.9497C17.502 17.9497 17.9497 17.502 17.9497 16.9497C17.9497 16.3975 17.502 15.9497 16.9497 15.9497ZM7.05025 6.05025C6.49797 6.05025 6.05025 6.49797 6.05025 7.05025C6.05025 7.60253 6.49797 8.05025 7.05025 8.05025C7.60253 8.05025 8.05025 7.60253 8.05025 7.05025C8.05025 6.49797 7.60253 6.05025 7.05025 6.05025Z" />
    ) : (
      <path d="M13.5 5.5C13.5 6.32844 12.8284 7 12 7C11.1716 7 10.5 6.32844 10.5 5.5C10.5 4.67156 11.1716 4 12 4C12.8284 4 13.5 4.67156 13.5 5.5ZM12 17C11.1716 17 10.5 17.6716 10.5 18.5C10.5 19.3284 11.1716 20 12 20C12.8284 20 13.5 19.3284 13.5 18.5C13.5 17.6716 12.8284 17 12 17ZM18.5 10.5C17.6716 10.5 17 11.1716 17 12C17 12.8284 17.6716 13.5 18.5 13.5C19.3284 13.5 20 12.8284 20 12C20 11.1716 19.3284 10.5 18.5 10.5ZM7 12C7 11.1716 6.32844 10.5 5.5 10.5C4.67156 10.5 4 11.1716 4 12C4 12.8284 4.67156 13.5 5.5 13.5C6.32844 13.5 7 12.8284 7 12ZM7.40381 15.0962C6.57537 15.0962 5.90381 15.7677 5.90381 16.5962C5.90381 17.4246 6.57537 18.0962 7.40381 18.0962C8.23225 18.0962 8.90381 17.4246 8.90381 16.5962C8.90381 15.7678 8.23222 15.0962 7.40381 15.0962ZM16.5962 15.0962C15.7677 15.0962 15.0962 15.7677 15.0962 16.5962C15.0962 17.4246 15.7677 18.0962 16.5962 18.0962C17.4246 18.0962 18.0962 17.4246 18.0962 16.5962C18.0962 15.7678 17.4246 15.0962 16.5962 15.0962ZM7.40381 5.90381C6.57537 5.90381 5.90381 6.57537 5.90381 7.40381C5.90381 8.23225 6.57537 8.90381 7.40381 8.90381C8.23225 8.90381 8.90381 8.23225 8.90381 7.40381C8.90381 6.57537 8.23222 5.90381 7.40381 5.90381Z" />
    );
  }
  if (variant === 'inverted') {
    iconVariant = (
      <path d="M8 -3.49691e-07C9.7324 -4.25417e-07 11.418 0.562354 12.8034 1.60252C14.1887 2.64269 15.199 4.10446 15.6823 5.76807C16.1657 7.43168 16.0959 9.20724 15.4836 10.8278C14.8712 12.4484 13.7493 13.8264 12.2866 14.7546C10.8239 15.6829 9.09939 16.1113 7.37233 15.9753C5.64527 15.8394 4.009 15.1465 2.7095 14.0009C1.41001 12.8552 0.517525 11.3187 0.166217 9.6223C-0.185091 7.92589 0.0237636 6.16129 0.761384 4.59376L1.84718 5.1047C1.2202 6.43709 1.04267 7.93701 1.34128 9.37895C1.6399 10.8209 2.39851 12.1269 3.50308 13.1008C4.60765 14.0746 5.99848 14.6635 7.46648 14.779C8.93448 14.8946 10.4003 14.5305 11.6436 13.7414C12.8869 12.9524 13.8405 11.7811 14.361 10.4036C14.8815 9.02615 14.9408 7.51693 14.53 6.10286C14.1192 4.68879 13.2604 3.44629 12.0829 2.56214C10.9053 1.678 9.47254 1.2 8 1.2L8 -3.49691e-07Z" />
    );
  }

  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={defaultClass}
      aria-hidden={ariaHidden}
    >
      {iconVariant}
    </svg>
  );
};

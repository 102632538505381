import React, { useState } from 'react';
import classNames from 'classnames';

import { ButtonType, NavigationLinks } from '../../types';
import { TelusInternational } from '../Icon';
import { TextLink } from '../TextLink';

export interface IHeaderProps extends React.HTMLAttributes<HTMLDivElement> {
  logoUrl?: string;
  links?: NavigationLinks;
  isResponsive?: boolean;
  /**
   * @deprecated `isTelusWhite` is deprecated. There is no use of this props anymore.
   */
  isTelusWhite?: boolean;
}

export const Header: React.FC<IHeaderProps> = ({
  children,
  className,
  logoUrl,
  links,
  isResponsive,
  ...props
}: IHeaderProps) => {
  const [open, setOpen] = useState(false);

  const toggle = () => setOpen(!open);

  const defaultClass = classNames(
    'sui-sticky sui-top-0 sui-z-10 sui-w-full sui-flex sui-items-center lg:sui-gap-x-14',
    'sui-bg-white sui-text-darkGray sui-border-b sui-border-lightGray',
    isResponsive
      ? classNames(
          'sui-u-nav-responsive sui-px-base lg:sui-justify-start lg:sui-px-xl',
          !links && !children ? 'sui-justify-center' : 'sui-justify-between'
        )
      : 'sui-u-nav sui-px-xl',
    className
  );

  // Logo
  const headerLogo = (
    <TelusInternational variant="original" className="sui-h-logoSmall md:sui-h-logoBig" />
  );
  const headerLogoClass = classNames(
    'sui-c-nav-logo',
    isResponsive ? 'sui-order-2 lg:sui-order-1' : 'sui-order-1'
  );

  // Links
  const linksClass = classNames('sui-nav-links sui-order-1');
  const responsiveClass = classNames(
    'sui-text-darkGray sui--m-px',
    { active: open },
    isResponsive
      ? classNames(
          'sui-c-nav-items sui-absolute sui-flex-col sui-left-0 sui-w-full sui-pb-2',
          'lg:sui-relative lg:sui-flex lg:sui-top-auto lg:sui-flex-row lg:sui-w-auto lg:sui-pb-0 lg:sui-left-auto'
        )
      : 'sui-flex'
  );

  // Burger Icon
  const burgerMenu = classNames('lg:sui-hidden sui-u-nav-burger-white', { active: open });

  // children
  const childrenClass = classNames(
    'sui-c-nav-child sui-order-3',
    isResponsive ? 'lg:sui-ml-auto' : 'sui-ml-auto'
  );

  return (
    <header className={defaultClass} {...props} role="menu">
      <div className={headerLogoClass}>
        {logoUrl ? (
          <TextLink aria-label="logo" to={logoUrl}>
            {headerLogo}
          </TextLink>
        ) : (
          headerLogo
        )}
      </div>
      {links && (
        <div className={linksClass}>
          {isResponsive && <div className={burgerMenu} onClick={toggle} />}
          <div className={responsiveClass}>
            {links
              .filter(({ isHidden = false }) => !isHidden)
              .map(({ label, path, exact, external, isActive, isSamePage }, key) => {
                return external || isSamePage ? (
                  <TextLink
                    key={key}
                    as={ButtonType.NAVIGATION_WHITE}
                    className={isActive ? 'active' : ''}
                    href={path}
                    target={external ? '_blank' : '_self'}
                  >
                    {label}
                  </TextLink>
                ) : (
                  <TextLink
                    key={key}
                    as={ButtonType.NAVIGATION_WHITE}
                    className={isActive ? 'active' : ''}
                    to={path}
                    exact={exact}
                  >
                    {label}
                  </TextLink>
                );
              })}
          </div>
        </div>
      )}
      <div className={childrenClass}>{children}</div>
    </header>
  );
};

import classNames from 'classnames';
import React from 'react';

import { IconTypes } from '../../types';
import { IconSpinner } from '../Icon';

export interface ILoaderProps {
  className?: string;
  size?: 'xSmall' | 'small' | 'medium' | 'large' | 'xLarge' | 'xxLarge';
  height?: number;
  width?: number;
  color?: 'primary' | 'secondary' | 'white';
  inverted?: boolean;
  role?: string;
  wrapperClass?: string;
}

export const Loader: React.FC<ILoaderProps> = ({
  className,
  size = 'xSmall',
  color,
  height,
  width,
  inverted,
  role,
  wrapperClass,
}: ILoaderProps & IconTypes) => {
  let ch = height ?? 16;
  let cw = width ?? 16;
  let iconColor = '';
  if (size === 'small') {
    ch = height ?? 24;
    cw = width ?? 24;
  }
  if (size === 'medium') {
    ch = height ?? 32;
    cw = width ?? 32;
  }
  if (size === 'large') {
    ch = height ?? 40;
    cw = width ?? 40;
  }
  if (size === 'xLarge') {
    ch = height ?? 48;
    cw = width ?? 48;
  }
  if (size === 'xxLarge') {
    ch = height ?? 64;
    cw = width ?? 64;
  }

  if (color === 'primary') {
    iconColor = 'sui-text-primary';
  }
  if (color === 'secondary') {
    iconColor = 'sui-text-secondary';
  }

  const defaultStyle = classNames('sui-animate-spin', iconColor, className);
  const wrapperStyle = classNames('sui-inline-block', wrapperClass);
  return (
    <span role={role} className={wrapperStyle}>
      <IconSpinner
        variant={inverted ? 'inverted' : 'telus'}
        className={defaultStyle}
        height={ch}
        width={cw}
      />
    </span>
  );
};

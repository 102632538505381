import React from 'react';
import classNames from 'classnames';

import { IconTypes } from '../../../types';

export const IconHome = ({
  className,
  width = 24,
  height = 24,
  ariaHidden = true,
  isClickable = false,
}: IconTypes): React.ReactElement => {
  const defaultClass = classNames(
    'sui-fill-current',
    { 'sui-pointer-events-none': !isClickable },
    className
  );

  const iconVariant = (
    <path d="M12.2944 5.11041C12.1278 4.9632 11.875 4.9632 11.7056 5.11041L4.15056 11.7766C3.96724 11.9377 3.9478 12.221 4.11167 12.4043C4.27555 12.5877 4.55609 12.6071 4.73941 12.4432L5.77822 11.5266V16.9985C5.77822 18.2261 6.77259 19.2205 8.00029 19.2205H15.9997C17.2274 19.2205 18.2218 18.2261 18.2218 16.9985V11.5266L19.2606 12.4432C19.4439 12.6043 19.7245 12.5877 19.8883 12.4043C20.0522 12.221 20.0328 11.9405 19.8494 11.7766L12.2944 5.11041ZM6.66705 16.9985V10.7406L12 6.03534L17.333 10.7406V16.9985C17.333 17.7345 16.7358 18.3317 15.9997 18.3317H14.2221V13.8876C14.2221 13.3959 13.8249 12.9987 13.3332 12.9987H10.6668C10.1751 12.9987 9.77794 13.3959 9.77794 13.8876V18.3317H8.00029C7.26423 18.3317 6.66705 17.7345 6.66705 16.9985ZM10.6668 18.3317V13.8876H13.3332V18.3317H10.6668Z" />
  );
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={defaultClass}
      aria-hidden={ariaHidden}
    >
      {iconVariant}
    </svg>
  );
};

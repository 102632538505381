import React from 'react';
import classNames from 'classnames';

export interface IAvatarProps extends React.HTMLAttributes<HTMLDivElement> {
  /**
   * @deprecated `type` will be removed in a future release. Use `size` instead as this props deal with the size variation.
   */
  type?: 'small' | 'medium' | 'large' | 'xlarge' | 'xxlarge' | 'jumbo';
  size?: 'small' | 'medium' | 'large' | 'xlarge' | 'xxlarge' | 'jumbo';
  initials: string;
  userName?: string;
  userRole?: string;
  userCompany?: string;
  disabled?: boolean;
  imageUrl?: string;
  /**
   * @deprecated `isTelus` props will be removed in a future release. Remove this props from the component.
   */
  isTelus?: boolean;
}

const InnerAvatar = (
  {
    type,
    initials,
    className,
    disabled,
    size = 'medium',
    imageUrl,
    userName,
    userRole,
    userCompany,
    ...props
  }: IAvatarProps,
  ref: React.Ref<HTMLDivElement>
) => {
  let avatarTypeClass = 'sui-h-8 sui-w-8 sui-text-h4';
  let userNameStyle = classNames('sui-text-b4', imageUrl ? 'sui-font-medium' : 'sui-font-normal');
  const avatarSize = type || size;

  switch (avatarSize) {
    case 'small':
      avatarTypeClass = 'sui-h-6 sui-w-6 sui-text-h5';
      userNameStyle = classNames('sui-text-b4', imageUrl ? 'sui-font-medium' : 'sui-font-normal');
      break;
    case 'large':
      avatarTypeClass = 'sui-h-10 sui-w-10 sui-text-h3';
      userNameStyle = classNames('sui-text-b3', imageUrl ? 'sui-font-medium' : 'sui-font-normal');
      break;
    case 'xlarge':
      avatarTypeClass = 'sui-h-12 sui-w-12 sui-text-h2';
      userNameStyle = 'sui-text-b2 sui-font-medium';
      break;
    case 'xxlarge':
      avatarTypeClass = 'sui-h-16 sui-w-16 sui-text-h1';
      userNameStyle = 'sui-text-b1 sui-font-medium';
      break;
    case 'jumbo':
      avatarTypeClass = 'sui-h-20 sui-w-20 sui-text-dh3';
      userNameStyle = 'sui-text-b1 sui-font-medium';
      break;
    default:
      break;
  }

  const defaultClass = classNames('sui-flex sui-items-center  sui-justify-center', {
    'sui-gap-2': ['small', 'medium', 'large'].includes(avatarSize),
    'sui-gap-3': ['xlarge'].includes(avatarSize),
    'sui-gap-4': ['xxlarge', 'jumbo'].includes(avatarSize),
  });

  const imageClass = classNames(
    'sui-rounded-full sui-flex sui-items-center sui-justify-center sui-bg-lightGray sui-border sui-border-lightGray-darker sui-font-bold sui-uppercase sui-font-heading sui-text-secondary-darker',
    avatarTypeClass,
    className,
    disabled ? 'sui-opacity-50 sui-cursor-default' : 'sui-c-avatar sui-cursor-pointer'
  );

  const rightSideContainerClass = 'sui-flex sui-flex-col sui-items-start sui-text-center';

  const userNameClass = classNames(
    userNameStyle,
    'sui-text-center sui-text-darkGray-darkest sui-font-body',
    imageUrl ? 'active:sui-text-darkGray-darkest' : 'active:sui-text-secondary-darker',
    disabled && 'sui-text-lightGray-darkest'
  );

  const userRoleStyle = classNames(
    'sui-text-darkGray sui-font-normal sui-font-body',
    avatarSize === 'xlarge' ? 'sui-text-b4' : 'sui-text-b3'
  );

  const userCompanyStyle = 'sui-text-b3 sui-text-darkGray sui-font-normal sui-font-body';

  const hasUserInformation = userName || userRole || userCompany;

  return (
    <div className={defaultClass}>
      <div
        className={imageClass}
        ref={ref}
        role="img"
        aria-label="User Avatar"
        aria-disabled={disabled}
        {...props}
      >
        {imageUrl ? (
          <img src={imageUrl} draggable="false" className="sui-rounded-full" />
        ) : (
          <span className="sui-flex sui-flex-col sui-justify-center sui-text-center sui-shrink-0">
            {initials}
          </span>
        )}
      </div>
      {hasUserInformation && (
        <div className={rightSideContainerClass}>
          <p className={userNameClass}>{userName}</p>
          {['xlarge', 'xxlarge', 'jumbo'].includes(avatarSize) && (
            <p className={userRoleStyle}>{userRole}</p>
          )}
          {avatarSize === 'jumbo' && <p className={userCompanyStyle}>{userCompany}</p>}
        </div>
      )}
    </div>
  );
};
export const Avatar = React.forwardRef(InnerAvatar);

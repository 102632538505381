import React, { useRef, useState } from 'react';
import { usePopper } from 'react-popper';
import { Placement } from '@popperjs/core';
import classNames from 'classnames';

import { useClickOutsideEvent } from '../../hooks/useClickOutsideEvent';
import { Button, IButtonProps } from '../Button';

export interface IDropdownProps extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
  titleElement?: React.ReactElement;
  isButton?: IButtonProps;
  isIcon?: React.ReactChild;
  placement?: Placement;
  offset?: { horizontal: number; vertical: number };
  isTelus?: boolean;
  ariaLabel?: string;
  isUserProfile?: boolean;
}

export const Dropdown: React.FC<IDropdownProps> = ({
  titleElement,
  placement,
  offset = { horizontal: 0, vertical: 0 },
  children,
  isTelus,
  isButton,
  isIcon,
  ariaLabel,
  isUserProfile,
  className,
  ...props
}: IDropdownProps) => {
  const [open, setOpen] = useState(false);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const referenceRef = useRef<HTMLButtonElement>(null);
  const popperRef = useRef<HTMLDivElement>(null);

  const toggle = () => setOpen(!open);

  useClickOutsideEvent(wrapperRef, () => {
    // The if-statement below is to prevent "not wrapped in act"-warnings
    // during tests using the React Testing Library (see commit message for more info)
    if (open) {
      setOpen(false);
    }
  });

  const { horizontal, vertical } = offset;
  const { styles, attributes } = usePopper(referenceRef.current, popperRef.current, {
    placement: placement ? placement : isTelus ? 'bottom-end' : 'bottom',
    modifiers: [
      {
        name: 'offset',
        enabled: true,
        options: {
          offset: [horizontal, vertical],
        },
      },
    ],
  });

  function handleDropdownClick(e: { preventDefault: () => void }) {
    e.preventDefault();
    toggle();
  }

  const dropdownMenuClass = classNames(
    'sui-dropdown-menu sui-mt-1 sui-py-1 sui-rounded sui-shadow-depth8',
    {
      active: open,
    }
  );
  const telusDropdownMenuClass = classNames(
    'sui-bg-white sui-rounded sui-shadow-depth8 sui-text-black sui-py-1',
    {
      'sui-min-w-120': isIcon && !isUserProfile,
      'sui-min-w-max': isButton && !isUserProfile,
      'sui-min-w-300': !isButton && !isIcon && !isUserProfile,
      'sui-w-300': isUserProfile,
      'sui-px-base sui-py-3': !isButton && !isUserProfile && !isIcon,
    },
    open
      ? 'sui-opacity-100 sui-visible sui-block'
      : 'sui-opacity-0 sui-invisible sui-hidden sui--translate-x-5'
  );

  return isTelus ? (
    <div className={classNames('sui-relative sui-dropdown', className)} ref={wrapperRef} {...props}>
      {isButton ? (
        <Button ref={referenceRef} onClick={handleDropdownClick} {...isButton}>
          {isButton.children}
        </Button>
      ) : (
        <button
          className={classNames('sui-flex sui-items-center focus:sui-outline-none', {
            'sui-w-8 sui-h-8 sui-border sui-border-lightGray sui-rounded-sm sui-justify-center': isIcon,
          })}
          ref={referenceRef}
          onClick={handleDropdownClick}
          aria-label={ariaLabel}
        >
          {isIcon ? isIcon : titleElement}
        </button>
      )}

      <div
        ref={popperRef}
        style={styles.popper}
        role="sui-div-menu"
        className="sui-z-20"
        {...attributes.popper}
      >
        <div className={telusDropdownMenuClass} style={styles.offset}>
          {children}
        </div>
      </div>
    </div>
  ) : (
    <div className="sui-relative sui-dropdown" ref={wrapperRef}>
      <button
        className="sui-flex sui-items-center focus:sui-outline-none"
        ref={referenceRef}
        onClick={handleDropdownClick}
        aria-label={ariaLabel}
      >
        {titleElement}
      </button>
      <div ref={popperRef} style={styles.popper} role="sui-div-menu" {...attributes.popper}>
        <div className={dropdownMenuClass} style={styles.offset}>
          {children}
        </div>
      </div>
    </div>
  );
};

import React from 'react';
import classNames from 'classnames';

type CopyrightProps = {
  year: string;
  company: string;
};

export interface IFooterProps {
  copyright: CopyrightProps;
  children?: React.ReactNode;
  className?: string;
}

export const Footer: React.FC<IFooterProps> = ({
  copyright,
  children,
  className,
}: IFooterProps) => {
  const defaultClass = classNames('sui-c-footer sui-z-10', className);
  return (
    <footer className={defaultClass}>
      <div className="sui-flex-initial">
        © {copyright.year} <span className="sui-font-bold sui-text-h5">{copyright.company}</span>
      </div>
      {children && <div className="sui-c-footer-child">{children}</div>}
    </footer>
  );
};

import * as React from 'react';
import classNames from 'classnames';

import { IconAngle, IconCheck, IconTimes, IconExclamation } from '../Icon';
import { ValidationType } from '../../types';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const CustomValueContainer = ({ children, ...props }: any) => (
  <div
    className={props.cx(
      {
        'value-container': true,
        'value-container--is-multi': props.isMulti,
        'value-container--has-value': props.hasValue,
      },
      props.className
    )}
    {...props.innerProps}
  >
    <div
      className={props.cx({
        placeholder: true,
      })}
      {...props.innerProps}
    >
      {props.selectProps.placeholder}
    </div>
    {React.Children.map(children, child => (child && child.key !== 'placeholder' ? child : null))}
  </div>
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const CustomDropdownIndicator = (props: any) => (
  <div
    className={props.cx(
      {
        indicator: true,
        'dropdown-indicator': true,
      },
      props.className
    )}
    title="Toggle Dropdown"
    {...props.innerProps}
  >
    <IconAngle variant="down" isLight className="sui-text-darkGray-darker" />
  </div>
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const CustomClearIndicator = (props: any) => (
  <div
    className={props.cx(
      {
        indicator: true,
        'clear-indicator': true,
      },
      props.className
    )}
    title="Clear All"
    {...props.innerProps}
  >
    <IconTimes className="sui-text-darkGray-darker" />
  </div>
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const CustomOption = ({ isMulti, isSelected, ...props }: any) => {
  let iconCheckClassName =
    'input-subs sui-absolute sui-my-auto sui-top-0 sui-bottom-0 sui-inline-flex sui-justify-center sui-items-center sui-rounded-sm sui-border sui-flex-no-shrink';
  if (isMulti) {
    iconCheckClassName = `${iconCheckClassName} sui-w-base sui-h-base sui-left-4 sui-border-button-border sui-text-white`;
    if (isSelected) {
      iconCheckClassName = `${iconCheckClassName} sui-bg-primary sui-border-primary`;
    }
    if (props.isDisabled) {
      iconCheckClassName = `${iconCheckClassName} sui-w-base sui-h-base sui-left-4 sui-border-lightGray-darker`;
    }
  } else {
    iconCheckClassName = `${iconCheckClassName} sui-w-6 sui-h-6 sui-left-3 sui-border-transparent sui-text-primary`;
  }

  const checkbox = (
    <span className={iconCheckClassName}>
      <IconCheck width={isMulti ? 10 : 16} height={isMulti ? 8 : 12} />
    </span>
  );
  return (
    <div
      className={props.cx(
        {
          option: true,
          'option--is-disabled': props.isDisabled,
          'option--is-focused': props.isFocused,
          'option--is-selected': isSelected,
        },
        classNames(props.className, {
          'sui-font-bold': !isMulti && isSelected,
          'sui-cursor-pointer hover:sui-bg-lightGray-lightest': !props.isDisabled,
        })
      )}
      ref={props.innerRef}
      {...props.innerProps}
    >
      {isMulti ? checkbox : isSelected ? checkbox : ''} {props.label}
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const CustomMultiValueRemove = (props: any) => (
  <div
    title={`Remove ${props.data.label}`}
    {...props.innerProps}
    className="sui-w-6 sui-h-6 sui-flex sui-justify-center sui-items-center sui-rounded-3xl sui-cursor-pointer hover:sui-bg-input-clear"
  >
    <IconTimes height={11} width={11} />
  </div>
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const CustomIndicatorsContainer = ({ validationIcon, validationType, ...props }: any) => {
  let iconType = null;
  let iconColor = '';

  if (validationType === ValidationType.WARNING) {
    iconType = <IconExclamation variant="circle" className="sui-mr-3" />;
    iconColor = 'sui-text-warning';
  }
  if (validationType === ValidationType.SUCCESS) {
    iconType = <IconCheck variant="circle" className="sui-mr-3" />;
    iconColor = 'sui-text-success';
  }
  if (validationType === ValidationType.ERROR) {
    iconColor = 'sui-text-danger';
    iconType = <IconExclamation variant="triangle" className="sui-mr-3" />;
  }

  return (
    <>
      {validationIcon ? <div className={iconColor}>{iconType}</div> : null}
      <div
        className={props.cx(
          {
            indicators: true,
          },
          classNames(props.className, 'sui-flex sui-items-center sui-self-stretch')
        )}
        {...props.innerProps}
      >
        {React.Children.map(props.children, child => (child ? child : null))}
      </div>
    </>
  );
};

import React from 'react';
import classNames from 'classnames';

import { IconTypes } from '../../../types';

type IconCircleQuestionProps = {
  isLight?: boolean;
};

export const IconCircleQuestion = ({
  className,
  width = 24,
  height = 24,
  ariaHidden = true,
  isLight,
  isClickable = false,
}: IconTypes & IconCircleQuestionProps): React.ReactElement => {
  const defaultClass = classNames(
    'sui-fill-current',
    { 'sui-pointer-events-none': !isClickable },
    className
  );

  const iconVariant = isLight ? (
    <path d="M12 14.7097C11.5012 14.7097 11.0968 15.1141 11.0968 15.6129C11.0968 16.1117 11.5012 16.5161 12 16.5161C12.4988 16.5161 12.9032 16.1117 12.9032 15.6129C12.9032 15.1141 12.4988 14.7097 12 14.7097ZM12.2474 13.9355H11.7313C11.5175 13.9355 11.3442 13.7622 11.3442 13.5484V13.5361C11.3442 11.267 13.8423 11.4839 13.8423 10.0713C13.8423 9.42564 13.2693 8.77419 11.9894 8.77419C11.0492 8.77419 10.5615 9.08545 10.0793 9.69974C9.95326 9.86039 9.72274 9.89313 9.55519 9.77639L9.13152 9.48123C8.95006 9.35481 8.91023 9.10152 9.04619 8.92713C9.7309 8.04884 10.5433 7.48387 11.9894 7.48387C13.6771 7.48387 15.1326 8.44358 15.1326 10.0713C15.1326 12.246 12.6345 12.131 12.6345 13.5361V13.5484C12.6345 13.7622 12.4612 13.9355 12.2474 13.9355ZM12 5.03226C15.8265 5.03226 18.9677 8.13145 18.9677 12C18.9677 15.8481 15.8513 18.9677 12 18.9677C8.15342 18.9677 5.03226 15.8528 5.03226 12C5.03226 8.15474 8.14845 5.03226 12 5.03226ZM12 4C7.58203 4 4 7.58332 4 12C4 16.4193 7.58203 20 12 20C16.418 20 20 16.4193 20 12C20 7.58332 16.418 4 12 4Z" />
  ) : (
    <path d="M20 12C20 16.4193 16.418 20 12 20C7.58203 20 4 16.4193 4 12C4 7.58332 7.58203 4 12 4C16.418 4 20 7.58332 20 12ZM12.2147 6.64516C10.4567 6.64516 9.33548 7.38571 8.45503 8.70187C8.34097 8.87239 8.37913 9.10236 8.54261 9.22632L9.66194 10.075C9.82984 10.2024 10.0691 10.1721 10.1995 10.0066C10.7758 9.27568 11.1709 8.85184 12.048 8.85184C12.707 8.85184 13.5221 9.27597 13.5221 9.915C13.5221 10.3981 13.1233 10.6462 12.4726 11.011C11.7138 11.4364 10.7097 11.9658 10.7097 13.2903V13.4194C10.7097 13.6331 10.883 13.8065 11.0968 13.8065H12.9032C13.117 13.8065 13.2903 13.6331 13.2903 13.4194V13.3764C13.2903 12.4582 15.9737 12.42 15.9737 9.93548C15.9737 8.06445 14.0329 6.64516 12.2147 6.64516ZM12 14.6452C11.1818 14.6452 10.5161 15.3108 10.5161 16.129C10.5161 16.9472 11.1818 17.6129 12 17.6129C12.8182 17.6129 13.4839 16.9472 13.4839 16.129C13.4839 15.3108 12.8182 14.6452 12 14.6452Z" />
  );
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={defaultClass}
      aria-hidden={ariaHidden}
    >
      {iconVariant}
    </svg>
  );
};

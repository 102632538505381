import React, { Fragment, ReactElement } from 'react';
import classNames from 'classnames';
import { Dialog, Transition } from '@headlessui/react';

import { IconTimes } from '../Icon';
import { ButtonVariant, ModalSize, ModalVariant } from '../../types';
import { Button } from '../Button';

export interface IModalProps {
  className?: string;
  actions?: ReactElement;
  children: React.ReactChild;
  closeIcon?: boolean;
  isOpen: boolean;
  icon?: React.ReactElement;
  closeModal: () => void;
  title: string;
  size?: ModalSize;
  variant?: ModalVariant;
  isScrolled?: boolean;
  isDivider?: boolean;
}

const ModalSizeToClass: Record<ModalSize, string> = {
  [ModalSize.SMALL]: 'sui-u-modal-small',
  [ModalSize.LARGE]: 'sui-u-modal-large',
  [ModalSize.XLARGE]: 'sui-u-modal-xlarge',
  [ModalSize.MEDIUM]: 'sui-u-modal-medium',
};

export const Modal: React.FC<IModalProps> = ({
  title,
  className,
  size = ModalSize.MEDIUM,
  isOpen = false,
  closeModal,
  children,
  icon,
  closeIcon,
  variant = ModalVariant.DIALOG,
  actions,
  isScrolled = true,
  isDivider = false,
}: IModalProps) => {
  const defaultClass = classNames(
    'sui-relative sui-inline-flex sui-flex-col sui-text-left sui-align-middle sui-transition-all sui-transform sui-bg-white sui-shadow-xl',
    {
      'sui-h-screen sui-u-side-panel': variant === ModalVariant.SIDE_PANEL,
      'sui-w-full sui-h-screen sui-p-md': variant === ModalVariant.FULL_SCREEN,
      'sui-max-h-modalDialog': isScrolled && variant === ModalVariant.DIALOG,
      'sui-overflow-hidden': isScrolled,
    },
    variant !== ModalVariant.FULL_SCREEN && ModalSizeToClass[size],
    className
  );

  const dialogClass = classNames(
    'sui-min-h-screen',
    variant === ModalVariant.SIDE_PANEL ? 'sui-text-right' : 'sui-text-center'
  );

  const dialogTitleClass = classNames('sui-flex sui-items-center sui-justify-between', {
    'sui-px-md sui-py-md': variant === ModalVariant.SIDE_PANEL && isDivider,
    'sui-px-md sui-pt-md sui-pb-4': variant === ModalVariant.SIDE_PANEL && !isDivider,
    'sui-pb-md': variant !== ModalVariant.SIDE_PANEL && variant !== ModalVariant.DIALOG,
    'sui-px-6 sui-pt-6 sui-pb-4': variant === ModalVariant.DIALOG,
  });

  const actionsContainerClass = classNames('sui-flex sui-justify-end', {
    'sui-pt-md': variant !== ModalVariant.DIALOG,
    'sui-border-t sui-border-lightGray sui-p-md': variant === ModalVariant.SIDE_PANEL && isDivider,
    'sui-p-md': variant === ModalVariant.SIDE_PANEL && !isDivider,
    'sui-px-6 sui-pt-4 sui-pb-6': variant === ModalVariant.DIALOG,
  });

  const enterLeaveClass = classNames('sui-duration-300', {
    'sui-transition sui-ease-in-out sui-transform': variant === ModalVariant.SIDE_PANEL,
  });

  const enterClass = classNames(enterLeaveClass, {
    'sui-ease-out': variant !== ModalVariant.SIDE_PANEL,
  });

  const enterFromLeaveToClass = variant !== ModalVariant.SIDE_PANEL && 'sui-opacity-0 sui-scale-95';

  const enterFromClass = classNames(enterFromLeaveToClass, {
    'sui-translate-x-1/4': variant === ModalVariant.SIDE_PANEL,
  });

  const enterToLeaveFromClass = classNames({
    'sui-opacity-100 sui-scale-100': variant !== ModalVariant.SIDE_PANEL,
    'sui-translate-x-0': variant === ModalVariant.SIDE_PANEL,
  });

  const leaveClass = classNames(enterLeaveClass, {
    'sui-ease-in': variant !== ModalVariant.SIDE_PANEL,
  });

  const leaveToClass = classNames(enterFromLeaveToClass, {
    'sui-translate-x-full': variant === ModalVariant.SIDE_PANEL,
  });
  const childrenContainerClass = classNames({
    'sui-overflow-y-auto': isScrolled,
    'sui-pr-5':
      isScrolled && variant !== ModalVariant.SIDE_PANEL && variant !== ModalVariant.DIALOG,
    'sui-h-screen': isScrolled && variant === ModalVariant.FULL_SCREEN,
    'sui-flex-grow': isScrolled && variant === ModalVariant.SIDE_PANEL && actions,
    'sui-min-h-modalSidePanel sui-max-h-modalSidePanel':
      isScrolled && variant === ModalVariant.SIDE_PANEL && !actions,
    'sui-border-t sui-border-lightGray': variant === ModalVariant.SIDE_PANEL && isDivider,
    'sui-px-6 sui-py-2': variant === ModalVariant.DIALOG,
  });

  const iconClass = classNames({
    'sui-inline-block sui-mr-sm': variant !== ModalVariant.DIALOG,
    'sui-inline-flex sui-w-9 sui-h-9 sui-items-center': variant === ModalVariant.DIALOG,
  });
  const titleClass = 'sui-inline-block sui-font-heading sui-text-h2 sui-font-bold';

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="sui-fixed sui-inset-0 sui-z-50" onClose={closeModal}>
        <div className={dialogClass}>
          <Transition.Child
            as={Fragment}
            enter="sui-ease-out sui-duration-300"
            enterFrom="sui-opacity-0"
            enterTo="sui-opacity-10"
            leave="sui-ease-in sui-duration-200"
            leaveFrom="sui-opacity-10"
            leaveTo="sui-opacity-0"
          >
            <Dialog.Overlay className="sui-fixed sui-inset-0 sui-opacity-50 sui-bg-black" />
          </Transition.Child>
          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="sui-inline-block sui-h-screen sui-align-middle" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter={enterClass}
            enterFrom={enterFromClass}
            enterTo={enterToLeaveFromClass}
            leave={leaveClass}
            leaveFrom={enterToLeaveFromClass}
            leaveTo={leaveToClass}
          >
            <div className={defaultClass}>
              <Dialog.Title as="div" className={dialogTitleClass}>
                <div className="sui-order-first sui-flex sui-items-center sui-justify-center sui-gap-2">
                  {icon && <span className={iconClass}> {icon} </span>}
                  <h2 className={titleClass}>{title}</h2>
                </div>

                <div className="sui-w-8 sui-h-8 sui-order-last sui-p-1 sui-flex sui-items-center">
                  {closeIcon && (
                    <Button
                      variant={ButtonVariant.SECONDARY}
                      border="none"
                      hasIcon={{ icon: <IconTimes width={20} height={20} /> }}
                      onClick={closeModal}
                      ariaLabel="Close"
                    />
                  )}
                </div>
              </Dialog.Title>
              <div className={childrenContainerClass}>{children}</div>
              {actions && <div className={actionsContainerClass}>{actions}</div>}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

import React from 'react';
import classNames from 'classnames';

import { TextLink } from '../TextLink';
import { ButtonSize, ButtonType } from '../../types';

export type BreadcrumbsItems = ReadonlyArray<{
  label: string;
  target?: string;
}>;

export interface IBreadcrumbsProps extends React.HTMLAttributes<HTMLDivElement> {
  items?: BreadcrumbsItems;
  /**
   * @deprecated `className` is deprecated. This props will be removed soon.
   */
  className?: string;
  /**
   * @deprecated `isTelus` is deprecated. Please remove this one.
   */
  isTelus?: boolean;
}

export const Breadcrumbs: React.FC<IBreadcrumbsProps> = ({
  items,
  ...props
}: IBreadcrumbsProps) => {
  const defaultStyle = classNames('sui-flex sui-items-center');
  return (
    <div {...props} className={defaultStyle}>
      {items?.map((item, id, { length }) => {
        if (id + 1 === length) {
          return (
            <span key={id} className={'sui-text-b5 sui-text-lightGray-darkest'}>
              {item.label}
            </span>
          );
        }

        return (
          <span key={id} className="sui-flex sui-items-center">
            <>
              <TextLink to={item.target} as={ButtonType.BREADCRUMBS} size={ButtonSize.SMALL}>
                {item.label}
              </TextLink>
              <span className="sui-text-b5 sui-text-lightGray sui-px-2">/</span>
            </>
          </span>
        );
      })}
    </div>
  );
};

import React from 'react';
import classNames from 'classnames';

import { Avatar } from '../Avatar';
import { LogoOnly } from '../Icon';
import { ButtonType, NavBarItem } from '../../types';
import { UncontrolledTooltip } from '../Tooltip';
import { TextLink } from '../TextLink';

export interface INavBarProps extends React.HTMLAttributes<HTMLDivElement> {
  avatar: {
    userImageUrl?: string;
    userInitials: string;
    onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
  };
  mainNavBarItems: ReadonlyArray<NavBarItem>;
  secondaryNavBarItems: ReadonlyArray<NavBarItem>;
}

const flexBoxBaseClass = 'sui-flex sui-flex-col sui-justify-center sui-items-center';

const parentContainerClass = classNames(
  flexBoxBaseClass,
  'sui-justify-between sui-w-14 sui-h-screen sui-py-2 sui-bg-secondary-navDarkest sui-text-white sui-overflow-y-auto'
);

const maintopNaveBarItemsClass = classNames(flexBoxBaseClass, 'sui-w-full');

const logo = classNames(
  flexBoxBaseClass,
  'sui-w-full sui-py-5 sui-pl-2 sui-pr-1.5 sui-cursor-auto'
);
const mainNavBarItemsContainer = classNames(flexBoxBaseClass, 'sui-w-full sui-pt-4');

const secondaryNavBarItemsClass = classNames(flexBoxBaseClass, 'sui-w-full');

const itemContainerBaseClass = classNames(flexBoxBaseClass, 'sui-w-full sui-h-14');

const itemBaseClass = classNames(
  flexBoxBaseClass,
  'sui-h-10 sui-w-10 sui-rounded-lg sui-outline-none sui-border-none sui-border-transparent',
  'hover:sui-bg-black-30',
  'focus-visible:sui-border-lightGray-darker focus-visible:sui-bg-black-30',
  'active:sui-border active:sui-border-transparent active:sui-bg-white-25'
);

const NavIcon = ({ item }: { item: NavBarItem }) => {
  const hasNumber = typeof item.notification === 'number';

  const characterCount = hasNumber && (item.notification?.toString().length ?? 0);

  const notificationWrapperClass = classNames(
    'sui-absolute sui-top-1 sui-px-1',
    'sui-rounded-full sui-bg-danger',
    'sui-text-h6 sui-font-heading sui-font-bold sui-text-center',
    !hasNumber && 'sui-w-2 sui-h-2',
    characterCount === 1 && 'sui-w-4 sui-h-4',
    !hasNumber
      ? 'sui-right-1'
      : characterCount > 2
      ? 'sui--right-1.5'
      : characterCount === 1
      ? 'sui-right-1.5'
      : 'sui--right-0.5'
  );

  return (
    <span className="sui-text-white sui-relative">
      {item.icon}
      {item.notification && (
        <>
          <div className={notificationWrapperClass}>
            {hasNumber && characterCount > 2 ? '99+' : item.notification}
          </div>
        </>
      )}
    </span>
  );
};

const NavItems = ({ items }: { items: ReadonlyArray<NavBarItem> }) => {
  return (
    <>
      {items
        .filter(({ isHidden = false }) => !isHidden)
        .map((item, index) => (
          <div className={itemContainerBaseClass} key={index}>
            <UncontrolledTooltip
              content={item.title}
              placement="right"
              offset={{ vertical: 12, horizontal: 0 }}
            >
              {item.isExternal || item.isSamePage ? (
                <TextLink
                  as={ButtonType.NAVIGATION_LINK}
                  className={classNames(
                    itemBaseClass,
                    item.isActive && 'sui-border sui-border-transparent sui-bg-white-25'
                  )}
                  href={item.path}
                  target={item.isSamePage ? '_self' : '_blank'}
                  aria-label={item.title}
                >
                  <NavIcon item={item} />
                </TextLink>
              ) : (
                <TextLink
                  as={ButtonType.NAVIGATION_LINK}
                  className={classNames(
                    itemBaseClass,
                    item.isActive && 'sui-border sui-border-transparent sui-bg-white-25'
                  )}
                  to={item.path}
                  exact={item.exact}
                  aria-label={item.title}
                >
                  <NavIcon item={item} />
                </TextLink>
              )}
            </UncontrolledTooltip>
          </div>
        ))}
    </>
  );
};
export const NavBar: React.FC<INavBarProps> = ({
  avatar,
  mainNavBarItems,
  secondaryNavBarItems,
}: INavBarProps) => {
  const headerLogo = <LogoOnly width={42} height={20} />;
  const userAvatar = <Avatar imageUrl={avatar.userImageUrl} initials={avatar.userInitials} />;

  return (
    <div className={parentContainerClass}>
      <div className={maintopNaveBarItemsClass}>
        <div className={logo}>{headerLogo}</div>

        <div className={mainNavBarItemsContainer}>
          <NavItems items={mainNavBarItems} />
        </div>
      </div>

      <div className={secondaryNavBarItemsClass}>
        <NavItems items={secondaryNavBarItems} />

        <div className={itemContainerBaseClass}>
          <div onClick={avatar.onClick}>{userAvatar}</div>
        </div>
      </div>
    </div>
  );
};

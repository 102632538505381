import { WeekDates } from '../components/DatePicker/Week';
import { DateRange } from '../types';

const weekdayIndicesVariants = {
  sundayBased: [0, 1, 2, 3, 4, 5, 6],
  mondayBased: [1, 2, 3, 4, 5, 6, 0],
};
const DAYS_IN_A_WEEK = 7;
const MONTHS_IN_A_YEAR = 12;
const weekdayNamesShort = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
const monthNamesShort = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'June',
  'July',
  'Aug',
  'Sept',
  'Oct',
  'Nov',
  'Dec',
];
const monthNamesLong = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];
const currentDate = removeHoursFromDate(new Date());
const currentYear = currentDate.getFullYear();
const currentMonthIndex = currentDate.getMonth();
const currentFirstOfMonthDate = new Date(currentYear, currentMonthIndex, 1);

const getCalendarViewWeeks = (
  monthDate: Date,
  isMondayBased?: boolean
): ReadonlyArray<WeekDates> => {
  const firstDateOfMonth = new Date(monthDate.getFullYear(), monthDate.getMonth(), 1);
  const daysBeforeFirst = isMondayBased
    ? getModulo(firstDateOfMonth.getDay() - 1, DAYS_IN_A_WEEK)
    : firstDateOfMonth.getDay();
  const daysInMonth = new Date(monthDate.getFullYear(), monthDate.getMonth() + 1, 0).getDate();
  const daysInCalendarMonth = daysInMonth + daysBeforeFirst;
  const numberOfWeeksInMonth = Math.ceil(daysInCalendarMonth / DAYS_IN_A_WEEK);

  let firstDateOfWeek = addDaysToDate(firstDateOfMonth, -daysBeforeFirst);
  const result = [];
  for (let weekIndex = 0; weekIndex < numberOfWeeksInMonth; weekIndex++) {
    const weekDates: WeekDates = [
      addDaysToDate(firstDateOfWeek, 0),
      addDaysToDate(firstDateOfWeek, 1),
      addDaysToDate(firstDateOfWeek, 2),
      addDaysToDate(firstDateOfWeek, 3),
      addDaysToDate(firstDateOfWeek, 4),
      addDaysToDate(firstDateOfWeek, 5),
      addDaysToDate(firstDateOfWeek, 6),
    ];
    result.push(weekDates);
    firstDateOfWeek = addDaysToDate(firstDateOfWeek, 7);
  }
  return result as ReadonlyArray<WeekDates>;
};

const getLastDayOfMonth = (date: Date): Date => {
  const lastDayOfCurrentMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0);
  return lastDayOfCurrentMonth;
};

type WeekdayLabels = Readonly<[string, string, string, string, string, string, string]>;
const getWeekdayLabels = (isMondayBased = false): WeekdayLabels => {
  const weekdayIndices = isMondayBased
    ? weekdayIndicesVariants.mondayBased
    : weekdayIndicesVariants.sundayBased;
  const weekdayLabels: WeekdayLabels = [
    weekdayNamesShort[weekdayIndices[0]],
    weekdayNamesShort[weekdayIndices[1]],
    weekdayNamesShort[weekdayIndices[2]],
    weekdayNamesShort[weekdayIndices[3]],
    weekdayNamesShort[weekdayIndices[4]],
    weekdayNamesShort[weekdayIndices[5]],
    weekdayNamesShort[weekdayIndices[6]],
  ];
  return weekdayLabels;
};

function addDaysToDate(date: Date, days: number): Date {
  const result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}
const getFirstOfXMonthsLater = (date: Date, months: number): Date => {
  return new Date(date.getFullYear(), date.getMonth() + months, 1);
};
function removeHoursFromDate(date: Date): Date {
  const result = new Date(date);
  result.setHours(0, 0, 0, 0);
  return new Date(result);
}

const isDateInRange = (
  date: Date,
  range: DateRange,
  leftIncluded = false,
  rightIncluded = false
): boolean => {
  if (!range.start && !range.end) {
    return false;
  }

  let leftCondition = true;
  let rightCondition = true;
  if (range.start) {
    leftCondition = leftIncluded ? range.start <= date : range.start < date;
  }
  if (range.end) {
    rightCondition = rightIncluded ? range.end >= date : range.end > date;
  }

  return leftCondition && rightCondition;
};
const isBoundedRange = (range: DateRange): range is { start: Date; end: Date } => {
  return !!range.start && !!range.end;
};

function getModulo(dividend: number, divisor: number): number {
  return ((dividend % divisor) + divisor) % divisor;
}

export {
  DAYS_IN_A_WEEK,
  MONTHS_IN_A_YEAR,
  weekdayNamesShort,
  monthNamesShort,
  monthNamesLong,
  currentDate,
  currentYear,
  currentMonthIndex,
  currentFirstOfMonthDate,
  getCalendarViewWeeks,
  getLastDayOfMonth,
  getWeekdayLabels,
  addDaysToDate,
  getFirstOfXMonthsLater,
  removeHoursFromDate,
  isDateInRange,
  isBoundedRange,
  getModulo,
};

import React from 'react';
import classNames from 'classnames';

export interface IRadioGroupProps extends React.HTMLAttributes<HTMLDivElement> {
  name: string;
  children: ReadonlyArray<React.ReactElement>;
  className?: string;
  inLine?: boolean;
}

export const RadioGroup: React.FC<IRadioGroupProps> = ({
  children,
  inLine,
  name,
  className,
}: IRadioGroupProps) => {
  const defaultClass = classNames('sui-flex', { 'sui-flex-col': !inLine }, className);
  return (
    <div className={defaultClass}>
      {children.map((item, index) => {
        return React.cloneElement(item, {
          ...item.props,
          key: index,
          name: name,
        });
      })}
    </div>
  );
};

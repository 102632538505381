import React from 'react';
import classNames from 'classnames';

import { IconTypes } from '../../../types';

type IconTimesProps = {
  variant?: 'default' | 'circle' | 'square';
  isLight?: boolean;
};

export const IconTimes = ({
  className,
  width = 24,
  height = 24,
  isLight,
  variant = 'default',
  ariaHidden = true,
  isClickable = false,
}: IconTypes & IconTimesProps): React.ReactElement => {
  const defaultClass = classNames(
    'sui-fill-current',
    { 'sui-pointer-events-none': !isClickable },
    className
  );
  let iconVariant = (
    // Default
    <path d="M13.6973 12.0003L18.8255 6.87209L19.883 5.81456C20.039 5.65855 20.039 5.40504 19.883 5.24904L18.7515 4.1175C18.5955 3.9615 18.3419 3.9615 18.1859 4.1175L12.0003 10.3032L5.81456 4.117C5.65855 3.961 5.40504 3.961 5.24904 4.117L4.117 5.24854C3.961 5.40454 3.961 5.65805 4.117 5.81406L10.3032 12.0003L4.117 18.1859C3.961 18.3419 3.961 18.5955 4.117 18.7515L5.24854 19.883C5.40454 20.039 5.65805 20.039 5.81406 19.883L12.0003 13.6973L17.1284 18.8255L18.1859 19.883C18.3419 20.039 18.5955 20.039 18.7515 19.883L19.883 18.7515C20.039 18.5955 20.039 18.3419 19.883 18.1859L13.6973 12.0003Z" />
  );

  if (variant === 'square') {
    iconVariant = (
      <path d="M15.0327 12L19.5814 7.45136C20.1395 6.89318 20.1395 5.98818 19.5814 5.42955L18.5705 4.41864C18.0123 3.86045 17.1073 3.86045 16.5486 4.41864L12 8.96727L7.45136 4.41864C6.89318 3.86045 5.98818 3.86045 5.42955 4.41864L4.41864 5.42955C3.86045 5.98773 3.86045 6.89273 4.41864 7.45136L8.96727 12L4.41864 16.5486C3.86045 17.1068 3.86045 18.0118 4.41864 18.5705L5.42955 19.5814C5.98773 20.1395 6.89318 20.1395 7.45136 19.5814L12 15.0327L16.5486 19.5814C17.1068 20.1395 18.0123 20.1395 18.5705 19.5814L19.5814 18.5705C20.1395 18.0123 20.1395 17.1073 19.5814 16.5486L15.0327 12Z" />
    );
  }
  if (variant === 'square' && isLight) {
    iconVariant = (
      <path d="M13.6973 12.0003L18.8255 6.87209L19.883 5.81456C20.039 5.65855 20.039 5.40504 19.883 5.24904L18.7515 4.1175C18.5955 3.9615 18.3419 3.9615 18.1859 4.1175L12.0003 10.3032L5.81456 4.117C5.65855 3.961 5.40504 3.961 5.24904 4.117L4.117 5.24854C3.961 5.40454 3.961 5.65805 4.117 5.81406L10.3032 12.0003L4.117 18.1859C3.961 18.3419 3.961 18.5955 4.117 18.7515L5.24854 19.883C5.40454 20.039 5.65805 20.039 5.81406 19.883L12.0003 13.6973L17.1284 18.8255L18.1859 19.883C18.3419 20.039 18.5955 20.039 18.7515 19.883L19.883 18.7515C20.039 18.5955 20.039 18.3419 19.883 18.1859L13.6973 12.0003Z" />
    );
  }
  if (variant === 'circle') {
    iconVariant = (
      <path d="M12 4C7.58065 4 4 7.58065 4 12C4 16.4194 7.58065 20 12 20C16.4194 20 20 16.4194 20 12C20 7.58065 16.4194 4 12 4ZM15.9226 14.1C16.0742 14.2516 16.0742 14.4968 15.9226 14.6484L14.6452 15.9226C14.4935 16.0742 14.2484 16.0742 14.0968 15.9226L12 13.8065L9.9 15.9226C9.74839 16.0742 9.50323 16.0742 9.35161 15.9226L8.07742 14.6452C7.92581 14.4935 7.92581 14.2484 8.07742 14.0968L10.1935 12L8.07742 9.9C7.92581 9.74839 7.92581 9.50323 8.07742 9.35161L9.35484 8.07419C9.50645 7.92258 9.75161 7.92258 9.90323 8.07419L12 10.1935L14.1 8.07742C14.2516 7.92581 14.4968 7.92581 14.6484 8.07742L15.9258 9.35484C16.0774 9.50645 16.0774 9.75161 15.9258 9.90323L13.8065 12L15.9226 14.1Z" />
    );
  }
  if (variant === 'circle' && isLight) {
    iconVariant = (
      <path d="M12 4C7.58065 4 4 7.58065 4 12C4 16.4194 7.58065 20 12 20C16.4194 20 20 16.4194 20 12C20 7.58065 16.4194 4 12 4ZM12 18.9677C8.17097 18.9677 5.03226 15.8677 5.03226 12C5.03226 8.17097 8.13226 5.03226 12 5.03226C15.829 5.03226 18.9677 8.13226 18.9677 12C18.9677 15.829 15.8677 18.9677 12 18.9677ZM15.0581 9.76452L12.8226 12L15.0581 14.2355C15.2097 14.3871 15.2097 14.6323 15.0581 14.7839L14.7839 15.0581C14.6323 15.2097 14.3871 15.2097 14.2355 15.0581L12 12.8226L9.76452 15.0581C9.6129 15.2097 9.36774 15.2097 9.21613 15.0581L8.94194 14.7839C8.79032 14.6323 8.79032 14.3871 8.94194 14.2355L11.1774 12L8.94194 9.76452C8.79032 9.6129 8.79032 9.36774 8.94194 9.21613L9.21613 8.94194C9.36774 8.79032 9.6129 8.79032 9.76452 8.94194L12 11.1774L14.2355 8.94194C14.3871 8.79032 14.6323 8.79032 14.7839 8.94194L15.0581 9.21613C15.2065 9.36774 15.2065 9.6129 15.0581 9.76452Z" />
    );
  }
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={defaultClass}
      aria-hidden={ariaHidden}
    >
      {iconVariant}
    </svg>
  );
};

import * as React from 'react';
import classNames from 'classnames';

import { ValidationType } from '../../types';
import { Label } from '../Label';

import {
  CustomClearIndicator,
  CustomDropdownIndicator,
  CustomIndicatorsContainer,
  CustomMultiValueRemove,
  CustomOption,
  CustomValueContainer,
} from './InputDropdownLionbridge';
import {
  TelusCustomOption,
  TelusIndicatorsContainer,
  TelusMultiValueRemove,
} from './InputDropdownTelus';

export interface IInputDropdownProps extends React.InputHTMLAttributes<HTMLInputElement> {
  children: React.ReactElement;
  validationIcon?: boolean;
  validationType?: ValidationType;
  isTelus?: boolean;
  label?: string;
  required?: boolean;
  tooltip?: React.ReactChild;
  isFixedHeight?: boolean;
}

export const InputDropdown: React.FC<IInputDropdownProps> = ({
  children,
  validationIcon,
  validationType,
  isTelus,
  label,
  required,
  tooltip,
  id,
  placeholder,
  disabled,
  isFixedHeight,
}: IInputDropdownProps) => {
  const itemProps = children.props;
  const components = children.props.components;

  let borderColor = '';

  if (validationType === ValidationType.WARNING) {
    borderColor = 'sui-c-input-dropdown-warning';
  }
  if (validationType === ValidationType.SUCCESS) {
    borderColor = 'sui-c-input-dropdown-success';
  }
  if (validationType === ValidationType.ERROR) {
    borderColor = 'sui-c-input-dropdown-danger';
  }

  return isTelus ? (
    <div>
      {label ? (
        <Label tooltip={tooltip} required={required} htmlFor={itemProps.inputId ?? id}>
          {label}
        </Label>
      ) : null}
      {React.cloneElement(children, {
        className: classNames('sui-c-telus-dropdown-container', itemProps.className, borderColor, {
          'has-icon': validationIcon,
          'is-fixed-height': isFixedHeight,
        }),
        placeholder: itemProps.placeholder ?? placeholder,
        classNamePrefix: classNames(
          itemProps.classNamePrefix ?? 'sui-c-telus-dropdown sui-c-input-dropdown'
        ),
        isDisabled: itemProps.isDisabled ?? disabled,
        components: {
          Option: components?.Option ?? TelusCustomOption,
          IndicatorsContainer: components?.IndicatorsContainer ?? TelusIndicatorsContainer,
          MultiValueRemove: components?.MultiValueRemove ?? TelusMultiValueRemove,
          ...itemProps.components,
        },
      })}
    </div>
  ) : (
    React.cloneElement(children, {
      className: classNames(children.props.className, borderColor),
      components: {
        DropdownIndicator: components?.DropdownIndicator ?? CustomDropdownIndicator,
        ValueContainer: components?.ValueContainer ?? CustomValueContainer,
        ClearIndicator: components?.ClearIndicator ?? CustomClearIndicator,
        Option: components?.Option ?? CustomOption,
        MultiValueRemove: components?.MultiValueRemove ?? CustomMultiValueRemove,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        IndicatorsContainer: (indicatorsContainerProps: any) =>
          components?.IndicatorsContainer(indicatorsContainerProps) ??
          CustomIndicatorsContainer({
            validationIcon,
            validationType,
            ...indicatorsContainerProps,
          }),
        ...components,
      },
    })
  );
};
